import { Inject, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxTippyModule, NgxTippyService } from 'ngx-tippy-wrapper';
import { MAT_TOOLTIP_CONFIG, MatTooltipDirective } from './mat-tooltip';
import { DefaultProps, followCursor } from 'tippy.js';
import { TruncatePipe } from '@app/_pipes/truncate.pipe';

@NgModule({
  declarations: [MatTooltipDirective],
  imports: [CommonModule, NgxTippyModule],
  exports: [MatTooltipDirective],
  providers: [
    {
      provide: MAT_TOOLTIP_CONFIG,
      useValue: <DefaultProps>{
        theme: 'timeghost',
        arrow: true,
        inertia: true,
        animation: 'timeghost-anim',
        duration: [100, 100],
        delay: 0,
        hideOnClick: true,
        plugins: [followCursor],
      },
    },
    TruncatePipe,
  ],
})
export class MatTooltipTippyModule {
  constructor(private tippyService: NgxTippyService, @Inject(MAT_TOOLTIP_CONFIG) private tippyOptions: DefaultProps) {
    this.tippyService.setDefaultProps(this.tippyOptions);
  }
}
