import { ChangeDetectorRef, OnInit, Pipe, PipeTransform } from '@angular/core';
import { fromRxValue } from '@app/_helpers/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OptionsWithTZ } from 'date-fns-tz';
import { format as formatDate } from 'date-fns-tz/esm';
import { FormatPipe as DfnsFormatPipe } from 'ngx-date-fns';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { ApplicationSettingsQuery, UserSettingsQuery } from 'timeghost-api';

@UntilDestroy()
@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform, OnInit {
  constructor(
    private appSettingsQuery: ApplicationSettingsQuery,
    private dateFnsFormat: DfnsFormatPipe,
    private userSettingsQuery: UserSettingsQuery,
    private cdRef: ChangeDetectorRef
  ) {}

  get defaultFormat() {
    return this.userSettingsQuery.getValue().settings?.dateFormat ?? 'PP';
  }
  get defaultTimezone() {
    return (
      this.userSettingsQuery.getValue().settings?.timeZone ?? Intl.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone
    );
  }
  ngOnInit(): void {
    this.appSettingsQuery
      .select()
      .pipe(
        map((x) => x?.workspace?.dateFormat),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(() => this.cdRef.markForCheck());
  }
  transform(_date: string | number | Date, _format: string, _options?: OptionsWithTZ): string {
    const date = new Date(_date);
    const options: OptionsWithTZ = Object.assign(
      <OptionsWithTZ>{ ...this.dateFnsFormat.config, timeZone: this.defaultTimezone },
      _options || {}
    );
    const enableDate = !_format || _format === 'P' || _format === 'PP' || _format === 'date';
    const enableTime = _format === 'p' || _format === 'pp' || _format === 'time';
    if (!enableTime && !enableDate && _format) return this.dateFnsFormat.transform(date, _format);
    const dtFormat = [
      enableDate ? this.defaultFormat : null,
      enableTime ? (this.userSettingsQuery.getValue().settings?.timeFormat24h ? 'HH:mm' : 'hh:mm a') : null,
    ]
      .filter(Boolean)
      .join(' ');
    return this.dateFnsFormat.transform(date, dtFormat, options);
  }
}
