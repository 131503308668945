<ng-container
  *ngFor="
    let group of this.times$ | async;
    let groupIndex = index;
    let last = last;
    let first = first;
    trackBy: trackGroup
  "
>
  <ng-container *ngIf="group.state.showMonthDivider">
    <div class="divider my-2">
      <div class="flex flex-col items-center justify-center divider-text" [style.width.px]="220">
        <span class="mat-hint text-sm">{{ group.date | dfnsFormat: 'MMMM' }}</span>
      </div>
    </div>
  </ng-container>
  <app-time-tracker-table-card
    [data]="group"
    [class.mat-border-b]="!last"
    [class.rounded-bottom]="last"
    [class.rounded-top]="first"
  >
    <ng-template timeCardContent>
      <ng-container *ngIf="this.selectedDisplayMode$ | async as mode">
        <ng-container
          *ngTemplateOutlet="
            mode === 'Grouped' ? groupedTable : mode === 'Simple' ? simpleTable : null;
            context: { $implicit: group }
          "
        >
        </ng-container>
      </ng-container>
    </ng-template>
  </app-time-tracker-table-card>
</ng-container>
<ng-template #loadingCards>
  <div fxLayout="column" fxLayoutGap="15px">
    <mat-card *ngFor="let __d of [0, 0, 0, 0, 0]; let i = index">
      <mat-card-title-group class="group-header" fxLayout="row" fxLayoutAlign="start start">
        <mat-card-title class="group-date">
          <div style="height: 20px; width: 80px">
            <div class="mat-skeleton"></div>
          </div>
        </mat-card-title>
        <mat-card-subtitle class="group-date-full">
          <div style="height: 20px; width: 60px">
            <div class="mat-skeleton"></div>
          </div>
        </mat-card-subtitle>
        <div fxFlex></div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
          <div fxLayout="column" fxLayoutGap="4px">
            <div style="height: 20px; width: 80px">
              <div class="mat-skeleton"></div>
            </div>
            <div style="height: 20px; width: 80px">
              <div class="mat-skeleton"></div>
            </div>
          </div>
        </div>
      </mat-card-title-group>
      <mat-card-content>
        <div style="height: 20px; width: 100%">
          <div class="mat-skeleton"></div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
<ng-template #groupedTable let-group>
  <ng-container *ngFor="let item of group.times; let itemindex = index; trackBy: trackItem">
    <ng-container *ngIf="item.times.length > 1 && item.times as dItems; else oneItem">
      <mat-accordion displayMode="flat" [multi]="true" [hideToggle]="false">
        <mat-expansion-panel #panel [hideToggle]="true" class="mat-elevation-z0 mat-expansion-native mb-0 --time-group">
          <mat-expansion-panel-header
            class="t-pad px-3"
            expandedHeight="48px"
            [class.--item-entry-wrapper]="panel.expanded"
          >
            <ng-container
              *ngTemplateOutlet="
                (this.isMobile$ | async) ? mobileGroup : normalGroup;
                context: { $implicit: item, dItems: dItems }
              "
            ></ng-container>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
              <div
                class="--item-entry-container px-2 pb-2 pt-0"
                fxLayout="column"
                fxLayoutAlign="start stretch"
                fxLayoutGap="8px"
              >
                <app-time-tracker-entry
                  *ngFor="let dtime of dItems; trackBy: trackItem"
                  [attr.data-object-id]="dtime.id"
                  [updateOnChange]="false"
                  (Deleted)="this.removeItem($event)"
                  (DateUpdate)="this.entryDateChange.next($event)"
                  [isCompleted]="group.completed"
                  [entry]="dtime"
                >
                </app-time-tracker-entry>
              </div>
            </div>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
    <ng-template #oneItem>
      <app-time-tracker-entry
        *ngFor="let dtime of item.times; trackBy: trackItem"
        [updateOnChange]="false"
        (Deleted)="this.removeItem($event)"
        [isCompleted]="group.completed"
        [entry]="dtime"
        [attr.data-object-id]="dtime.id"
        class="--table-time-entry"
      >
      </app-time-tracker-entry>
    </ng-template>
  </ng-container>
</ng-template>
<ng-template #simpleTable let-group>
  <app-time-tracker-entry
    *ngFor="
      let item of group.times | mapProp: 'times' | flat | sort: { start: 'desc', end: 'desc' };
      let itemindex = index;
      trackBy: trackItem
    "
    [updateOnChange]="false"
    (Deleted)="this.removeItem($event)"
    [isCompleted]="group.completed"
    [entry]="item"
    [attr.data-object-id]="item.id"
    class="--table-time-entry"
  >
  </app-time-tracker-entry>
</ng-template>
<ng-template #mobileGroup let-item let-dItems="dItems">
  <div class="w-full truncate" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
    <div fxLayout="row" fxLayoutAlign="start center">
      <span
        [style.transform]="'scale((panel.expanded ? .85 : 1))'"
        class="badge badge-transparent badge-stroked t-transform"
        >{{ item.times.length }}</span
      >
    </div>
    <div class="group-name truncate" fxFlex>
      <span class="truncate text-nowrap">{{ item.min.task?.name ?? ("task.none" | translate) }}</span>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" class="group-project-badge text-sm">
        <span
          class="mat-hint truncate text-nowrap group-project-name text-right"
          *ngIf="dItems[0]?.project | defaultEntityTranslate: 'project':'project.none' as projectName"
          >{{ projectName }}</span
        >
        <div class="h-1 w-1 rounded-full mat-bg-30 flex-shrink-0"></div>
        <span>{{ dItems[0]?.task?.name || ('task.none' | translate) | truncate: 25 }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
      <div style="width: 32px" fxHide.lt-md></div>
      <mat-icon
        inline
        class="group-header-icons"
        [class]="item.billable ? { 'mat-accent': true } : { 'mat-hint': true }"
        >attach_money</mat-icon
      >
      <div class="ml-0 mr-1" fxHide.lt-md></div>
      <div fxLayout="column" fxLayoutGap="2px" class="flex-shrink-0">
        <small>{{ this.parseExactDuration(item) }}</small>
        <small>
          {{ item.min.start | dfnsParseIso | formatDate: 'time' }} -
          {{ item.max.end | dfnsParseIso | formatDate: 'time':'narrow' }}
        </small>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #normalGroup let-item let-dItems="dItems">
  <div class="w-full text-ellipsis" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
    <div fxLayout="row" fxLayoutAlign="start center">
      <span
        [style.transform]="'scale((panel.expanded ? .85 : 1))'"
        class="badge badge-transparent badge-stroked t-transform"
        >{{ item.times.length }}</span
      >
    </div>
    <div class="group-name text-ellipsis">
      <span class="text-ellipsis text-nowrap">{{ item.task.name ?? ("task.none" | translate) }}</span>
    </div>
    <div class="!ml-auto text-ellipsis">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="6px"
        class="group-project-badge mr-3/4-em"
        *ngIf="dItems[0].project as project"
      >
        <div
          *ngIf="this.getProjectColor(project.id) as color"
          class="project-color-dot"
          [style.backgroundColor]="color"
        ></div>
        <span
          class="mat-hint text-ellipsis text-nowrap group-project-name text-right"
          *ngIf="project | defaultEntityTranslate: 'project':'project.none' as projectName"
          >{{ projectName }}</span
        >
        <mat-icon class="mat-hint" inline> folder </mat-icon>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div fxFlex="0 0 32px" fxHide.lt-md></div>
      <mat-icon
        inline
        class="group-header-icons"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxFlex="0 0 32px"
        [class]="item.billable ? { 'mat-accent': true } : { 'mat-hint': true }"
        >attach_money</mat-icon
      >
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="text-center">
        <span class="mat-hint" fxFlex="0 0 86px"> {{ item.min.start | dfnsParseIso | formatDate: 'time' }} </span>
        <mat-icon inline class="mat-hint">arrow_forward</mat-icon>
        <span class="mat-hint" fxFlex="0 0 86px"> {{ item.max.end | dfnsParseIso | formatDate: 'time' }} </span>
      </div>
    </div>
    <div
      class="badge text-center"
      fxFlex="0 0 64px"
      matTooltip
      [matTooltipTemplate]="durationHoverCard"
      [matTooltipTemplateContext]="{ timeDiff: item.sum }"
    >
      {{ item.sum | parseMsAsDuration: false }}
    </div>
    <div fxFlex="0 0 40px" fxHide.lt-md></div>
  </div>
</ng-template>
<ng-template #durationHoverCard let-timeDiff="timeDiff">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
    <span>{{ 'timer.time.duration' | translate }}:</span>
    <span>{{ timeDiff | parseMsAsDuration: true }}</span>
  </div>
</ng-template>
