import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserGroupSelectControlComponent } from './user-group-select-control.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { UserAvatarModule } from '@app/shared/user-avatar/user-avatar.module';
import { TranslateModule } from '@ngx-translate/core';
import { UserGroupViewComponent } from './user-group-view/user-group-view.component';

@NgModule({
  declarations: [UserGroupSelectControlComponent, UserGroupViewComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    UserAvatarModule,
    FlexLayoutModule,
  ],
  exports: [UserGroupSelectControlComponent],
})
export class UserGroupSelectControlModule {}
