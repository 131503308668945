import { AfterContentInit, ChangeDetectorRef, Component, OnInit, Optional } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { ProjectPageService } from '@app/pages/settings/projects/project-page/project-page.service';
import { SelectMode } from '@app/shared/record-toolbar/record-toolbar.component';
import { RecordToolbarService } from '@app/shared/record-toolbar/record-toolbar.service';
import { debounceTimeAfterFirst } from '@app/_helpers/debounceAfterTime';
import { distinctUntilChangedJson } from '@app/_helpers/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, interval } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Logger, MyTimesQuery, Project, ProjectsQuery, ProjectsService, Time } from 'timeghost-api';
const log = new Logger('RecordWidgetComponent');
@UntilDestroy()
@Component({
  selector: 'tg-record-widget',
  templateUrl: './record-widget.component.html',
  styleUrls: ['./record-widget.component.scss'],
})
export class RecordWidgetComponent implements OnInit, AfterContentInit {
  readonly time$ = this.myTimeQuery.selectAll({ filterBy: (x) => !x.end, limitTo: 1 }).pipe(map((x) => x?.[0]));
  readonly timeRunning$ = combineLatest([this.time$, interval(950)]).pipe(
    untilDestroyed(this),
    distinctUntilChanged(([prevTime], [time, x]) => (!!time || (prevTime && !prevTime.end) ? false : true)),
    map(([time]) => (time ? { ...time } : time)),
    map((time: Time & { now: Date }) => {
      if (time) time.now = new Date();
      return time;
    })
  );
  constructor(
    private myTimeQuery: MyTimesQuery,
    private router: Router,
    @Optional()
    private projectPage: ProjectPageService,
    private recordService: RecordToolbarService,
    private projectService: ProjectsService,
    private projectsQuery: ProjectsQuery,
    private cdr: ChangeDetectorRef
  ) {}
  isLoading = false;
  hasTimeRunning = false;
  ngOnInit(): void {
    this.time$.pipe(untilDestroyed(this)).subscribe((x) => (this.hasTimeRunning = !!x));
  }
  ngAfterContentInit() {}
  get now() {
    return new Date();
  }
  get canAdd() {
    const project = this.projectPage?.project;
    if (!project) return true;
    return !project.completed && project.projectType !== 'template';
  }
  async openCreateDialog() {
    let project: Project,
      task = this.projectPage?.selectedTask.value;
    if (
      this.projectPage?.projectId &&
      (project =
        this.projectsQuery.getEntity(this.projectPage.projectId) ??
        (await this.projectService.getById(this.projectPage.projectId).catch(() => null)))
    )
      return this.recordService.openCreateDialog({ project, task });
    return this.recordService.openCreateDialog();
  }
  openUpdateDialog(time: Time, forceUpdate: boolean = false) {
    return this.recordService.openUpdateDialog(time, forceUpdate);
  }
  async startRecord() {
    this.isLoading = true;
    let project: Project,
      task = this.projectPage?.selectedTask.value;
    if (
      this.projectPage?.projectId &&
      (project =
        this.projectsQuery.getEntity(this.projectPage.projectId) ??
        (await this.projectService.getById(this.projectPage.projectId).catch(() => null)))
    )
      return this.recordService
        .startRecord({ project, task })
        .then(() => (this.isLoading = false))
        .then(() => this.resetLoading())
        .catch(() => this.resetLoading());
    return this.recordService
      .startRecord()
      .then(() => this.resetLoading())
      .catch(() => this.resetLoading());
  }
  stopRecord() {
    this.isLoading = true;
    return this.recordService
      .stopRecord()
      .then(() => this.resetLoading())
      .catch(() => this.resetLoading());
  }
  resetLoading() {
    this.isLoading = false;
  }
}
