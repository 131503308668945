import { Injectable, EventEmitter, Output, Input } from '@angular/core';
import { SelectMode, TempSaveRecord } from './record-toolbar.component';
import {
  Project,
  Tag,
  Time,
  ProjectsQuery,
  MyTimesQuery,
  MyTimesService,
  UserSettingsQuery,
  Task,
  ApplicationSettingsQuery,
} from 'timeghost-api';
import { Logger } from 'timeghost-api';
import { BehaviorSubject, combineLatest, defer, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  auditTime,
  map,
  filter,
  take,
  finalize,
  switchMap,
  debounceTime,
  skip,
  takeUntil,
} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MediaObserver } from '@angular/flex-layout';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CustomValidators } from '@app/_validators/custom-validators';
import { Validators } from '@angular/forms';
import { ObserveFormGroupErrors, useFormErrorObservable } from '@app/_helpers/get-error-observable';
import { TranslateService } from '@ngx-translate/core';
import { RecordData } from '@app/classes/record';
import { extract } from '@app/core';
import {
  addHours,
  endOfDay,
  format as formatString,
  isSameDay,
  roundToNearestMinutes,
  subHours,
  subMinutes,
  parse as parseFromString,
  addMinutes,
  startOfDay,
  addSeconds,
  startOfToday,
  subSeconds,
  endOfMinute,
  endOfToday,
  isToday,
} from 'date-fns/esm';
import { NotifierService } from 'angular-notifier';
import { NotifierNotificationOptions } from 'angular-notifier/lib/models/notifier-notification.model';
import produce from 'immer';
import { EntityStore } from '@datorama/akita';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  TimeTrackCreateData,
  TimeTrackerCalendarCreateDialogComponent,
} from '../time-tracker-calendar-create-dialog/time-tracker-calendar-create-dialog.component';
import { CalendarEvent } from 'calendar-utils';
import { TimeTrackerCalendarUpdateDialogComponent } from '../time-tracker-calendar-update-dialog/time-tracker-calendar-update-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import differenceInHours from 'date-fns/differenceInHours';
import { flow } from 'lodash-es';
import { clampDay } from '@app/_helpers/date-fns';
import { intervalToDuration } from 'date-fns';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChangedJson, roundUpMinute, WithOptional } from '@app/_helpers/utils';
import { pushError } from '@app/_helpers/globalErrorHandler';
@UntilDestroy()
@ObserveFormGroupErrors()
@Injectable({
  providedIn: 'root',
})
export class RecordToolbarService {
  get configInputMode() {
    const inputMode = this.appSettings.getValue()?.inputMode ?? 'range';
    const validModes = ['range', 'duration'];
    return validModes.includes(inputMode) ? inputMode : 'range';
  }
  group = new FormGroup({
    name: new FormControl('', [Validators.minLength(3)]),
    project: new FormControl<any>(
      this.projectsQuery.getAll({ filterBy: (x) => x.useAsDefault === true, limitTo: 1 })[0],
      [
        Validators.required,
        (ctrl) => {
          const value = ctrl.value as Project;
          if (
            this.userSettingsQuery.getValue().workspace?.settings?.requireProject &&
            typeof value === 'object' &&
            value.useAsDefault
          ) {
            return {
              required: true,
            };
          }
          return null;
        },
      ]
    ),
    task: new FormControl(null, (ctrl) => {
      // const value = ctrl.value as Task;
      if (this.userSettingsQuery.getValue().workspace?.settings?.requireTask && !ctrl.value?.id) {
        return {
          required: true,
        };
      }
      return null;
    }),
    billable: new FormControl(false),
    tags: new FormControl<any[]>([]),
    time: new FormGroup(
      {
        start: new FormControl('00:00', [CustomValidators.validDateFormat('HH:mm')]),
        end: new FormControl('00:00', [CustomValidators.validDateFormat('HH:mm')]),
        recordStart: new FormControl<Date>(null, [CustomValidators.validDate]),
        duration: new FormControl('00:00', [CustomValidators.validDuration()]),
      },
      [CustomValidators.timeDiffCheck('start', 'end')]
    ),
    ref: new FormControl<string>(null),
    inputMode: new FormControl<'range' | 'duration'>(this.configInputMode),
  });
  groupError(controlName: string) {
    return useFormErrorObservable(this)(
      controlName,
      () => this.group.get(controlName) as UntypedFormControl,
      {
        required: (error, ctrl) => ({
          content: 'errors.required',
          args: { field: controlName },
        }),
        minlength: (error, ctrl) => ({
          content: 'errors.minlength',
          args: { field: controlName, length: error.requiredLength },
        }),
      },
      (key) => this.translate.instant(key)
    );
  }
  readonly onClear = new Subject<void>();
  clear() {
    this.onClear.next();
    this.resetAll();
  }
  private _isLoading = new BehaviorSubject<boolean>(false);
  readonly isLoading$ = this._isLoading.asObservable().pipe(distinctUntilChanged());
  get isLoading() {
    return this._isLoading.getValue();
  }
  set isLoading(val: boolean) {
    this._isLoading.next(val);
  }
  reset() {
    this.group.patchValue({
      time: {
        start: `${new Date().getHours().toString()?.padStart(2, '0')}:00`,
        end: `${new Date().getHours().toString()?.padStart(2, '0')}:00`,
        duration: `${new Date().getHours().toString()?.padStart(2, '0')}:00`,
      },
    });
  }
  resetProject(name?: boolean) {
    let data: any = {
      project: this.projectsQuery.getAll({ filterBy: (x) => x.useAsDefault === true })[0],
      task: null,
      ref: null,
    };
    if (name) data.name = '';
    this.group.patchValue(data);
  }
  resetEntites() {
    this.group.patchValue({
      name: '',
      project: this.projectsQuery.getAll({ filterBy: (x) => x.useAsDefault === true })[0],
      task: null,
      billable: false,
      tags: [],
      ref: null,
    });
  }
  resetDate(date?: Date) {
    this.group.patchValue({
      time: {
        recordStart: date ?? null,
      },
    });
  }
  resetAll() {
    const now = new Date();
    this.group.patchValue({
      name: '',
      project: this.projectsQuery.getAll({ filterBy: (x) => x.useAsDefault === true })[0],
      task: null,
      billable: false,
      tags: [],
      time: {
        start: `${now.getHours().toString()?.padStart(2, '0')}:00`,
        end: `${now.getHours().toString()?.padStart(2, '0')}:00`,
        duration: `${new Date().getHours().toString()?.padStart(2, '0')}:00`,
      },
      ref: null,
    });
  }
  constructor(
    private projectsQuery: ProjectsQuery,
    private media: MediaObserver,
    private dialog: MatDialog,
    private myTimesQuery: MyTimesQuery,
    private myTimesService: MyTimesService,
    private translate: TranslateService,
    private notifier: NotifierService,
    private appSettings: ApplicationSettingsQuery,
    private userSettingsQuery: UserSettingsQuery
  ) {}
  private initialized = false;
  private setDefaultTime() {
    const user = this.userSettingsQuery.getValue();
    const timesMode = user.workspace?.settings?.timesMode;
    let inputMode: any = this.configInputMode;
    if (timesMode === 'duration') inputMode = 'duration';
    const now = new Date();
    let start = roundToNearestMinutes(
        !user.workspace.settings?.allowFutureTimeTracking
          ? subHours(now.getTime(), 1)
          : subMinutes(new Date(now.getTime()), 15),
        { nearestTo: 15 }
      ),
      end = ((newEnd) => (isToday(newEnd) ? newEnd : endOfToday()))(addHours(start.getTime(), 1));
    if (!user.workspace.settings?.allowFutureTimeTracking) {
      if (start > new Date()) start = subMinutes(start, start.getMinutes() % 15);
      if (end > new Date()) end = subMinutes(end, end.getMinutes() % 15);

      if (differenceInHours(now, end) > 0) end = addHours(end, 1);
    }
    if (end > endOfDay(start.getTime())) {
      end = endOfDay(start.getTime());
    }
    let duration = ((d) => (~~d.hours * 60 + ~~d.minutes) * 60)(intervalToDuration({ start, end }));
    this.group.patchValue({
      time: {
        start: formatString(start, 'HH:mm'),
        end: formatString(end, 'HH:mm'),
        duration: formatString(addSeconds(startOfDay(start.getTime()), duration), 'HH:mm'),
      },
      inputMode,
    });
  }
  initialize() {
    if (!this.initialized) {
      this.initialized = true;
      this.setDefaultTime();
      this.group.valueChanges.subscribe(({ inputMode }) => {
        this.appSettings.updateByKey('inputMode', inputMode);
      });
      (this.group.controls.time as UntypedFormGroup).valueChanges
        .pipe(
          untilDestroyed(this),
          distinctUntilChangedJson(({ start, end }) => ({ start, end })),
          filter((x) => x.start !== '00:00' && x.end !== '00:00')
        )
        .subscribe(({ start, end, recordStart: date }) => {
          const refDate = new Date(date);
          let range = {
            start: clampDay(refDate)(parseFromString(start, 'HH:mm', refDate)),
            end: clampDay(refDate)(parseFromString(end, 'HH:mm', refDate)),
          };
          if (range.end < range.start) range.end = new Date(range.start.getTime());
          const duration = intervalToDuration({
            ...range,
          });
          (this.group.controls.time as UntypedFormGroup).patchValue(
            {
              start: formatString(range.start, 'HH:mm') || formatString(startOfDay(range.start), 'HH:mm'),
              end: formatString(range.end, 'HH:mm') || formatString(endOfDay(range.end), 'HH:mm'),
              duration: formatString(
                parseFromString(`${~~duration.hours}:${~~duration.minutes}`, 'HH:mm', new Date()),
                'HH:mm'
              ),
            },
            { emitEvent: false }
          );
        });
      combineLatest([
        this.group.controls.name.valueChanges.pipe<string>(debounceTime(1000)),
        this.group.valueChanges.pipe(debounceTime(250)),
        this.myTimesQuery.selectAll({ filterBy: (x) => !x.end }).pipe(map((x) => (x?.length > 0 ? x[0] : null))),
      ])
        .pipe(
          debounceTime(250),
          filter(([name, value, time]) => this.group.valid && time && !time.end && value?.project),
          filter(() => !this.userSettingsQuery.getValue().settings.captureManualMode && !this.isLoading),
          distinctUntilChanged(([oldName, oldValue, oldTime], [newName, newValue, newTime]) => {
            const time = newTime;
            if (!newName) newName = time.name;
            return (
              newName === time.name &&
              newValue.project.id === time.project.id &&
              JSON.stringify((newValue.tags ?? []).map((x: Tag) => x.id).sort()) ===
                JSON.stringify((time.tags ?? []).map((x: Tag) => x.id).sort()) &&
              newValue.billable === time.billable
            );
          }),
          skip(1)
        )
        .pipe(
          switchMap(([name, value, time]) => {
            this.isLoading = true;
            return this.myTimesService
              .update(
                produce(time, (draft) => {
                  draft.name = name;
                  if (value.project) draft.project = value.project;
                  draft.task = value.task;
                  draft.billable = value.billable;
                  draft.tags = value.tags ?? [];
                  return draft;
                })
              )
              .pipe(finalize(() => (this.isLoading = false)));
          })
        )
        .subscribe();
      this.userSettingsQuery
        .select()
        .pipe(
          untilDestroyed(this),
          map((x) => x?.workspace?.settings),
          distinctUntilChangedJson()
        )
        .subscribe(() => {
          this.group.updateValueAndValidity();
        });
      this.loadAutomodeData().then((time) => {
        log.debug('last active recording', time);
        if (time) {
          this.selectedRecordMode = SelectMode.Timer;
          this.isPlaying = true;
        } else {
          this.isPlaying = false;
        }
      });
    }
  }
  notifyError(body: string, options?: NotifierNotificationOptions, args?: { [key: string]: any }) {
    return this.notifier.show({
      type: 'error',
      ...(options ? options : {}),
      message: this.translate.instant(body, args || {}),
    });
  }
  notifyInfo(body: string, options?: NotifierNotificationOptions, args?: { [key: string]: any }) {
    return this.notifier.show({
      type: 'info',
      ...(options ? options : {}),
      message: this.translate.instant(body, args || {}),
    });
  }
  notifySuccess(body: string, options?: NotifierNotificationOptions, args?: { [key: string]: any }) {
    return this.notifier.show({
      type: 'success',
      ...(options ? options : {}),
      message: this.translate.instant(body, args || {}),
    });
  }
  hasPendingInput: boolean;
  //#region isPlaying
  private _isPlaying: boolean;
  silentTogglePlaying(playState?: boolean) {
    this._isPlaying = playState !== undefined ? playState : !this.isPlaying;
  }
  public isPlayingChange = new EventEmitter<boolean>();
  get isPlaying() {
    return this._isPlaying;
  }
  set isPlaying(val: boolean) {
    this._isPlaying = val;
    this.isPlayingChange.emit(val);
  }
  //#endregion
  //#region description
  private _taskName: string;
  public taskNameChange = new EventEmitter<string>();
  get TaskName() {
    return this._taskName;
  }
  set TaskName(val: string) {
    this._taskName = val;
    this.taskNameChange.emit(val);
    log.debug('taskname: ', this._taskName);
  }
  setTaskName(val: string) {
    this._taskName = val;
  }
  //#endregion
  //#region startTime
  private startTime: Date;
  public StartTimeChange = new EventEmitter<Date>();
  get StartTime() {
    return this.startTime;
  }
  set StartTime(val: Date) {
    this.startTime = val;
    this.StartTimeChange.emit(val);
  }
  //#endregion
  //#region startTime
  private _manualStartTime: Date = new Date();
  public ManualStartTimeChange = new EventEmitter<Date>();
  get ManualStartTime() {
    return this._manualStartTime;
  }
  set ManualStartTime(val: Date) {
    this._manualStartTime = val as Date;
    this.ManualStartTimeChange.emit(val);
  }
  private _manualEndTime: Date = new Date();
  public ManualEndTimeChange = new EventEmitter<Date>();
  get ManualEndTime() {
    return this._manualEndTime;
  }
  set ManualEndTime(val: Date) {
    this._manualEndTime = val as Date;
    this.ManualEndTimeChange.emit(val);
  }

  timeRangeSetter = new EventEmitter<{ start: Date; end: Date }>(true);
  //#endregion
  //#region selectedMode
  private _selectedRecordMode = new BehaviorSubject<SelectMode>(SelectMode.Timer);
  readonly selectedRecordMode$ = this._selectedRecordMode.asObservable().pipe(distinctUntilChanged());
  get selectedRecordMode() {
    return this._selectedRecordMode.getValue();
  }
  set selectedRecordMode(val: SelectMode) {
    this._selectedRecordMode.next(val);
  }
  private _events = new BehaviorSubject<string>(null);
  readonly events$ = this._events.asObservable().pipe(auditTime(100));
  get events() {
    return this._events.getValue();
  }
  set events(val: string) {
    this._events.next(val);
  }

  //#endregion
  //#region prj
  private _selectedProject = new BehaviorSubject<Project>(null);
  readonly selectedProject$ = this._selectedProject.asObservable().pipe(distinctUntilChanged());
  public selectedProjectChange = new EventEmitter<Project>();
  get selectedProject() {
    return this._selectedProject.getValue();
  }
  set selectedProject(val: Project) {
    this._selectedProject.next(val);
    this.selectedProjectChange.emit(val);
  }
  //#endregion
  private _selectedTags = new BehaviorSubject<Tag[]>([]);
  readonly selectedTags$ = this._selectedTags.asObservable().pipe(distinctUntilChanged());
  get selectedTags() {
    return this._selectedTags.getValue();
  }
  set selectedTags(val: Tag[]) {
    this._selectedTags.next(val);
  }
  private _billed = new BehaviorSubject<boolean>(false);
  readonly billed$ = this._billed.asObservable().pipe(distinctUntilChanged());
  get billed() {
    return this._billed.getValue();
  }
  set billed(val: boolean) {
    this._billed.next(val);
  }
  async loadAutomodeData() {
    const time = (await this.myTimesService.getLatestRecordings(1))[0];
    if (!time) return null;
    (this.myTimesQuery.__store__ as EntityStore).upsert(time.id, time);
    this.selectedRecordMode = SelectMode.Timer;
    this.group.patchValue({
      name: time.name || '',
      project: time.project?.id
        ? this.projectsQuery.getEntity(time.project.id)
        : this.projectsQuery.getAll({ filterBy: (x) => x.useAsDefault })[0],
      task: time.task,
      billable: !!time?.billable,
      tags: [],
      time: {
        start: formatString(new Date(time.start), 'HH:mm'),
        end: formatString(new Date(time.start), 'HH:mm'),
      },
    });
    this.isPlaying = true;
    return time;
  }
  async saveActiveRecording() {
    const running = await this.myTimesService.getLatestRecordings(1).then((x) => x[0]);
    return await new Promise<Time>((resolve, reject) => {
      if (running && !running.end) {
        this.isLoading = true;
        return this.myTimesService
          .update({
            ...running,
            end: new Date().toISOString(),
          })
          .toPromise()
          .then(() => {
            this.isLoading = false;
            resolve(running);
          })
          .catch(reject);
      }
      return resolve(null);
    });
  }
  async continueFromTask(entry: Task) {
    return this.continueRecording({
      name: null,
      project: entry.project as any,
      billable: false,
      task: entry,
    } as any);
  }
  async continueRecording(entry: Time) {
    const project = this.projectsQuery.getEntity(entry.project.id);
    return this.saveActiveRecording()
      .then((prevTime) => {
        if (prevTime) this.notifySuccess('record-toolbar.saved-prevtime');
        this.isLoading = true;
        this.selectedRecordMode = SelectMode.Timer;
        this.StartTime = new Date();
        this.group.patchValue({
          project,
          task: entry.task,
          name: entry.name,
          billable: !!entry.billable,
          tags: entry.tags || [],
          time: {
            recordStart: this.StartTime,
          },
        });
        return this.myTimesService
          .add({
            name: entry.name,
            start: new Date(),
            project: entry.project,
            // @ts-ignore
            task: entry.task,
            billable: !!entry.billable,
            outlookCalenderReference: entry.outlookCalenderReference,
            tags: entry.tags || ([] as any),
            end: null,
          })
          .toPromise();
      })
      .then((time) => {
        this.silentTogglePlaying(true);
        this.isLoading = false;
        return time;
      })
      .catch((err) => {
        this.isLoading = false;
        log.error(err);

        this.handleError(err);
        return Promise.reject(err);
      });
  }
  async copyRecording(entry: Time) {
    const project = this.projectsQuery.getEntity(entry.project.id) ?? { ...entry.project, client: entry.client };
    this.selectedRecordMode = SelectMode.Manual;
    this.events = 'COPY_ENTRY';
    this.group.patchValue({
      project,
      task: entry.task,
      name: entry.name,
      billable: !!entry.billable,
      tags: entry.tags || [],
      // @ts-ignore
      inputMode: entry.inputMode,
    });
  }
  private _saveLoading = new BehaviorSubject<boolean>(false);
  readonly saveLoading$ = this._saveLoading.asObservable().pipe(distinctUntilChanged());
  get saveLoading() {
    return this._saveLoading.getValue();
  }
  set saveLoading(val: boolean) {
    this._saveLoading.next(val);
  }

  async save(data: Partial<Time & { inputMode: 'duration' | 'range' }>) {
    this.saveLoading = true;
    const start = new Date(data.start),
      end = new Date(data.end),
      duration = data.timeDiff;
    if (start > end) {
      this.notifyError('record-toolbar.start-lt-end');
      this.saveLoading = false;
      return;
    }
    if (!data.project?.id) {
      this.saveLoading = false;
      throw new Error(extract('errors.task.not-added'));
    }
    const mode = this.userSettingsQuery.getValue()?.workspace.settings?.timesMode ?? 'range';
    const inputMode = data.inputMode;
    const taskId = data.task?.id;
    return new Promise<Time[]>((resolve, reject) => {
      this.myTimesService
        .add({
          project: { id: data.project.id },
          name: data.name,
          // @ts-ignore
          task: data.task,
          billable: !!data.billable,
          outlookCalenderReference: data.outlookCalenderReference,
          inputMode,
          ...(mode === 'range_optional'
            ? data.inputMode === 'duration'
              ? {
                  start: startOfDay(start.getTime()),
                  end: startOfDay(end.getTime()),
                  timeDiff: duration,
                }
              : {
                  start: start.toISOString(),
                  end: end.toISOString(),
                }
            : mode === 'duration'
            ? { start: startOfDay(start.getTime()), end: startOfDay(end.getTime()), timeDiff: duration }
            : {
                start: start.toISOString(),
                end: end.toISOString(),
              }),
          tags: (data.tags?.filter(({ id, name }) => id && name) ?? []) as any,
        })
        .pipe(
          finalize(() => {
            this.saveLoading = false;
          })
        )
        .subscribe({
          next: (...args) => resolve(...args),
          error: (...args) => reject(...args),
        });
    })
      .then(([x]) => {
        log.debug(x);
        const user = this.userSettingsQuery.getValue();
        const mode = user.workspace.settings?.timesMode ?? 'range';
        if (user.settings.captureManualMode === true && mode === 'range') {
          let newStart = new Date(x.end),
            newEnd = user.workspace.settings?.allowFutureTimeTracking ? addHours(new Date(x.end), 1) : new Date(x.end);
          if (!user.workspace.settings?.allowFutureTimeTracking) {
            if (differenceInHours(new Date(), newEnd) > 0) newEnd = addHours(newEnd, 1);
          }
          this.group.patchValue({
            time: {
              start: formatString(newStart, 'HH:mm'),
              end: formatString(isSameDay(newEnd, newStart) ? newEnd : endOfDay(newEnd), 'HH:mm'),
            },
          });
        }
        this.handleSuccess(x);
        if (taskId && x.task?.id != taskId) pushError('errors.times.only_assignedusers_can_book_on_task');
        this.notifySuccess('success.saved');
        this.resetEntites();
      })
      .catch((err) => {
        log.error(err);
        this.saveLoading = false;
        this.handleError(err);
        this.revertOnError(data);
      });
  }
  revertOnError(data: Partial<Time>) {
    this.group.patchValue({
      name: data.name,
      project: data.project,
      task: data.task,
      billable: data.billable,
      tags: data.tags,
      time: {
        start: formatString(new Date(data.start), 'HH:mm'),
        end: formatString(new Date(data.end), 'HH:mm'),
        duration: formatString(addSeconds(startOfToday(), data.timeDiff), 'HH:mm'),
      },
    });
  }
  openCreateDialog(data?: Partial<{ name: string; project: Project; task: Task }>) {
    const start = this.StartTime || new Date();
    const currentDt = new Date(start.setHours(start.getHours(), 0, 0));
    return new Promise((resolve) => {
      return this.dialog
        .open(TimeTrackerCalendarCreateDialogComponent, {
          data: <TimeTrackCreateData>{
            title: data?.name || this.group.value.name,
            timeDiff: 0,
            start: currentDt,
            project: data?.project,
            task: data?.task,
          },
        })
        .afterClosed()
        .subscribe(resolve);
    });
  }
  openUpdateDialog(time: Time, forceUpdate: boolean = false) {
    return new Promise((resolve) => {
      this.dialog
        .open(TimeTrackerCalendarUpdateDialogComponent, {
          data: <CalendarEvent<{ time: Time; oldStart?: Date; oldEnd?: Date }>>{
            title: time.name,
            start: new Date(time.start),
            end: new Date(time.end),
            meta: { time, forceUpdate },
          },
        })
        .afterClosed()
        .subscribe(resolve);
    });
  }
  startRecord(data?: Partial<{ name: string; project: Project; task: Task }>) {
    const project = data?.project || this.projectsQuery.getAll({ filterBy: (x) => x.useAsDefault })[0];
    return this.myTimesService
      .add({
        name: data?.name,
        project,
        // @ts-ignore
        task: data?.task?.project?.id === project?.id ? data?.task : undefined,
        start: new Date(),
        billable: false,
        end: null,
        recordType: 'timer',
      })
      .toPromise()
      .catch((err) => {
        this.handleError(err);
        this.revertOnError(data);
        return Promise.reject(err);
      });
  }
  async stopRecord(options?: Partial<{ showUpdate: boolean; checkServiceControls: boolean }>) {
    const time = await this.myTimesService.getLatestRecordings(1).then((x) => x?.[0]);
    if (!time) return;
    const taskId = time.task?.id;
    const mode = this.userSettingsQuery.getValue().workspace.settings?.timesMode ?? 'range';
    return await this.myTimesService
      .update(
        produce(time, (draft: WithOptional<Time>) => {
          const start = ((dt) => {
              dt.setSeconds(0, 0);
              return dt;
            })(new Date(draft.start)),
            end = ((dt) => {
              const ref = new Date(draft.start);
              const diff = Math.ceil((dt.getTime() - ref.getTime()) / 1000 / 60);
              return new Date(start.getTime() + diff * 60 * 1000);
            })(new Date());
          draft.inputMode = 'range';
          if (mode === 'duration') {
            draft.inputMode = 'duration';
          }

          draft.start = start.toISOString();
          draft.end = end.toISOString();
          if (options?.checkServiceControls === true) {
            const value = this.group.value;
            draft.name = value.name;
            draft.project = value.project;
            draft.billable = value.billable;
            draft.task = value.task;
            if (value.ref) draft.outlookCalenderReference = value.ref;
          }
          draft.recordType = 'timer';
        })
      )
      .toPromise()
      .then((x) => [x].flat(1)?.[0])
      .then((x) => {
        this.handleSuccess(x);
        if (taskId && x.task?.id !== taskId) this.notifyInfo('errors.times.only_assignedusers_can_book_on_task');
        if (x.hasTimeOverlap) return this.openUpdateDialog(x, true);
        return options?.showUpdate === false ? x : this.openUpdateDialog(x, true);
      })
      .catch((err) => {
        this.handleError(err);
        this.revertOnError(time);
        return Promise.reject(err);
      });
  }
  handleError(err: HttpErrorResponse) {
    let args: any = {};
    if (err.error?.message === 'errors.required') args.field = err.error.field;
    pushError(err, undefined, args);
  }
  handleSuccess(x: Time) {
    if (!x) return;
    if (x.hasTimeOverlap) this.notifyInfo('time.overlaptime_not_allow_workspace');
  }
  setToNow(prop: 'start' | 'end') {
    return this.group.patchValue({
      time: {
        [prop]: flow((x) => formatString(x, 'HH:mm'))(new Date()),
      },
    });
  }
  setMinutesDiff(minutes: number, prop: 'start' | 'end') {
    const timespanGroup = this.group.get('time') as UntypedFormGroup;
    switch (prop) {
      case 'start':
        return timespanGroup.patchValue({
          [prop]: flow(
            (x) => parseFromString(x, 'HH:mm', new Date()),
            (x) => subMinutes(x, minutes),
            clampDay(new Date()),
            (x) => formatString(x, 'HH:mm')
          )(timespanGroup.value.end as string),
        });
      case 'end':
        return timespanGroup.patchValue({
          [prop]: flow(
            (x) => parseFromString(x, 'HH:mm', new Date()),
            (x) => addMinutes(x, minutes),
            clampDay(new Date()),
            (x) => formatString(x, 'HH:mm')
          )(timespanGroup.value.start as string),
        });
    }
  }
  setHourDiff(hours: number, prop: 'start' | 'end') {
    const timespanGroup = this.group.get('time') as UntypedFormGroup;
    switch (prop) {
      case 'start':
        return timespanGroup.patchValue({
          [prop]: flow(
            (x) => parseFromString(x, 'HH:mm', new Date()),
            (x) => subHours(x, hours),
            clampDay(new Date()),
            (x) => formatString(x, 'HH:mm')
          )(timespanGroup.value.end as string),
        });
      case 'end':
        return timespanGroup.patchValue({
          [prop]: flow(
            (x) => parseFromString(x, 'HH:mm', new Date()),
            (x: Date) => addHours(x, hours),
            clampDay(new Date()),
            (x) => formatString(x, 'HH:mm')
          )(timespanGroup.value.start as string),
        });
    }
  }
  setWorkDay() {
    this.group.patchValue({
      time: {
        start: '09:00',
        end: '17:00',
      },
    });
  }
}
const log = new Logger(RecordToolbarService.name);
