import { isPlatformServer } from '@angular/common';
import {
  Directive,
  ElementRef,
  EmbeddedViewRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { hasChange } from '@app/_helpers/utils';
import { TruncatePipe } from '@app/_pipes/truncate.pipe';
import { merge } from 'lodash-es';
import { Logger } from 'timeghost-api';
import tippy, { DefaultProps, Instance as TippyInstance, Placement, Props as TippyProps } from 'tippy.js';

import { MAT_TOOLTIP_CONFIG } from './mat-tooltip';

let uniqueId: number = 0;
const log = new Logger('MatTooltipDirective');
@Directive({
  selector: '[matTooltip]',
  host: {
    class: 'mat-tippy',
  },
})
export class MatTooltipDirective implements OnInit, OnChanges, OnDestroy {
  private tippy: TippyInstance;
  constructor(
    private truncateSerivce: TruncatePipe,
    @Inject(MAT_TOOLTIP_CONFIG)
    private options: DefaultProps,
    private el: ElementRef<HTMLElement>,
    private viewContainer: ViewContainerRef,
    @Inject(PLATFORM_ID) private platform: Object
  ) {}
  private id: string = `matTippy-${uniqueId++}`;
  private _matTooltipDisabled: boolean;
  @Input()
  public get matTooltipDisabled(): boolean {
    return this._matTooltipDisabled;
  }
  public set matTooltipDisabled(v: boolean) {
    this._matTooltipDisabled = v;
  }

  private _matTooltip: string = null;
  @Input()
  public get matTooltip(): string {
    return this._matTooltip;
  }
  public set matTooltip(v: string) {
    this._matTooltip = v;
  }
  private _matTooltipOptions: TippyProps;
  public get matTooltipOptions(): TippyProps {
    return this._matTooltipOptions;
  }
  @Input()
  public set matTooltipOptions(v: TippyProps) {
    this._matTooltipOptions = merge({ ...this.options }, v);
  }

  private _matTooltipPosition: Placement = 'top';
  @Input()
  public get matTooltipPosition(): Placement {
    return this._matTooltipPosition;
  }
  public set matTooltipPosition(v: Placement) {
    this._matTooltipPosition = v || 'top';
  }

  private _matTooltipTemplate: TemplateRef<any>;
  @Input()
  public get matTooltipTemplate(): TemplateRef<any> {
    return this._matTooltipTemplate;
  }
  public set matTooltipTemplate(v: TemplateRef<any>) {
    this._matTooltipTemplate = v;
  }

  private _matTooltipTemplateContext: any;
  @Input()
  public get matTooltipTemplateContext(): any {
    return this._matTooltipTemplateContext;
  }
  public set matTooltipTemplateContext(v: any) {
    this._matTooltipTemplateContext = v;
  }
  private view: EmbeddedViewRef<any>;
  ngOnInit() {
    if (isPlatformServer(this.platform)) return;
    this.viewLoaded = true;
    this.tippy = tippy(this.el.nativeElement, { ...this.options, ...this.matTooltipOptions });
    this.setContent();
  }
  private viewLoaded: boolean;
  ngOnChanges({
    matTooltipTemplateContext,
    matTooltipOptions,
    matTooltip,
    matTooltipTemplate,
    matTooltipPosition,
    matTooltipDisabled,
  }: SimpleChanges): void {
    if (this.tippy) {
      if (
        matTooltipTemplateContext &&
        !matTooltipTemplateContext.firstChange &&
        hasChange(this.view.context, matTooltipTemplateContext.currentValue)
      ) {
        this.setContent();
      }
      if (
        matTooltipOptions &&
        !matTooltipOptions.firstChange &&
        hasChange(this.tippy.props, matTooltipOptions.currentValue)
      ) {
        this.tippy.setProps({
          ...this.options,
          ...matTooltipOptions.currentValue,
          ...(matTooltipPosition && matTooltipPosition.previousValue !== matTooltipPosition.currentValue
            ? {
                placement: matTooltipPosition.currentValue,
              }
            : {}),
        });
      }
      if (
        matTooltipTemplate &&
        !matTooltipTemplate.firstChange &&
        matTooltipTemplate.previousValue !== matTooltipTemplate.currentValue
      ) {
        this.setContent();
      }
      if (matTooltip && !matTooltip.firstChange && matTooltip.previousValue !== matTooltip.currentValue) {
        this.setContent();
      }
      if (
        matTooltipDisabled &&
        !matTooltipDisabled.firstChange &&
        matTooltipDisabled.previousValue !== matTooltipDisabled.currentValue &&
        this.tippy.state.isEnabled === matTooltipDisabled.currentValue
      ) {
        this.tippy[!matTooltipDisabled.currentValue ? 'enable' : 'disable']();
      }
    }
  }
  private setContent() {
    if (!this.tippy) return;
    const tippyState = this.tippy.state;
    if (tippyState.isEnabled === this.matTooltipDisabled) this.tippy.disable();
    else if (!tippyState.isEnabled === !this.matTooltipDisabled) this.tippy.enable();
    const root = document.createElement('div');
    const instance = this.tippy;
    if (!this.matTooltipTemplate) {
      instance.setProps({
        ...this.options,
        ...(this.matTooltipOptions ? this.matTooltipOptions : {}),
        placement: this.matTooltipPosition ?? this.matTooltipOptions?.placement,
        content: this.matTooltip,
      });
      if (!this.matTooltip) this.tippy.disable();
      return;
    }
    this.view = this.viewContainer.createEmbeddedView(this.matTooltipTemplate, this.matTooltipTemplateContext);
    this.view.detectChanges();
    root.classList.add('ng-tippy-content');
    root.id = this.id + '-content';
    root.append(...this.view.rootNodes);
    instance.setProps({
      ...this.options,
      ...(this.matTooltipOptions ? this.matTooltipOptions : {}),
      allowHTML: true,
      placement: this.matTooltipPosition ?? this.matTooltipOptions?.placement,
      content: root,
    });
  }
  ngOnDestroy() {
    this.tippy.destroy();
  }
}
