<div class="flex flex-col space-y-2">
  <div class="h-10 flex space-x-3 items-center relative group mat-typography select-none">
    <div class="w-10 h-10 flex flex-col items-center justify-center mbg-card rounded-full">
      <mat-icon class="!mx-0">group</mat-icon>
    </div>
    <div class="flex flex-col space-y-0 flex-1">
      <div>{{ group.name }}</div>
    </div>
    <div
      class="px-2.5 h-8 flex flex-row items-center space-x-0.5 cursor-pointer mat-bg-3-hover text-sm rounded"
      (click)="toggleUsersView()"
      *ngIf="showGroupUsers && group.users"
    >
      <ng-container *ngIf="group.users.length as userCount">
        <span>{{ userCount }} {{ 'workspace.users.name' | translate }}</span>
      </ng-container>
      <mat-icon inline class="!leading-none !h-initial" [ngClass]="{ '!rotate-180': showUsers.value }"
        >keyboard_arrow_down</mat-icon
      >
    </div>
    <ng-container
      *ngTemplateOutlet="
        showDelete ? deleteEntityTemplate : null;
        context: { entity: { entity: group, type: 'group', id: group.id } }
      "
    ></ng-container>
  </div>
  <div
    fxLayout="column"
    fxLayoutGap="8px"
    class="mat-bg-3 px-1.5 py-2.5 mb-2 rounded"
    *ngIf="showGroupUsers && showUsers.value"
    @matScale
  >
    <ng-container *ngFor="let user of group.users; trackBy: trackId">
      <ng-container *ngTemplateOutlet="groupUsersTemplate; context: { user: user }"></ng-container>
    </ng-container>
  </div>
</div>
