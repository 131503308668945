import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateTaskDialogComponent } from './create-task-dialog.component';
import { MaterialModule } from '@app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxCurrencyModule } from 'ngx-currency';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UserGroupSelectControlModule } from '../user-group-select-control/user-group-select-control.module';
import { DurationInputControlModule } from '../duration-input-control/duration-input-control.module';

@NgModule({
  declarations: [CreateTaskDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxCurrencyModule,
    UserGroupSelectControlModule,
    DurationInputControlModule,
  ],
  exports: [CreateTaskDialogComponent],
})
export class CreateTaskDialogModule {}
