import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { from } from 'rxjs';
import { UserService, UserSettingsQuery } from 'timeghost-api';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class UserAvatarService {
  private _userImageStore: Map<string, Map<string, Blob>> = new Map();
  get userImageStore() {
    return this._userImageStore;
  }
  constructor(private userService: UserService, private userSettingsQuery: UserSettingsQuery) {}
  async initialize() {
    const users = this.userSettingsQuery.getValue()?.workspace?.users;
    await users
      .uniqBy((x) => x.id)
      .reduce((acc, user) => {
        return acc.then(() => this.getPhotoByUserId(user.id).catch(() => null));
      }, Promise.resolve(null as any));
  }
  getPhotoByUserId(
    uid: string,
    size?: '48x48' | '64x64' | '96x96' | '120x120' | '240x240' | '360x360' | '432x432' | '504x504' | '648x648'
  ): Promise<Blob> {
    let userImages: Map<string, Blob>;
    return new Promise((resolve, reject) => {
      if (this.userImageStore.has(uid) && (userImages = this.userImageStore.get(uid))?.has(size || 'default')) {
        return resolve(userImages.get(size || 'default'));
      }
      if (/^custom\-/.test(uid)) return resolve(null);
      return from(this.userService.getUserPhotoById(uid, size))
        .pipe(untilDestroyed(this))
        .toPromise()
        .then((x) => {
          const data = typeof x === 'string' ? b64toBlob(x) : new Blob([x]);
          if (!this.userImageStore.has(uid)) {
            this.userImageStore.set(uid, new Map([[size || 'default', data]]));
          } else {
            this.userImageStore.get(uid).set(size || 'default', data);
          }
          return data;
        })
        .then(resolve)
        .catch(reject);
    });
  }
  getCurrentUserPhoto(
    size?: '48x48' | '64x64' | '96x96' | '120x120' | '240x240' | '360x360' | '432x432' | '504x504' | '648x648'
  ) {
    return this.getPhotoByUserId(this.userSettingsQuery.getValue().id, size);
  }
}
function b64toBlob(b64Data: any, contentType: string = '', sliceSize: number = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}
