import { AfterContentInit, Component, ElementRef, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ElRefDirective } from '@app/_directives/el-ref/el-ref.directive';
import { clampDay } from '@app/_helpers/date-fns';
import {
  DEFAULT_PERMISSION_GROUPS,
  distinctUntilChangedJson,
  fromRxValue,
  hasPermission,
  isNullOrUndefined,
} from '@app/_helpers/utils';
import { CustomValidators } from '@app/_validators/custom-validators';
import { AppService } from '@app/app.service';
import { TaskPickerDialogComponent } from '@app/components/task-picker-dialog/task-picker-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { CalendarEventTimesChangedEvent } from 'angular-calendar';
import { CalendarEvent } from 'calendar-utils';
import {
  addMinutes,
  addSeconds,
  differenceInSeconds,
  endOfDay,
  format as formatString,
  intervalToDuration,
  isValid,
  parse as parseFromString,
  startOfDay,
  startOfToday,
} from 'date-fns/esm';
import { addHours, isAfter, isSameDay, parse as parsefp, parseISO, subHours, subMinutes } from 'date-fns/esm/fp';
import { flow } from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { delay, distinctUntilChanged, filter, finalize, map, shareReplay, startWith, tap } from 'rxjs/operators';
import {
  Logger,
  MyTimesQuery,
  MyTimesService,
  Project,
  ProjectsQuery,
  Tag,
  TagType,
  Task,
  Time,
  UserSettingsQuery,
  Workspace,
} from 'timeghost-api';

import {
  ClientProjectDialogData,
  ClientProjectPickerDialogComponent,
} from '../dialogs/client-project-picker-dialog/client-project-picker-dialog.component';
import { TagDialogData, TagPickerDialogComponent } from '../dialogs/tag-picker-dialog/tag-picker-dialog.component';
import { RecordToolbarService } from '../record-toolbar/record-toolbar.service';
import { TimeTrackerCalendarStepperCreateDialogComponent } from '../time-tracker-calendar-stepper-create-dialog/time-tracker-calendar-stepper-create-dialog.component';
import { TimeDatePickerComponent } from '../time-date-picker/time-date-picker.component';
import { TimeDatePickerConfig } from '../time-date-picker/time-date-picker-config';
import { CosmosEntity } from '@app/_classes/cosmos-entity';
import {
  UserSinglePickerDialogComponent,
  UserSinglePickerDialogData,
} from '@app/components/user-single-picker-dialog/user-single-picker-dialog.component';
import { parseDurationSeconds } from '@app/components/duration-input-control/duration-input-utils';

const log = new Logger('TimeTrackerCalendarCreateDialogComponent');
@UntilDestroy()
@Component({
  selector: 'app-time-tracker-calendar-create-dialog',
  templateUrl: './time-tracker-calendar-create-dialog.component.html',
  styleUrls: ['./time-tracker-calendar-create-dialog.component.scss'],
})
export class TimeTrackerCalendarCreateDialogComponent implements OnInit, AfterContentInit {
  private _isLoading = new BehaviorSubject<boolean>(false);
  readonly isLoading$ = this._isLoading.asObservable().pipe(distinctUntilChanged());
  get isLoading() {
    return this._isLoading.getValue();
  }
  set isLoading(val: boolean) {
    this._isLoading.next(val);
  }
  get mode() {
    return this.userSettingsQuery.getValue()?.workspace.settings?.timesMode;
  }
  group = new FormGroup(
    {
      name: new FormControl('', [CustomValidators.minLength(3)]),
      project: new FormControl<Project>(null),
      task: new FormControl<Task>(null),
      billable: new FormControl(false),
      date: new FormControl(null, [Validators.required]),
      start: new FormControl(null),
      end: new FormControl(null),
      duration: new FormControl(null),
      tags: new FormControl<Tag[]>([]),
      user: new FormControl<Workspace['users'][0]>(null),
    },
    [CustomValidators.timeDiffCheck('start', 'end')]
  );
  defaultProject: Project;
  events$ = combineLatest([
    this.group.valueChanges.pipe(distinctUntilChangedJson()),
    this.myTimesQuery.selectAll().pipe(startWith(this.myTimesQuery.getAll())),
  ]).pipe(
    map(([groupValue, feed]: [{ date: Date; name: string; start: string; end: string }, Time[]]) => {
      const date: Date = groupValue?.date || new Date();
      return feed
        .filter((x) => x.start && x.end && (isSameDay(parseISO(x.start))(date) || isSameDay(parseISO(x.end))(date)))
        .map(
          (t) =>
            <CalendarEvent>{
              id: t.outlookCalenderReference || t.id,
              title: t.name,
              start: new Date(t.start),
              end: new Date(t.end),
              allDay: false,
              draggable: false,
              resizable: {
                beforeStart: false, // this allows you to configure the sides the event is resizable from
                afterEnd: false,
              },
              cssClass: 'event-item',
            }
        )
        .concat(<CalendarEvent>{
          id: this.data.outlookRefId || 'custom',
          title: groupValue.name,
          start: flow((val) => parseFromString(val, 'HH:mm', date))(groupValue.start),
          end: flow((val) => parseFromString(val, 'HH:mm', date))(groupValue.end),
          draggable: true,
          resizable: {
            beforeStart: true,
            afterEnd: true,
          },
          cssClass: 'event-item event-item-active',
          color: {
            primary: '#51BF43',
            secondary: '#51BF43',
          },
        });
    }),
    shareReplay(),
    tap((x) => log.debug(x))
  );

  private _data: TimeTrackCreateData;
  public get data(): TimeTrackCreateData {
    return this._data;
  }
  @Input()
  public set data(v: TimeTrackCreateData) {
    this._data = v;
    this.initializeGroup();
  }
  settings$allowFutureTimeTracking = this.userSettingsQuery
    .select((x) => x.workspace.settings)
    .pipe(map((x) => (x.allowFutureTimeTracking ? null : new Date())));

  constructor(
    @Optional()
    private ref: MatDialogRef<TimeTrackerCalendarCreateDialogComponent>,
    @Optional()
    private stepperCreate: TimeTrackerCalendarStepperCreateDialogComponent,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    private dialogData: TimeTrackCreateData,
    private myTimes: MyTimesService,
    private myTimesQuery: MyTimesQuery,
    private projectsQuery: ProjectsQuery,
    private media: MediaObserver,
    private translate: TranslateService,
    private userSettingsQuery: UserSettingsQuery,
    private dialog: MatDialog,
    private appService: AppService,
    private recordService: RecordToolbarService,
    private el: ElementRef<HTMLElement>
  ) {
    if (this.dialogData) this.prepareData(this.dialogData);
  }
  currentFormat$ = this.appService.timeFormat$;

  readonly workspace$isAdmin = fromRxValue(
    this.userSettingsQuery.select((x) => hasPermission(DEFAULT_PERMISSION_GROUPS.Admin, x))
  );

  readonly postDiffUser$ = combineLatest([
    this.group.valueChanges.pipe(
      map((x) => x?.user?.id),
      distinctUntilChanged()
    ),
    this.userSettingsQuery.select(),
  ]).pipe(map(([uid, user]) => uid && uid !== user.id));
  get isMobile() {
    return this.media.isActive(['xs', 'sm']);
  }
  get i18n() {
    return this.data.lang;
  }
  resetProject() {
    this.patchValue({
      project: this.defaultProject,
      task: null,
    });
  }
  private prepareData(data: TimeTrackCreateData) {
    const now = new Date();
    this.defaultProject = this.projectsQuery
      .getAll({ filterBy: (x) => x.useAsDefault })
      .find((x) => x.useAsDefault === true);
    data.start = data.start || new Date(now.getTime());
    if (data.end) {
      data.end = isAfter(endOfDay(new Date(data.start.getTime())), data.end)
        ? endOfDay(new Date(data.start.getTime()))
        : data.end;
    } else {
      data.end = addHours(1)(new Date(data.start.getTime()));
    }
    const settings = this.userSettingsQuery.getValue().workspace.settings;
    data.timeDiff =
      isNullOrUndefined(data.timeDiff) && !data.end
        ? 30 * 60
        : data.timeDiff ?? differenceInSeconds(data.start, data.end);
    this.data = data;
  }
  private initializeGroup() {
    const mode = this.mode;
    const project = this.data.project || this.defaultProject,
      task = this.data.task;
    const start = this.data.start,
      end = ((date) => (isSameDay(start, date) ? date : endOfDay(start.getTime())))(
        isValid(this.data.end) ? this.data.end : addMinutes(start.getTime(), 30)
      );
    const userSettings = this.userSettingsQuery.getValue();
    const user = this.data.user || userSettings.workspace.users.find((x) => x.id === userSettings.id);
    this.patchValue({
      name: this.data.title || '',
      start: formatString(start, 'HH:mm'),
      date: new Date(start.getTime()),
      end: formatString(end, 'HH:mm'),
      duration: flow(
        (d) => addSeconds(d, (end.getTime() - start.getTime()) / 1000),
        (d) => formatString(d, 'HH:mm')
      )(startOfDay(new Date())),
      tags: this.data.tags || [],
      billable: !!this.data.billable ?? !!project?.billable,
      project: project || null,
      task: task || null,
      user: user as any,
    });
    if (mode === 'duration') {
      this.group.controls.duration.setValidators([Validators.required, CustomValidators.validDateFormat('HH:mm')]);
    } else if (mode !== 'range') {
      this.group.controls.start.clearValidators(),
        this.group.controls.end.clearValidators(),
        this.group.controls.duration.clearValidators();
    } else {
      this.group.controls.start.setValidators([Validators.required, CustomValidators.validDateFormat('HH:mm')]),
        this.group.controls.end.setValidators([Validators.required, CustomValidators.validDateFormat('HH:mm')]),
        this.group.controls.duration.setValidators([Validators.required, CustomValidators.validDateFormat('HH:mm')]);
    }
  }
  trackId(_: number, { id }: { id: string }) {
    return id;
  }
  get platform() {
    return this.appService.platform;
  }
  focusCalendarNow() {
    const calContainer = this.el.nativeElement.querySelector('#cal-day-view');
    if (calContainer?.scrollTop !== undefined) {
      calContainer.scrollTop = this.data.start.getHours() * 30 * 4;
    }
  }
  get stateVisibleMode() {
    return this.recordService.group.value.inputMode === 'range';
  }
  set stateVisibleMode(v: boolean) {
    if (this.userSettingsQuery.getValue().workspace?.settings?.timesMode === 'range_optional') {
      const inputMode = v ? 'range' : 'duration';
      this.recordService.group.patchValue({
        inputMode,
      });
    }
  }
  ngOnInit(): void {
    this.ref?.updateSize('760px', this.stepperCreate ? '620px' : null);
    this.ref?.addPanelClass(['mat-dialog-vanilla', 'mat-dialog-relative', 'mat-dialog-flex']);
    this.ref?.afterOpened().subscribe(() => {
      this.focusCalendarNow();
    });
    this.stepperCreate?.stepper?.selectionChange
      .pipe(
        filter((x) => x.selectedIndex === 1),
        delay(500)
      )
      .subscribe(() => this.focusCalendarNow());
  }
  openUserPicker() {
    if (!this.workspace$isAdmin.value) return;
    return this.dialog
      .open(UserSinglePickerDialogComponent, {
        data: <UserSinglePickerDialogData>{
          selected: this.group.value?.user,
          filter: ({ selected, ...x }) => (x.has_license && !x.removed) || selected,
        },
      })
      .afterClosed()
      .subscribe((user) => {
        if (user)
          this.patchValue({
            user,
          });
      });
  }
  patchValue(data: Partial<typeof this.group.value> = {}, options?: Parameters<typeof this.group.patchValue>[1]) {
    const user = this.userSettingsQuery.getValue();
    if (data.user === null) data.user = user.workspace.users.find((x) => x.id === user.id);
    return this.group.patchValue(data, options);
  }
  onCalChange({ newStart, newEnd }: CalendarEventTimesChangedEvent) {
    const start = formatString(newStart, 'HH:mm'),
      end = formatString(isSameDay(newStart, newEnd) ? newEnd : endOfDay(newStart.getTime()), 'HH:mm');
    this.patchValue({
      start,
      end,
    });
  }
  get hasStepper() {
    return !!this.stepperCreate;
  }
  prevStep() {
    if (!this.hasStepper) return;
    this.stepperCreate.stepIndex = 0;
  }
  openProjectPicker() {
    if (this.hasStepper) {
      this.prevStep();
      return;
    }
    return this.dialog
      .open(ClientProjectPickerDialogComponent, {
        data: <ClientProjectDialogData>{
          data: {
            selectedProject: this.group.value.project,
            selectedTask: this.group.value.task,
            closeOnRemove: true,
            canToggle: true,
            defaultProject: this.defaultProject,
          },
        },
      })
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe(([x, t]: [Project, Task]) => {
        const newProject = x ?? this.defaultProject;
        this.patchValue({
          project: newProject,
          task: newProject?.useAsDefault ? null : t,
          billable: x.billable ? true : this.group.value.billable,
        });
      });
  }
  openTagsPicker() {
    return this.dialog
      .open(TagPickerDialogComponent, {
        data: <TagDialogData>{
          data: {
            SelectedTags: this.group.value.tags,
            canToggle: true,
            type: TagType.Time,
            allowTypeModify: false,
          },
        },
      })
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe((x) => this.patchValue({ tags: x }));
  }
  openTaskPicker() {
    const { project, task } = this.group.value;
    if (!project || project.useAsDefault || project.completed) return;
    return this.dialog
      .open(TaskPickerDialogComponent, {
        data: {
          project: project,
          task: task,
        },
      })
      .afterClosed()
      .subscribe((task: Task & { selected: boolean }) => {
        if (task) this.patchValue({ task: task.selected ? task : null });
      });
  }
  get timeZone() {
    return this.userSettingsQuery.getValue().settings.timeZone;
  }
  get taskDisabled() {
    return !this.group.value.project || this.group.value.project?.useAsDefault || this.group.value.project?.completed;
  }
  openCalPicker() {
    return this.dialog
      .open(TimeDatePickerComponent, {
        data: <TimeDatePickerConfig>{
          selectedDate: new Date(this.group.value.date),
        },
      })
      .afterClosed()
      .pipe(filter((x) => x && isValid(x)))
      .subscribe((x) => {
        this.patchValue({
          date: x,
        });
      });
  }
  addEntry() {
    if (this.group.invalid) return;
    const mode = this.mode,
      inputMode = this.stateVisibleMode ? 'range' : 'duration';
    const val = this.group.getRawValue();
    const baseDate = val.date as Date,
      name = this.group.controls.name.invalid ? this.translate.instant('timer.calendar.newevent') : val.name;
    this.isLoading = true;
    const start: Date = parseFromString(val.start, 'HH:mm', baseDate),
      end: Date = parseFromString(val.end, 'HH:mm', baseDate);

    const nextDuration =
      inputMode === 'duration'
        ? parseDurationSeconds(val.duration)
        : (({ hours, minutes }) => hours * 60 + minutes)(intervalToDuration({ start, end })) * 60;
    const relativeDateStart = startOfDay(start.getTime());
    const userId = this.userSettingsQuery.getValue().id;
    return this.myTimes
      .add({
        name,
        // @ts-ignore
        inputMode,
        ...(mode === 'range_optional'
          ? this.stateVisibleMode
            ? {
                start: start.toISOString(),
                end: end.toISOString(),
                timeDiff: 0,
              }
            : {
                start: relativeDateStart.toISOString(),
                end: relativeDateStart.toISOString(),
                timeDiff: nextDuration,
              }
          : mode === 'duration'
          ? {
              timeDiff: nextDuration,
              start: relativeDateStart.toISOString(),
              end: relativeDateStart.toISOString(),
            }
          : { start: start.toISOString(), end: end.toISOString() }),
        billable: !!val.billable,
        project: { id: val.project.id },
        // @ts-ignore
        task: val.task,
        tags: val.tags || [],
        outlookCalenderReference: this.data.outlookRefId || undefined,
        recordType: 'manual',
        ...(val.user ? { user: { id: val.user.id, name: val.user.name } } : {}),
      })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (x) => {
          this.myTimesQuery.__store__.remove(x.filter((y) => y && y.user?.id !== userId).map((y) => y.id));
          this.ref.close(x);
        },
        error: (err) => {
          this.recordService.handleError(err);
        },
      });
  }
  selectInput(ev: Event, timeInput: ElRefDirective) {
    const el: HTMLElement = timeInput.elementRef.nativeElement;
    if (!el) {
      return;
    }
    const input = el.querySelector('input');
    if (input) {
      ev.preventDefault();
      input.select();
    }
  }

  @ViewChild('timeContextMenuTrigger', { static: true })
  timeContextMenu: MatMenuTrigger;

  timeContextMenuPosition = { x: '0px', y: '0px' };

  onTimeContextMenuTrigger(event: MouseEvent, item: { time: Time; prop: string }) {
    event.stopPropagation(), event.preventDefault();
    this.timeContextMenuPosition.x = event.clientX + 'px';
    this.timeContextMenuPosition.y = event.clientY + 'px';
    this.timeContextMenu.menuData = { $implicit: item };
    this.timeContextMenu.menu.focusFirstItem('mouse');
    this.timeContextMenu.openMenu();
  }
  setToNow(prop: 'start' | 'end') {
    return this.group.controls[prop].patchValue(flow((date) => formatString(date, 'HH:mm'))(new Date()));
  }
  setMinutesDiff(minutes: number, prop: 'start' | 'end') {
    switch (prop) {
      case 'start':
        return this.group.controls[prop].patchValue(
          flow(parsefp(new Date(), 'HH:mm'), subMinutes(minutes), clampDay(new Date()), (date) =>
            formatString(date, 'HH:mm')
          )(this.group.value.end as string)
        );
      case 'end':
        return this.group.controls[prop].patchValue(
          flow(
            parsefp(new Date(), 'HH:mm'),
            (date) => addMinutes(date, minutes),
            clampDay(new Date()),
            (date) => formatString(date, 'HH:mm')
          )(this.group.value.start as string)
        );
    }
  }
  setHourDiff(hours: number, prop: 'start' | 'end') {
    switch (prop) {
      case 'start':
        return this.group.controls[prop].patchValue(
          flow(parsefp(new Date(), 'HH:mm'), subHours(hours), clampDay(new Date()), (date) =>
            formatString(date, 'HH:mm')
          )(this.group.value.end as string)
        );
      case 'end':
        return this.group.controls[prop].patchValue(
          flow(parsefp(new Date(), 'HH:mm'), addHours(hours), clampDay(new Date()), (date) =>
            formatString(date, 'HH:mm')
          )(this.group.value.start as string)
        );
    }
  }
  setWorkDay() {
    return this.patchValue({
      start: '09:00',
      end: '17:00',
    });
  }
  ngAfterContentInit() {
    setTimeout(() => {
      this.focusCalendarNow();

      this.group.updateValueAndValidity();
    }, 50);
  }
}
export interface TimeTrackCreateData {
  timeDiff?: number;
  title?: string;
  start?: Date;
  end?: Date;
  project?: Project;
  task?: Task;
  billable?: boolean;
  lang?: { submit?: string };
  outlookRefId?: string;
  user?: CosmosEntity;
  tags: Tag[];
}
