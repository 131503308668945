<mat-card
  class="!p-0 calendar-range-picker-container"
  [@slideContent]="animationState"
  (@slideContent.start)="onAnimationStart($event)"
  (@slideContent.done)="onAnimationDone($event)"
>
  <mat-card-content>
    <div fxLayout="row" fxLayoutAlign="start start">
      <div class="range-container" *ngIf="this.ranges$Custom | async as ranges" fxFlex="1 1 120px" mat-list>
        <ng-container *ngFor="let item of ranges | iterateObject">
          <button
            mat-menu-item
            class="range-item"
            [class.active]="(this.range$ | async)?.name === item.name"
            (click)="this.selectRange(item)"
          >
            {{ item.name | translate }}
          </button>
        </ng-container>
        <ng-container *ngIf="this.range$ | async as range">
          <button mat-menu-item class="range-item" [class.active]="range.name === 'time-range.preset.custom'">
            {{ 'time-range.preset.custom' | translate }}
          </button>
        </ng-container>
      </div>
      <div fxLayout="column" class="calendar-container" fxFlex>
        <div
          class="text-center time-range-display"
          fxLayout="row"
          fxLayoutAlign="space-between center"
          *ngIf="!(this.range$SameDay | async); else sameDayTemplate"
        >
          <span class="time-range-display-start" *ngIf="this.rangeStart$ | async as dt">{{
            dt | formatDate: 'PP'
          }}</span>
          <span class="time-range-display-divider">-</span>
          <span class="time-range-display-end" *ngIf="this.rangeEnd$ | async as dt">{{ dt | formatDate: 'PP' }}</span>
        </div>
        <ng-template #sameDayTemplate>
          <div class="text-center time-range-display" fxLayout="row" fxLayoutAlign="center center">
            <span class="time-range-display-start">{{ this.rangeStart$ | async | formatDate: 'PP' }}</span>
          </div>
        </ng-template>
        <mat-calendar
          #startCal
          [selected]="this.range$Date | async"
          (selectedChange)="this.selectedChange($event)"
          color="primary"
          class="calender-item"
        ></mat-calendar>
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="end center"
      fxLayoutGap="14px"
      class="calendar-range-footer"
      *ngIf="this.range$ | async as r"
    >
      <button mat-raised-button color="primary" (click)="this.saveSelection()" [disabled]="!r.from || !r.to">
        {{ 'utils.apply' | translate }}
      </button>
    </div>
  </mat-card-content>
</mat-card>
