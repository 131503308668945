<form fxLayout="column" [formGroup]="group" (submit)="$event.preventDefault()" cdkTrapFocus>
  <mat-toolbar class="bg-transparent" id="record_toolbar_wrapper">
    <div class="w-full mr-2 min-w-0">
      <mat-form-field
        appearance="fill"
        class="mat-form-field-no-hint mat-form-field-compact mat-form-field-plain !text-base min-w-0"
        fxFlex
        matAutocompleteOrigin
        #descWrapper="matAutocompleteOrigin"
        role="listbox"
      >
        <input
          type="text"
          matInput
          id="record_toolbar_name"
          [placeholder]="'RECORD_TOOLBAR_DESCRIPTION' | translate"
          formControlName="name"
          [matAutocomplete]="descriptionList"
          [matAutocompleteConnectedTo]="descWrapper"
          (keydown.enter)="$event.preventDefault(); $event.stopPropagation()"
          (keyup.arrowdown)="focusAutocomplete()"
        />
        <div
          fxLayout="row"
          fxLayoutAlign="start stretch"
          fxLayoutGap="8px"
          matSuffix
          class="h-full"
          *ngIf="{
            name: (value$project.value | defaultEntityTranslate: 'project':'project.none'),
            task: value$task | async,
            tags: value$project.value?.tags
          } as projectDetails"
        >
          <mat-divider class="mat-divider-vertical ml-2" fxFlexAlign="center" vertical></mat-divider>
          <button
            class="cursor-pointer border-0 shadow-none"
            id="record_toolbar_project"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="6px"
            (click)="$event.preventDefault(); $event.stopPropagation(); this.openClientProjectPickerDialog($event)"
            (keyup.enter)="
              $event.preventDefault(); $event.stopPropagation(); this.openClientProjectPickerDialog($event)
            "
            matTooltip
            [matTooltipTemplate]="projectPickerTooltip"
            [matTooltipTemplateContext]="projectTooltipData$ | async"
            [matTooltipOptions]="{ delay: [500, 0] }"
            tabindex="0"
          >
            <span> {{ projectDetails.name | truncate: 20 }} </span>
            <div class="h-1 w-1 rounded-full mat-bg-30"></div>
            <span>{{ projectDetails.task?.name || ('task.none' | translate) | truncate: 25 }}</span>
          </button>
        </div>
      </mat-form-field>
      <mat-autocomplete
        #searchACRef
        #descriptionList="matAutocomplete"
        [displayWith]="this.autoDisplayWith"
        class="!rounded-lg overflow-hidden !max-h-full"
      >
        <div class="scroll-y max-h-128 flex-1 min-h-0 my-2">
          <ng-container
            *ngIf="{ suggestions: (entries$suggestions | async), times: (entries$latestTimes | async) } as data"
          >
            <ng-container
              *ngTemplateOutlet="
                data.suggestions?.length > 0 ? suggestionsStepperTemplate : latestTimesTemplate;
                context: data
              "
            ></ng-container>
          </ng-container>
        </div>
        <mat-option style="height: 0; display: none" tabindex="-1" [value]="null"></mat-option>
      </mat-autocomplete>
    </div>
    <div fxFlex></div>
    <div class="flex flex-row items-center justify-center record-toolbar-icon-button-group" #containerAccessor>
      <button
        mat-icon-button
        [class.mat-accent]="value$tags | async"
        (click)="this.openTagPickerDialog($event)"
        [disabled]="group.disabled"
      >
        <mat-icon inline class="">label</mat-icon>
      </button>
      <button
        mat-icon-button
        [class.mat-accent]="value$billable | async"
        mat-button-toggle
        (click)="this.togglePayment()"
        [disabled]="group.disabled"
      >
        <mat-icon inline class="">attach_money</mat-icon>
      </button>
    </div>
    <div class="ml-2 vertical-center" *ngIf="this.SelectedRecordMode === this.modes.Timer">
      <tg-record-toolbar-counter [start]="this.runningTime$counter | async"></tg-record-toolbar-counter>
    </div>
    <div class="vertical-separator"></div>
    <div class="ml-2 vertical-center" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <ng-container *ngIf="this.SelectedRecordMode === this.modes.Timer; else manualMode">
        <button
          mat-fab
          color="accent"
          [disabled]="
            (group$disabled | async) ||
            (!!(runningTime.value$ | async) && ((hasError.value$ | async) || !this.initialEnabled)) ||
            !!(this.isLoading$ | async)
          "
          (click)="this.togglePlaying()"
          class="submit-time-button"
          id="record_toolbar_timer_submit"
        >
          <ng-container *ngIf="!(this.isLoading$ | async); else autoLoading">
            <mat-icon inline *ngIf="!(this.runningTime.value$ | async); else pauseRecordButton">play_arrow</mat-icon>
            <ng-template #pauseRecordButton>
              <mat-icon inline>stop</mat-icon>
            </ng-template>
          </ng-container>
          <ng-template #autoLoading>
            <mat-progress-spinner [strokeWidth]="3" [diameter]="20" color="accent" [mode]="'indeterminate'">
            </mat-progress-spinner>
          </ng-template>
        </button>
        <div></div>
      </ng-container>
      <ng-template #manualMode>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="text-small">
          <div fxLayout="row" fxLayoutAlign="end center" [ngClass]="{ '-ml-12': mode === 'duration' }">
            <tg-time-range-control
              [(stateVisibleMode)]="stateVisibleMode"
              [control]="group.controls.time"
              class="min-w-60 flex items-center justify-end"
              [mode]="mode"
            ></tg-time-range-control>
          </div>
          <button
            class="submit-time-button"
            mat-fab
            color="accent"
            [disabled]="
              (group$disabled | async) ||
              (hasError.value$ | async) ||
              !this.initialEnabled ||
              !!(this.isLoading$ | async)
            "
            (click)="this.saveManualData()"
            id="record_toolbar_submit"
          >
            <mat-icon inline *ngIf="!(this.isLoading$ | async); else manualLoading">check</mat-icon>
            <ng-template #manualLoading>
              <mat-progress-spinner [strokeWidth]="3" [diameter]="20" color="accent" [mode]="'indeterminate'">
              </mat-progress-spinner>
            </ng-template>
          </button>
        </div>
      </ng-template>
    </div>
    <div class="ml-2 record-toolbar-side-menu">
      <button
        mat-icon-button
        [ngClass]="{ 'mat-accent': this.SelectedRecordMode == this.modes.Timer }"
        #SelectionModeTimer
        (click)="this.changeMode(this.modes.Timer)"
      >
        <mat-icon inline class="">timer</mat-icon>
      </button>
      <button
        mat-icon-button
        [ngClass]="{ 'mat-accent': this.SelectedRecordMode == this.modes.Manual }"
        #SelectionModeManual
        (click)="this.changeMode(this.modes.Manual)"
      >
        <mat-icon inline class="">edit</mat-icon>
      </button>
    </div>
  </mat-toolbar>
</form>
<mat-toolbar class="record-err-toolbar bg-transparent" *ngIf="errors$ | async as err" @slide>
  <div class="text-primary">
    {{ err?.translate ? (err.translate | translate: err.args) : (err | translate) }}
  </div>
</mat-toolbar>
<mat-menu #timeContextMenu="matMenu">
  <ng-template matMenuContent let-data>
    <button mat-menu-item (click)="setToNow(data.prop)">{{ 'timeContextMenu.setTo.now' | translate }}</button>
    <button mat-menu-item [matMenuTriggerFor]="timeContextMenuTimeTemplates" [matMenuTriggerData]="{ $implicit: data }">
      {{ 'timeContextMenu.setToMore' | translate }}
    </button>
  </ng-template>
</mat-menu>
<ng-template #showCounter let-time>
  {{ time.start | dfnsParseIso | dfnsDifferenceInSeconds: now() | parseMsAsDuration: true:false }}
</ng-template>
<ng-template #taskSearchTemplate let-searchResult="searchResult">
  <mat-list dense>
    <ng-container
      *ngFor="let entry of searchResult?.clients || []; let last = last; let first = first; trackBy: trackId"
    >
      <h3 matSubheader class="f-bold">
        <div class="text-ellipsis text-nowrap">{{ entry | defaultEntityTranslate: 'client':'client.none' }}</div>
      </h3>
      <div *ngFor="let project of entry.projects || []">
        <h3
          matSubheader
          [matRipple]="!project.useAsDefault"
          (click)="
            $event.stopPropagation();
            project.selected || project.useAsDefault
              ? this.removeProject()
              : this.changeProject({
                  id: project.id,
                  name: project.name,
                  billable: project.billable,
                  client: {
                    id: entry.id,
                    name: entry.name
                  }
                })
          "
        >
          <mat-checkbox
            [labelPosition]="'before'"
            color="primary"
            (click)="$event.preventDefault()"
            class="w-full mat-checkbox-flex"
            [checked]="project.selected"
            [disabled]="project.useAsDefault"
            >{{ project | defaultEntityTranslate: 'project':'project.none' }}</mat-checkbox
          >
        </h3>
        <ng-container
          *ngTemplateOutlet="
            project?.tasks.length > 0 ? autocompleteTaskItem : placeholderTask;
            context: { project: project, entry: entry, value: group.value.name }
          "
        >
        </ng-container>
      </div>
      <mat-divider *ngIf="!last"></mat-divider>
    </ng-container>
  </mat-list>
</ng-template>
<ng-template #autocompleteTaskItem let-project="project" let-entry="entry">
  <mat-option
    class="pl-6 text-ellipsis text-wrap-line"
    *ngFor="let task of project.tasks; trackBy: trackId"
    (click)="
      taskSelected($event, {
        client: { id: entry.id, name: entry.name },
        task: task,
        project: project
      })
    "
    [value]="task.name"
    >{{ task?.name }}</mat-option
  >
</ng-template>
<ng-template #placeholderTask let-value="value">
  <mat-option style="height: 0; display: none" value="value" tabindex="-1"></mat-option>
</ng-template>
<ng-template #taskSearchLoadTemplate>
  <mat-list dense>
    <div fxLayout="column" class="m-2" fxLayoutGap="6px">
      <div class="w-64 h-6">
        <div class="mat-skeleton"></div>
      </div>
      <div class="w-80 h-6">
        <div class="mat-skeleton"></div>
      </div>
    </div>
  </mat-list>
</ng-template>
<ng-template #projectPickerTooltip let-project="project" let-task="task" let-tags="tags">
  <div class="flex flex-col px-2.5 my-2 space-y-2" fxFlex="1 0 220px">
    <div class="space-y-1">
      <h3 class="text-sm mat-hint italic" translate>client.single</h3>
      <div class="font-semibold">{{ project?.client | defaultEntityTranslate: 'client':'client.none' }}</div>
    </div>
    <div class="space-y-1">
      <h3 class="text-sm mat-hint italic" translate>project.single</h3>
      <div class="font-semibold">{{ project | defaultEntityTranslate: 'project':'project.none' }}</div>
    </div>
    <div class="space-y-1" *ngIf="(project | projectProxy)?.description as desc">
      <h3 class="text-sm mat-hint italic" translate>Description</h3>
      <div>{{ desc | truncate: 120 }}</div>
    </div>
    <ng-container *ngIf="tags && tags.length > 0">
      <div class="space-y-1">
        <h3 class="text-sm mat-hint italic" translate>tag.plural</h3>
        <div class="flex flex-wrap gap-2">
          <div
            class="mbg-card rounded-full px-2.5 h-6 cursor-default text-base leading-6 select-none pointer-events-none truncate"
            *ngFor="let item of tags; trackBy: trackId"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
<ng-template #timeInfoTooltip let-client="client" let-project="project" let-task="task" let-color="color">
  <div class="flex flex-col px-2.5 my-2 space-y-2" fxFlex="1 0 220px">
    <div class="space-y-1">
      <h3 class="text-sm mat-hint italic" translate>client.single</h3>
      <div class="font-semibold">
        {{ client | defaultEntityTranslate: 'client':'client.none':('client.none' | translate) }}
      </div>
    </div>
    <div class="space-y-1" *ngIf="(project | projectProxy)?.description as desc">
      <h3 class="text-sm mat-hint italic" translate>Description</h3>
      <div>{{ desc | truncate: 120 }}</div>
    </div>
  </div>
</ng-template>
<ng-template
  #feedInfoTooltip
  let-name="name"
  let-description="description"
  let-icon="icon"
  let-iconUrl="iconUrl"
  let-entry="entry"
>
  <div class="flex flex-row items-start space-x-2 min-w-32" fxFlex="1 0 220px">
    <div class="flex items-center flex-shrink-0">
      <img *ngIf="iconUrl != null; else msIcon" [src]="iconUrl" style="width: 32px" />
      <ng-template #msIcon>
        <ms-icon style="font-size: 32px; height: 32px; width: 32px" [icon]="icon"> </ms-icon>
      </ng-template>
    </div>
    <div class="flex flex-col">
      <div class="font-bold">{{ name | truncate: 100 }}</div>
      <div *ngIf="description as desc" class="mt-1 text-sm">{{ desc | parseFeedDescription | truncate: 160 }}</div>
      <ng-container *ngIf="!entry.isAllDay">
        <div class="flex flex-col mt-1">
          <div class="flex items-center space-x-1">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
              <span class="mat-hint" *ngIf="entry.start as startDate">{{
                startDate | dfnsParseIso | formatDate: 'time'
              }}</span>
              <span class="mat-hint">-</span>
              <span class="mat-hint" *ngIf="entry.end as endDate">{{
                endDate | dfnsParseIso | formatDate: 'time'
              }}</span>
            </div>
          </div>
          <span class="mat-hint">{{ entry.start | dfnsParseIso | dfnsFormat: 'dd. MMMM - EEEE' }}</span>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #noSearchEntries>
  <div class="h-10 mx-16px">
    <p class="mat-hint leading-10 align-middle">{{ 'utils.noitems-search' | translate }}</p>
  </div>
</ng-template>
<ng-template #recentTimeTemplate let-meta let-last="last" let-entry="data">
  <button
    class="rounded-full text-sm mb-1.5 flex items-center space-x-1 cursor-pointer mbg-card opacity-80 hover:opacity-100 px-2.5 h-8"
    matTooltip
    [matTooltipTemplate]="timeInfoTooltip"
    [matTooltipTemplateContext]="{
      project: entry.project,
      client: entry.client,
      task: entry.task,
      color: entry.project.color ?? getProjectColor(entry.project.id)
    }"
    [class.mr-2]="!last"
    (click)="$event.stopPropagation(); timeSelected(entry)"
  >
    <div
      *ngIf="entry.project.color ?? getProjectColor(entry.project.id) as color"
      class="project-color-dot -mt-px"
      [style.backgroundColor]="color"
    ></div>
    <span class="uppercase font-bold truncate">{{
      (entry.project | defaultEntityTranslate: 'project':'project.none' | translate) +
        ' - ' +
        (entry.task?.name || ('task.none' | translate)) | truncate: 80
    }}</span>
  </button>
</ng-template>
<ng-template #recentFeedTemplate let-meta let-last="last" let-entry="data">
  <button
    class="rounded-full text-sm mb-1.5 flex items-center space-x-2 cursor-pointer mbg-card opacity-80 hover:opacity-100 px-2.5 h-8"
    matTooltip
    [matTooltipTemplate]="feedInfoTooltip"
    [matTooltipTemplateContext]="{
      name: entry.name,
      description: entry.description,
      icon: entry.icon,
      iconUrl: entry.iconUrl,
      entry: entry
    }"
    [class.mr-2]="!last"
    (click)="$event.stopPropagation(); feedSelected(entry)"
  >
    <div class="flex items-center flex-shrink-0">
      <img *ngIf="entry.iconUrl != null; else msIcon" [src]="entry.iconUrl" class="w-5" />
      <ng-template #msIcon>
        <ms-icon class="text-sm h-5 w-5" [icon]="entry.icon"> </ms-icon>
      </ng-template>
    </div>
    <div class="flex items-center space-x-1">
      <span class="uppercase font-bold truncate">{{ entry.name | truncate: 40 }}</span>
    </div>
  </button>
</ng-template>
<ng-template #latestTimesTemplate let-entries="times">
  <ng-container *ngIf="entries">
    <div class="pb-2 my-2 overflow-hidden">
      <ng-container *ngIf="!group.value.name || group.value.name.length <= 0">
        <ng-container *ngTemplateOutlet="autocompleteHeader"></ng-container>
      </ng-container>
      <div class="flex flex-col space-y-6">
        <div class="flex flex-col">
          <h1 class="text-base mx-16px font-semibold mat-hint mb-2" translate>task.plural</h1>
          <div class="flex flex-wrap flex-grow px-16px pb-16px -mb-16px">
            <ng-container *ngFor="let entry of entries.times; let last = last; trackBy: trackId">
              <ng-container
                *ngTemplateOutlet="
                  recentTimeTemplate;
                  context: { $implicit: entry, data: entry.data, type: entry.type, last: last }
                "
              ></ng-container>
            </ng-container>
          </div>
          <div class="flex items-center justify-end mx-16px">
            <button
              mat-button
              color="primary"
              class="link-button"
              *ngIf="entries.showMoreTasks !== undefined"
              (click)="showMoreTasks.value = !entries.showMoreTasks"
            >
              {{ (showMoreTasks.value ? 'utils.show_less' : 'utils.show_more') | translate }}
            </button>
          </div>
          <div class="flex flex-wrap flex-grow mx-16px pb-16px -mb-16px" *ngIf="!entries.times?.length">
            <p class="mat-hint mx-16px text-base opacity-60 italic" translate>utils.noitems</p>
          </div>
        </div>
        <div class="flex flex-col" *ngIf="!group.value.name || group.value.name.length <= 0">
          <h1 class="text-base mx-16px font-semibold mat-hint mb-2" translate>feed.name</h1>
          <div class="flex flex-wrap flex-grow px-16px pb-16px -mb-16px">
            <ng-container *ngFor="let entry of entries.feed; let last = last; trackBy: trackId">
              <ng-container
                *ngTemplateOutlet="
                  recentFeedTemplate;
                  context: { $implicit: entry, data: entry.data, type: entry.type, last: last }
                "
              ></ng-container>
            </ng-container>
          </div>
          <div class="flex items-center justify-end mx-16px">
            <button
              mat-button
              color="primary"
              class="link-button"
              *ngIf="entries.showMoreFeed !== undefined"
              (click)="showMoreFeed.value = !entries.showMoreFeed"
            >
              {{ (showMoreFeed.value ? 'utils.show_less' : 'utils.show_more') | translate }}
            </button>
          </div>
          <div class="flex flex-wrap flex-grow mx-16px pb-16px -mb-16px" *ngIf="!entries.feed?.length">
            <p class="mat-hint mx-16px text-base opacity-60 italic" translate>utils.noitems</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
<ng-template #suggestionsStepperTemplate let-entries="suggestions">
  <div class="pb-2 my-2 overflow-hidden">
    <ng-container *ngTemplateOutlet="autocompleteHeader"></ng-container>
    <div class="flex flex-wrap flex-grow px-16px pb-16px -mb-16px gap-4">
      <ng-container *ngFor="let entry of entries; let last = last; trackBy: trackId">
        <mat-card
          class="mat-card-button cursor-pointer mat-bg !rounded !py-2 !px-3 mb-2"
          (click)="$event.stopPropagation(); submitSuggestion({ project: entry, task: entry.task })"
          [class.border-active]="entry.selected"
        >
          <mat-card-content>
            <div fxLayout="column" fxFlex="1 1">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <div
                  class="project-color-dot"
                  *ngIf="entry.color as color"
                  fxFlexAlign="center"
                  [style.backgroundColor]="color"
                  [matTooltip]="'project.color' | translate"
                ></div>
                <h3 class="text-base font-bold mb-0">
                  {{ entry | defaultEntityTranslate: 'project':'project.none' | truncate: 100 }}
                </h3>
                <div class="h-1 w-1 rounded-full mat-bg-30"></div>
                <span>{{ entry.task?.name || ('task.none' | translate) | truncate: 40 }}</span>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #autocompleteHeader>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="mb-4 px-16px">
    <mat-icon svgIcon="logoIcon" class="logo-icon"></mat-icon>
    <div fxLayout="column">
      <h4 class="my-0" translate>utils.suggestions</h4>
      <span class="mat-hint" translate>buzz.ki-suggestions</span>
    </div>
  </div>
</ng-template>
