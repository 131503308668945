<div fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="12px">
  <button mat-raised-button mat-icon-button color="primary" (click)="openCreateDialog()" [disabled]="!canAdd">
    <mat-icon>add</mat-icon>
  </button>
  <ng-container
    *ngTemplateOutlet="this.isLoading ? loadingFab : normalFabTimer; context: { time: (this.timeRunning$ | async) }"
  ></ng-container>
  <ng-template #runningFabTimer let-time="time">
    <button mat-raised-button color="accent" (click)="stopRecord()">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" class="-ml-3 -mr-1 line-height-base h-10">
        <mat-icon>stop</mat-icon>
        {{ time.start | dfnsParseIso | dfnsDifferenceInSeconds: time.now | parseMsAsDuration: true }}
      </div>
    </button>
  </ng-template>
  <ng-template #normalFabTimer let-time="time" let-status="">
    <button
      mat-fab
      (click)="time ? stopRecord() : startRecord()"
      [disabled]="!canAdd"
      matTooltip
      [matTooltipTemplate]="tooltipCounter"
      [matTooltipTemplateContext]="{ time: time }"
      [matTooltipDisabled]="!time || time.end"
      matTooltipPosition="left"
      fxHide.lt-lg
    >
      <mat-icon *ngIf="!time; else runningTime">play_arrow</mat-icon>
      <ng-template #runningTime>
        <mat-icon>stop</mat-icon>
      </ng-template>
    </button>
    <div class="relative" fxHide.gt-md>
      <button mat-fab (click)="time ? stopRecord() : startRecord()">
        <mat-icon *ngIf="!time; else runningTime">play_arrow</mat-icon>
        <ng-template #runningTime>
          <mat-icon>stop</mat-icon>
        </ng-template>
      </button>
      <div
        class="absolute right-full mr-2 bottom-0 px-2.5 py-2 mbg-tooltip rounded shadow block text-left min-w-48"
        *ngIf="time && !time.end"
      >
        <ng-container *ngTemplateOutlet="tooltipCounter; context: { time: time }"></ng-container>
      </div>
    </div>
  </ng-template>
  <ng-template #loadingFab let-time="time">
    <button mat-fab disabled>
      <mat-progress-spinner [strokeWidth]="3" [diameter]="20" color="accent" [mode]="'indeterminate'">
      </mat-progress-spinner>
    </button>
  </ng-template>
</div>
<ng-template #tooltipCounter let-time="time">
  <ng-container *ngIf="time && !time.end">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-icon>timer</mat-icon>
      <div fxLayout="column">
        <span class="font-bold">{{ time.name }}</span>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
          <span class="text-sm font-bold op-70" *ngIf="time.project as project">{{
            project | defaultEntityTranslate: 'project':'project.none'
          }}</span>
          <ng-container *ngIf="time.task?.name as taskName">
            <div class="h-1 w-1 rounded-full mat-bg-30 flex-shrink-0"></div>
            <span class="truncate text-sm op-70">{{ taskName | truncate: 35 }}</span>
          </ng-container>
        </div>
        <span class="mt-1">{{
          time.start | dfnsParseIso | dfnsDifferenceInSeconds: time.now | parseMsAsDuration: true
        }}</span>
      </div>
    </div>
  </ng-container>
</ng-template>
