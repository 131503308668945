<div id="wrapper" fxLayout="column">
  <mat-toolbar
    class="mat-toolbar-tg mat-toolbar-base --timer-rc-header mat-elevation-z0"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="12px"
    teams-hide
  >
    <shell-menu-toggle></shell-menu-toggle>
    <div fxFlex></div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxHide.lt-sm>
      <tg-frill-button></tg-frill-button>
      <mat-divider vertical class="h-8"></mat-divider>
      <app-user-menu></app-user-menu>
    </div>
  </mat-toolbar>
  <mat-card class="record-toolbar-card mat-elevation-z10" *ngIf="!(this.isMobile$ | async)">
    <div class="container">
      <app-record-toolbar #recordToolbar [isLoading]="this.recordLoading$ | async"> </app-record-toolbar>
    </div>
  </mat-card>
  <div
    class="timer-content-wrapper"
    id="timer-content-scroll"
    #tcs
    *ngIf="(this.selectedDisplayMode$ | async) !== 'Calendar'; else calendarHost"
  >
    <div
      class="timer-content"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false"
      elementRef
      #scrollContainer="nativeElement"
      [alwaysCallback]="true"
      (scrolled)="onBottomThreshold(scrollContainer?.el)"
    >
      <div class="container mt-4" *ngIf="!(this.hasEntries$ | async)">
        <ng-container *ngTemplateOutlet="this.isManualMode ? manualModeGhost : autoModeGhosts"></ng-container>
      </div>
      <div class="container">
        <div fxLayout="column" class="time-entries-wrapper mt-1 pb-16">
          <div class="mb-3 mt-2 ml-2 text-left" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
            <shell-menu-toggle [teamsOnly]="true"></shell-menu-toggle>
            <mat-icon>timeline</mat-icon>
            <h3 translate class="my-0">records</h3>
            <div class="!ml-auto flex-shrink-0">
              <ng-container *ngTemplateOutlet="viewToggleRow"></ng-container>
            </div>
          </div>
          <app-time-tracker-table
            [onScrolledObserver]="this.onScrolledBottom"
            [showCompleted]="this.showCompleted"
            [byDayTreshold]="this.byDayTreshold$ | async"
          >
          </app-time-tracker-table>
          <div class="my-3" *ngIf="this.hasLoadMore">
            <div class="flex flex-col items-center justify-center" *ngIf="!(this.isLoading | async); else loadingCards">
              <button mat-stroked-button color="primary" (click)="onBottomThreshold(scrollContainer?.el, true)">
                {{ 'utils.load_more' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #calendarHost>
  <div class="container mt-4" *ngIf="!(this.hasEntries$ | async)">
    <ng-container *ngTemplateOutlet="this.isManualMode ? manualModeGhost : autoModeGhosts"></ng-container>
  </div>
  <div class="container-fluid">
    <div class="mt-2 text-left" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-icon>timeline</mat-icon>
      <h3 translate class="my-0">records</h3>
      <div class="!ml-auto flex-shrink-0">
        <ng-container *ngTemplateOutlet="viewToggleRow"></ng-container>
      </div>
    </div>
  </div>
  <app-time-tracker-calendar></app-time-tracker-calendar>
</ng-template>
<ng-template #loadingCards>
  <div fxLayout="column" fxLayoutGap="15px">
    <mat-card *ngFor="let __d of [0, 0, 0, 0, 0]; let i = index">
      <mat-card-title-group class="group-header" fxLayout="row" fxLayoutAlign="start start">
        <mat-card-title class="group-date">
          <div fxLayout="column" fxLayoutGap="4px">
            <div style="height: 20px; width: 80px">
              <div class="mat-skeleton"></div>
            </div>
            <div style="height: 20px; width: 60px">
              <div class="mat-skeleton"></div>
            </div>
          </div>
        </mat-card-title>
        <div fxFlex></div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
          <div fxLayout="column" fxLayoutGap="4px">
            <div style="height: 20px; width: 80px">
              <div class="mat-skeleton"></div>
            </div>
            <div style="height: 20px; width: 80px">
              <div class="mat-skeleton"></div>
            </div>
          </div>
        </div>
      </mat-card-title-group>
      <mat-card-content>
        <div class="mat-skeleton" style="height: 20px"></div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
<ng-template #viewToggleRow>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" *ngIf="selectedDisplayMode$ | async as mode">
    <button
      [class.bg-primary-2]="!displayModeLoading && mode === 'Simple'"
      mat-icon-button
      color="primary"
      [send-tagmanager_event]="{ category: 'feature', action: 'button tableDisplayMode tableDisplayMode_Simple' }"
      (click)="this.selectTableDisplayMode(this.tableDisplayTypes.Simple)"
      [matTooltip]="'utils.tableDisplayMode.simple' | translate"
      [disabled]="displayModeLoading"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <ng-container *ngTemplateOutlet="displayModeLoading === 'Simple' ? btnLoading : simpleIcon"></ng-container>
    </button>
    <button
      [class.bg-primary-2]="!displayModeLoading && mode === 'Grouped'"
      mat-icon-button
      color="primary"
      [send-tagmanager_event]="{ category: 'feature', action: 'button tableDisplayMode tableDisplayMode_Grouped' }"
      (click)="this.selectTableDisplayMode(this.tableDisplayTypes.Grouped)"
      [matTooltip]="'utils.tableDisplayMode.grouped' | translate"
      [disabled]="displayModeLoading"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <ng-container *ngTemplateOutlet="displayModeLoading === 'Grouped' ? btnLoading : groupedIcon"></ng-container>
    </button>
    <button
      [class.bg-primary-2]="!displayModeLoading && mode === 'Calendar'"
      mat-icon-button
      color="primary"
      [send-tagmanager_event]="{ category: 'feature', action: 'button tableDisplayMode tableDisplayMode_Calendar' }"
      *ngIf="!(this.isMobileCalendar$ | async)"
      (click)="this.selectTableDisplayMode(this.tableDisplayTypes.Calendar)"
      [matTooltip]="'utils.tableDisplayMode.calendar' | translate"
      [disabled]="displayModeLoading"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <ng-container *ngTemplateOutlet="displayModeLoading === 'Calendar' ? btnLoading : calendarIcon"></ng-container>
    </button>
  </div>
  <ng-template #simpleIcon>
    <mat-icon>list</mat-icon>
  </ng-template>
  <ng-template #groupedIcon>
    <mat-icon>view_stream</mat-icon>
  </ng-template>
  <ng-template #calendarIcon>
    <mat-icon>event</mat-icon>
  </ng-template>
  <ng-template #btnLoading>
    <mat-spinner mode="indeterminate" color="primary" [diameter]="18"></mat-spinner>
  </ng-template>
</ng-template>
<ng-template #autoModeGhosts>
  <div fxLayout="column" class="time-entries-wrapper">
    <div class="flex flex-row items-center d-none">
      <div class="flex flex-col justfiy-content-center align-items-start flex-grow">
        <div class="help-container cursor-pointer" (click)="this.focusElementById('record_toolbar_name')">
          <mat-icon inline svgIcon="record_help_1" class="help-icon"></mat-icon>
          <span>{{ 'record-toolbar.help.step-timer-1' | translate }}</span>
        </div>
      </div>
      <div class="flex flex-col justfiy-content-center align-items-start" style="width: 240px">
        <div class="help-container cursor-pointer" (click)="this.clickElementById('record_toolbar_project')">
          <mat-icon inline svgIcon="record_help_2" class="help-icon"></mat-icon>
          <span>{{ 'record-toolbar.help.step-timer-2' | translate }}</span>
        </div>
      </div>
      <div class="flex flex-col justfiy-content-center align-items-start" style="width: 160px">
        <div class="help-container cursor-pointer" (click)="this.clickElementById('record_toolbar_timer_submit')">
          <mat-icon inline svgIcon="record_help_timer_3" class="help-icon"></mat-icon>
          <span>{{ 'record-toolbar.help.step-timer-3' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #manualModeGhost>
  <div fxLayout="column" class="time-entries-wrapper">
    <div class="flex flex-row items-center d-none">
      <div class="flex flex-col justfiy-content-center align-items-start flex-grow">
        <div class="help-container cursor-pointer" (click)="this.focusElementById('record_toolbar_name')">
          <mat-icon inline svgIcon="record_help_1" class="help-icon"></mat-icon>
          <span>{{ 'record-toolbar.help.step-1' | translate }}</span>
        </div>
      </div>
      <div class="flex flex-col justfiy-content-center align-items-start" style="width: 290px">
        <div class="help-container cursor-pointer" (click)="this.clickElementById('record_toolbar_project')">
          <mat-icon inline svgIcon="record_help_2" class="help-icon"></mat-icon>
          <span>{{ 'record-toolbar.help.step-2' | translate }}</span>
        </div>
      </div>
      <div class="flex flex-col justfiy-content-center align-items-start" style="width: 288px">
        <div class="help-container cursor-pointer" (click)="this.clickElementById('record_toolbar_start')">
          <mat-icon inline svgIcon="record_help_3" class="help-icon"></mat-icon>
          <span>{{ 'record-toolbar.help.step-3' | translate }}</span>
        </div>
      </div>
      <div class="flex flex-col justfiy-content-center align-items-start" style="width: 60">
        <div class="help-container cursor-pointer" (click)="this.clickElementById('record_toolbar_submit')">
          <mat-icon inline svgIcon="record_help_4" class="help-icon"></mat-icon>
          <span>{{ 'record-toolbar.help.step-4' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #widgetLoadingRef>
  <mat-progress-spinner [strokeWidth]="2" [diameter]="18" class="mat-white" mode="indeterminate">
  </mat-progress-spinner>
</ng-template>
