import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapProp',
})
export class MapPropPipe implements PipeTransform {
  transform(value: any[], prop: string): any[] {
    return value.map((x) => x?.[prop]);
  }
}
