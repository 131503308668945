import { Component, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { initialScopes, UserService, UserSettingsQuery } from 'timeghost-api';
import { Router, NavigationExtras } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FeedbackDialogComponent } from '../dialogs/feedback-dialog/feedback-dialog.component';
import { environment } from '@env/environment';
import { MsalService } from '@azure/msal-angular';
import { AppService } from '@app/app.service';
import { isAfter } from 'date-fns';
import { map } from 'rxjs/operators';
import parseSubscriptionAsStatus from '@app/_helpers/parseSubscriptionAsStatus';
import { UserAvatarComponent } from '../user-avatar/user-avatar.component';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { AccountInfo } from '@azure/msal-common';
import { resetStores } from '@datorama/akita';
import { ShellComponent } from '@app/shell/shell.component';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  get currentWorkspace() {
    return this.userSettings.workspace;
  }
  @ViewChild(UserAvatarComponent, { read: MatMenuTrigger, static: true })
  menuTrigger: MatMenuTrigger;
  @Input()
  disableMenu: boolean = false;
  @Input()
  closeOnNavigation: boolean = false;

  constructor(
    private msalService: MsalService,
    private userSettingsQuery: UserSettingsQuery,
    private userService: UserService,
    private appService: AppService,
    private router: Router,
    @Optional()
    private shell: ShellComponent,
    private dialog: MatDialog
  ) {}
  closeShell() {
    if (this.shell?.isMobile && this.shell.sidenav?.mode === 'over') {
      this.shell.sidenav.close(), this.menuTrigger?.closeMenu?.();
    }
  }
  status$ = this.userSettingsQuery.select().pipe(
    map((x) => {
      return parseSubscriptionAsStatus(x.workspace, x);
    })
  );
  userSettings$ = this.userSettingsQuery.select();
  get isExpired() {
    return parseSubscriptionAsStatus(this.userSettings.workspace, this.userSettings).isExpired;
  }
  get isTrial() {
    return parseSubscriptionAsStatus(this.userSettings.workspace, this.userSettings).isTrial;
  }
  navigateWorkspace(commands: any[], extras?: NavigationExtras) {
    return this.router.navigate(['settings', 'workspace', ...commands], extras);
  }
  ngOnInit() {}
  logout() {
    if (this.appService.isTeams()) {
      localStorage.clear(), sessionStorage.clear();
      localStorage.setItem('teams_auth_scopes', initialScopes.join(','));
      localStorage.setItem('teams_auth_clientId', environment.adalConfig.clientId);
      return this.userService.teamsLogout();
    }

    resetStores();
    this.msalService.logout();
  }
  get selectedTheme() {
    return this.appService.selectedTheme;
  }
  set selectedTheme(val: 'default' | 'dark' | 'light') {
    this.appService.setMode(val);
  }
  openFeedback() {
    return this.dialog.open(FeedbackDialogComponent);
  }
  openCurrentWorkspaceCheckout() {
    window.dataLayer.push({
      purchase_c2a: 'user-menu button',
    });

    return this.router.navigate(['/settings/workspace/plans']);
  }
  get userSettings() {
    return this.userSettingsQuery.getValue();
  }
  get packageVersion() {
    return environment.version;
  }
  get releaseName() {
    return environment.releaseName;
  }
  get isBeta() {
    return environment.isBeta;
  }
  get isdev() {
    return !environment.production;
  }
  @Input()
  size: string = '42px';
  get isDarkMode(): boolean {
    if (this.appService.selectedTheme === 'default') {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return true;
      }
    }
    return this.appService.selectedTheme === 'dark';
  }
  async toggleDarkMode() {
    const isDark = this.isDarkMode;
    return this.appService.setMode(isDark ? 'light' : 'dark');
  }
}
