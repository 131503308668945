import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { HttpClient } from '@angular/common/http';
import { SVG_ICON_REG } from './material-svg-icons';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MaterialSvgRegistryService {
  constructor(private matIconReg: MatIconRegistry, private httpClient: HttpClient, private domSan: DomSanitizer) {}
  initialize() {
    this.httpClient.disableApiPrefix();
    Object.keys(SVG_ICON_REG).map(x => {
      this.matIconReg.addSvgIcon(x, this.domSan.bypassSecurityTrustResourceUrl(SVG_ICON_REG[x]));
    });
  }
}
