import { NgModule, SkipSelf, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeRangePickerComponent } from './time-range-picker.component';
import { MaterialModule } from '@app/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarRangePickerComponent } from './calendar-range-picker/calendar-range-picker.component';
import { CalendarRangePickerService } from './calendar-range-picker/calendar-range-picker.service';
import RangeDateAdapter from './calendar-range-picker/RangeDateAdapter';
import { DateAdapter } from '@angular/material/core';
import {
  MatDateRangeSelectionStrategy,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
} from '@angular/material/datepicker';
import { DateFnsModule } from 'ngx-date-fns';
function MAT_CALENDAR_RANGE_STRATEGY_PROVIDER_FACTORY(
  parent: MatDateRangeSelectionStrategy<unknown>,
  adapter: DateAdapter<unknown>
) {
  return parent || new DefaultMatCalendarRangeStrategy(adapter);
}
@NgModule({
  declarations: [TimeRangePickerComponent, CalendarRangePickerComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    DateFnsModule,
    TranslateModule,
  ],
  providers: [
    CalendarRangePickerService,
    { provide: DateAdapter, useClass: RangeDateAdapter },
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      deps: [[new Optional(), new SkipSelf(), MAT_DATE_RANGE_SELECTION_STRATEGY], DateAdapter],
      useFactory: MAT_CALENDAR_RANGE_STRATEGY_PROVIDER_FACTORY,
    },
  ],
  exports: [TimeRangePickerComponent],
})
export class TimeRangePickerModule {}
