import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { debounceTimeAfterFirst } from '@app/_helpers/debounceAfterTime';
import { createRxValue, distinctUntilChangedJson } from '@app/_helpers/utils';
import { BehaviorSubject, combineLatest, defer } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { firstBy } from 'thenby';
import { Project, ProjectsQuery, ProjectsService, Task, UserService, UserSettingsQuery } from 'timeghost-api';
import { TimeTrackerCalendarStepperCreateDialogComponent } from '../time-tracker-calendar-stepper-create-dialog.component';

type SelectedEntity = {
  project: Project;
  task?: Task;
};
@Component({
  selector: 'tg-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit {
  search = new FormControl('');

  private _selectedEntity: SelectedEntity = this.stepper?.entityData;
  public get selectedEntity(): SelectedEntity {
    return this._selectedEntity;
  }
  public set selectedEntity(v: SelectedEntity) {
    this._selectedEntity = v;
    this.selectedEntityChange.emit(v);
  }
  @Output('entityChange') selectedEntityChange = new EventEmitter<SelectedEntity>();

  readonly entries$ = this.projectsQuery.selectAll({ filterBy: (x) => !x?.completed });
  readonly entries$filtered = combineLatest([
    this.entries$.pipe(startWith(this.projectsQuery.getAll())),
    this.search.valueChanges.pipe(startWith(''), debounceTime(300), distinctUntilChanged()),
    this.userSettingsQuery.select((x) => x.pinnedProjects).pipe(debounceTime(50)),
    this.selectedEntityChange.asObservable().pipe(startWith(this._selectedEntity), distinctUntilChanged()),
  ]).pipe(
    map(([entries, q, pinnedProjects, selected]: [Project[], string, string[], SelectedEntity]) => {
      if (!q?.length) return [entries, pinnedProjects, selected];
      return [
        entries.filter(
          (x) =>
            x.client &&
            x.name &&
            (x.name.toLowerCase().indexOf(q.toLowerCase()) !== -1 ||
              x.client.name.toLowerCase().indexOf(q.toLowerCase()) !== -1)
        ),
        pinnedProjects,
        selected,
      ];
    }),
    map(([entries, pinnedProjects, selected]: [Project[], string[], SelectedEntity]) => {
      return (
        entries
          .map((x) => ({
            ...x,
            pinned: pinnedProjects.findIndex((p) => p === x.id) !== -1,
            selected: selected?.project && x.id === selected.project.id,
          }))
          // @ts-ignore
          .sort((a, b) => b.pinned - a.pinned)
          .sort((a, b) =>
            a.pinned && b.pinned
              ? pinnedProjects.findIndex((x) => x === a.id) - pinnedProjects.findIndex((x) => x === b.id)
              : 0
          )
          // @ts-ignore
          .sort((a, b) => b.selected - a.selected)
      );
    })
  );
  private _taskName = new BehaviorSubject<string>(null);
  readonly taskName$ = this._taskName.asObservable().pipe(distinctUntilChanged());
  get taskName() {
    return this._taskName.getValue();
  }
  @Input('suggestProjectByName')
  set taskName(val: string) {
    this._taskName.next(val);
  }
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;
  readonly showMore = createRxValue(false);
  readonly entries$suggestions = combineLatest([
    this.taskName$.pipe(startWith(this.taskName), distinctUntilChanged(), debounceTimeAfterFirst(100)),
    this.selectedEntityChange.asObservable().pipe(startWith(this.selectedEntity)),
    this.showMore.value$,
  ]).pipe(
    filter(([taskName]) => taskName?.length >= 2),
    distinctUntilChangedJson(),
    switchMap(([taskName, selected, showMore]) =>
      defer(() => {
        return this.projectsService
          .findByTaskName(taskName)
          .then((entries) => entries?.uniqBy(({ project, task }) => `${project?.id}${task?.id}`))
          .then((entries) => {
            return entries
              ?.filter((item) => !!item)
              .map(({ project, task }) => {
                return {
                  ...project,
                  task,
                  selected: selected && selected.project?.id === project.id && selected.task?.id === task?.id,
                };
              });
          });
      }).pipe(
        map((x) => {
          return x.sort(firstBy('selected', 'desc').thenBy('name'));
        }),
        map((x) => {
          const maxItems = 3;
          return {
            entries: x?.length > 0 ? (showMore ? x : x.slice(0, maxItems)) : null,
            length: x?.length,
            showMore: x?.length > maxItems ? showMore : undefined,
          };
        })
      )
    ),
    map((x) => (x.entries?.length > 0 ? x : null))
  );
  constructor(
    private projectsQuery: ProjectsQuery,
    private userSettingsQuery: UserSettingsQuery,
    private userService: UserService,
    private projectsService: ProjectsService,
    private stepper: TimeTrackerCalendarStepperCreateDialogComponent,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit(): void {}
  trackId(i: number, { id }: { id: string }) {
    return id;
  }
  submitProject(entity?: SelectedEntity) {
    this.selectedEntity = entity;
  }
  togglePinProject(id: string) {
    return (
      this.isProjectPinned(id) ? this.userService.removePinnedProject(id) : this.userService.addPinnedProject(id)
    ).toPromise();
  }
  isProjectPinned(id: string) {
    return this.userSettingsQuery.getValue().pinnedProjects?.findIndex((x) => x === id) >= 0;
  }
  projectMenuPosition = { x: '0px', y: '0px' };
  openContextMenu(event: MouseEvent, trigger: MatMenuTrigger, data: any) {
    event.stopPropagation(), event.preventDefault();
    this.projectMenuPosition.x = event.clientX + 'px';
    this.projectMenuPosition.y = event.clientY + 'px';
    trigger.menuData = data;
    trigger.menu.focusFirstItem('mouse');
    trigger.openMenu();
  }
}
