import { state } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { AppService } from '@app/app.service';
import { GradientColor } from '@app/classes/gradient';
import { I18nService } from '@app/core';
import { RecordToolbarComponent } from '@app/shared/record-toolbar/record-toolbar.component';
import { RecordToolbarService } from '@app/shared/record-toolbar/record-toolbar.service';
import {
  TableDisplayType,
  TimeTrackerTableComponent,
} from '@app/shared/time-tracker-table/time-tracker-table.component';
import { ShellComponent } from '@app/shell/shell.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { toDate } from 'date-fns-tz';
import { isAfter, sub, subDays } from 'date-fns/esm';
import { format, setMilliseconds, startOfDay } from 'date-fns/esm/fp';
import { flow } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { auditTime, distinctUntilChanged, filter, finalize, map, startWith, takeUntil } from 'rxjs/operators';
import { Logger, MyTimesQuery, MyTimesService, UserService, UserSettingsQuery } from 'timeghost-api';
import { MyTimesStore } from 'timeghost-api/lib/stores/myTimes/myTimes.store';

import { HomeComponent } from '../home.component';

const log = new Logger('home');

export const TIMEFORMAT = {
  parse: {
    dateInput: 'H:mm:ss',
  },
  display: {
    dateInput: 'H:mm:ss',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@UntilDestroy()
@Component({
  selector: 'app-home-timer',
  templateUrl: './home-timer.component.html',
  styleUrls: ['./home-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeTimerComponent implements OnInit, AfterViewInit, OnDestroy {
  _onDestroy: Subject<void> = new Subject<void>();
  @ViewChild('recordToolbar', { static: false }) recordToolbar: RecordToolbarComponent;
  @ViewChild(TimeTrackerTableComponent, { static: true }) table: TimeTrackerTableComponent;
  @ViewChild('timer', { static: true })
  timer: ElementRef;
  @ViewChild(MatSort, { static: true })
  recordSortTable: MatSort;
  counter: number;
  running = false;
  timerIntervalRef: any;

  headerColor: GradientColor;
  quote: string;
  @ViewChild('projectSelector', { static: true })
  _user: any;
  private _showCompleted: BehaviorSubject<boolean> = new BehaviorSubject(false);

  showCompleted = this._showCompleted.asObservable();

  setShowCompleted = (ev: MatCheckboxChange) => this._showCompleted.next(ev.checked);
  isLoading: Observable<boolean> = combineLatest([this.myTimeQuery.selectLoading().pipe(startWith(false))]).pipe(
    auditTime(100),
    map(([timesLoading]) => timesLoading),
    distinctUntilChanged()
  );
  readonly timeRunning$ = this.myTimeQuery.selectAll({ filterBy: (x) => !x.end, limitTo: 1 }).pipe(
    map((times) => times[0]),
    finalize(() => {
      this.cdr.detectChanges();
    })
  );
  constructor(
    private language: I18nService,
    private media: MediaObserver,
    public shell: ShellComponent,
    private appService: AppService,
    private myTimesService: MyTimesService,
    private snackbar: MatSnackBar,
    private i18nService: TranslateService,
    private userService: UserService,
    private myTimeQuery: MyTimesQuery,
    private userSettingsQuery: UserSettingsQuery,
    private parent: HomeComponent,
    private recordService: RecordToolbarService,
    private cdr: ChangeDetectorRef
  ) {
    log.debug('home construct');
    // todo unbedingt ändern, nicht gut gelößt
  }
  readonly isMobile$ = this.parent.isMobile$;
  readonly isMobileCalendar$ = this.parent.isMobileCalendar$;
  navToggle() {
    this.shell.sidenav.toggle();
  }
  get isManualMode() {
    return !!this.userSettingsQuery.getValue()?.settings.captureManualMode;
  }
  tableDisplayTypes: typeof TableDisplayType = TableDisplayType;
  selectedDisplayMode$ = this.userSettingsQuery.select((x) => x.settings.tableDisplay);
  displayModeLoading: string = null;
  selectTableDisplayMode(mode: TableDisplayType) {
    const tableDisplay =
      {
        [TableDisplayType.Calendar]: 'Calendar',
        [TableDisplayType.Grouped]: 'Grouped',
        [TableDisplayType.Simple]: 'Simple',
      }[mode] || ('Simple' as any);
    if (tableDisplay === this.userSettingsQuery.getValue().settings.tableDisplay) {
      return;
    }
    window.dataLayer.push({
      Timer_View: mode,
    });
    this.displayModeLoading = tableDisplay;
    this.userService
      .changeSettings({
        tableDisplay,
      })
      .toPromise()
      .then(() => (this.displayModeLoading = null));
  }
  get isTeams() {
    return this.appService.isTeams();
  }
  readonly hasEntries$ = combineLatest([this.myTimeQuery.selectAll(), this.myTimeQuery.selectLoading()]).pipe(
    map((x) => x[0]),
    takeUntil(this._onDestroy),
    map((x) => x?.length > 0),
    distinctUntilChanged()
  );
  get isnavOpen() {
    return this.shell.sidenav.opened;
  }
  get hasLoadMore() {
    return this.myTimeQuery.getHasMore();
  }
  focusElementById(expr: string) {
    document.getElementById(expr)?.focus();
  }
  clickElementById(expr: string) {
    document.getElementById(expr)?.click();
  }
  checkContentScroll(el: HTMLDivElement) {
    return el && el.scrollTop && el.scrollTop > 64;
  }
  private _ghostState = new BehaviorSubject<boolean>(false);
  readonly ghostState$ = this._ghostState.asObservable().pipe(distinctUntilChanged());
  get ghostState() {
    return this._ghostState.getValue();
  }
  set ghostState(val: boolean) {
    this._ghostState.next(val);
  }

  onGhostFocus(state: boolean = false) {
    this.ghostState = state;
  }
  private _byDayTreshold = new BehaviorSubject<number>(7);
  readonly byDayTreshold$ = this._byDayTreshold.asObservable().pipe(distinctUntilChanged());
  readonly byDayTresholdRange = this.byDayTreshold$.pipe(
    map((x) => {
      let from = sub(new Date(), { days: x });
      let to = new Date();
      return { from, to };
    })
  );

  get byDayTreshold() {
    return this._byDayTreshold.getValue();
  }
  set byDayTreshold(val: number) {
    this._byDayTreshold.next(val);
  }
  ngOnInit() {
    if (this.myTimeQuery.getCount((x) => !isAfter(new Date(x.start), subDays(new Date(), 5))) > 15) {
      (this.myTimeQuery.__store__ as MyTimesStore).remove((x) => !isAfter(new Date(x.start), subDays(new Date(), 5)));
      this.myTimesService.get('init');
    }
    if (this.media.isActive(['xs', 'sm']) && this.userSettingsQuery.getValue().settings.tableDisplay === 'Calendar') {
      this.selectTableDisplayMode(TableDisplayType.Simple);
      this.cdr.markForCheck();
    }
    combineLatest([this.isMobileCalendar$, this.userSettingsQuery.select((x) => x.settings.tableDisplay)])
      .pipe(
        filter(([x, displayMode]) => !!x && displayMode === 'Calendar'),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.selectTableDisplayMode(TableDisplayType.Simple);
        this.cdr.markForCheck();
      });
  }
  ngAfterViewInit(): void {}
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  get counterDate() {
    return flow(startOfDay, setMilliseconds(this.counter), format('H:mm:ss'))(new Date());
  }
  recordLoading$ = this.recordService.saveLoading$;
  onScrolledBottom = new Subject<void>();
  onBottomThreshold(el: ElementRef<HTMLDivElement>, force?: boolean) {
    log.debug('bottom hit');
    if (force === true || !el || el.nativeElement.scrollHeight - el.nativeElement.scrollTop > 200)
      this.onScrolledBottom.next();
  }
  now() {
    return new Date();
  }
  get currentDate(): Date {
    return toDate(new Date(), { locale: { code: this.lang } });
  }
  get lang(): string {
    return this.language.language;
  }
  clearTimer() {
    log.debug(this.counter);
    this.counter = undefined;
    localStorage.removeItem('pTimerStart');
    clearInterval(this.timerIntervalRef);
    this.cdr.markForCheck();
  }
  openCreateDialog() {
    return this.parent.openCreateDialog();
  }
}
