import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarWeekViewComponent } from 'angular-calendar';
import { BehaviorSubject, combineLatest, forkJoin, fromEvent, Subject } from 'rxjs';
import { distinctUntilChanged, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  FeedEntry,
  FeedQuery,
  FeedService,
  Logger,
  MyTimesQuery,
  MyTimesService,
  ProjectsQuery,
  Time,
  TimesService,
  UserSettingsQuery,
} from 'timeghost-api';
import {
  addMinutes,
  addWeeks,
  differenceInMinutes,
  differenceInSeconds,
  endOfDay,
  endOfWeek,
  isBefore,
  isSameDay,
  startOfWeek,
  subWeeks,
} from 'date-fns/esm';
import { WeekViewHourSegment, WeekViewTimeEvent } from 'calendar-utils';
import { MatDialog } from '@angular/material/dialog';
import {
  TimeTrackCreateData,
  TimeTrackerCalendarCreateDialogComponent,
} from '../time-tracker-calendar-create-dialog/time-tracker-calendar-create-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppService } from '@app/app.service';
import { MyTimesStore } from 'timeghost-api/lib/stores/myTimes/myTimes.store';
import { TimeTrackerCalendarUpdateDialogComponent } from '../time-tracker-calendar-update-dialog/time-tracker-calendar-update-dialog.component';
import produce from 'immer';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import { DateRange } from '@angular/material/datepicker';
import { debounceTimeAfterFirst } from '@app/_helpers/debounceAfterTime';
import { AfterViewInit } from '@angular/core';
import { TimeTrackerCalendarStepperCreateDialogComponent } from '../time-tracker-calendar-stepper-create-dialog/time-tracker-calendar-stepper-create-dialog.component';
import { NaNZeroify } from '@app/_helpers/utils';

const log = new Logger('TimeTrackerCalendarComponent');
function floorToNearest(amount: number, precision: number) {
  return Math.floor(amount / precision) * precision;
}

function ceilToNearest(amount: number, precision: number) {
  return Math.ceil(amount / precision) * precision;
}

@UntilDestroy()
@Component({
  selector: 'app-time-tracker-calendar',
  templateUrl: './time-tracker-calendar.component.html',
  styleUrls: ['./time-tracker-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeTrackerCalendarComponent implements OnInit, AfterViewInit {
  viewDate = new Date();
  viewRefresh = new Subject<void>();

  private _startFromWorkDay: boolean = JSON.parse(localStorage.startFromWorkDay || 'false');
  public get startFromWorkDay(): boolean {
    return this._startFromWorkDay;
  }
  public set startFromWorkDay(v: boolean) {
    const last = this._startFromWorkDay;
    this._startFromWorkDay = v;
    localStorage.startFromWorkDay = v;
    if (last !== v) this.viewRefresh?.next();
  }
  get userSettings() {
    return this.userSettingsQuery.getValue();
  }
  private _events = new BehaviorSubject<CalendarEvent[]>([]);
  readonly events$ = combineLatest([
    this.myTimesQuery.selectAll({ filterBy: (x) => !!x.end }),
    this.feedQuery.selectAll(),
    this._events.asObservable().pipe(distinctUntilChanged()),
  ]).pipe(
    map(([times, feedEvents, events]) => {
      this.setColors(times);
      return [
        ...times
          .filter((x) => x.timeDiff >= 0)
          .map((t) => {
            const start = new Date(t.start),
              end = new Date(t.end);
            const disabledState = t.project?.completed;
            return <CalendarEvent>{
              id: t.id,
              title: t.name,
              ...(() => {
                //@ts-ignore
                if (t.inputMode === 'duration')
                  return {
                    allDay: true,
                  };
                return {};
              })(),
              start: new Date(start.setDate(new Date(t.start).getDate())),
              end: new Date(end.setDate(new Date(t.end).getDate())),
              draggable: !disabledState,
              resizable: {
                beforeStart: !disabledState, // this allows you to configure the sides the event is resizable from
                afterEnd: !disabledState,
              },
              meta: {
                time: t,
                outlook: t.outlookCalenderReference
                  ? feedEvents.find((x) => x.id === t.outlookCalenderReference)
                  : undefined,
              },
            };
          }),
        ...feedEvents
          .filter((x) => ['calendar'].includes(x.type) && !x.booked)
          .map((t) => {
            const start = new Date(t.start),
              end = new Date(t.end);
            return <CalendarEvent>{
              id: t.id,
              title: t.name,
              start: new Date(start.setDate(new Date(t.start).getDate())),
              end: new Date(end.setDate(new Date(t.end).getDate())),
              draggable: false,
              resizable: {
                beforeStart: false, // this allows you to configure the sides the event is resizable from
                afterEnd: false,
              },
              meta: {
                feed: t,
                type: t.type,
              },
            };
          }),
        ...events,
      ];
    }),
    // map(events => uniqBy(events, e => e.id)),
    tap((x) => {
      log.debug('events', x);
    })
  );
  get events() {
    return this._events.getValue();
  }
  set events(val: CalendarEvent[]) {
    this._events.next(val);
  }

  dragToCreateActive = false;
  private _isLoading = new BehaviorSubject<boolean>(false);
  readonly isLoading$ = this._isLoading.asObservable().pipe(distinctUntilChanged());
  get isLoading() {
    return this._isLoading.getValue();
  }
  set isLoading(val: boolean) {
    this._isLoading.next(val);
  }

  weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 = 1;
  private _timeEntries = new BehaviorSubject<Time[]>(null);
  @Output('OnTimeEntriesUpdate')
  readonly timeEntries$ = this._timeEntries.asObservable().pipe(distinctUntilChanged());
  @Input()
  get timeEntries() {
    return this._timeEntries.getValue();
  }
  set timeEntries(val: Time[]) {
    this._timeEntries.next(val);
  }
  @ViewChild('mwlCal', { static: true })
  private weekView: CalendarWeekViewComponent;
  constructor(
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private appService: AppService,
    private userSettingsQuery: UserSettingsQuery,
    private myTimesQuery: MyTimesQuery,
    private projectsQuery: ProjectsQuery,
    private myTimesService: MyTimesService,
    private feedQuery: FeedQuery,
    private feedService: FeedService,
    private timesService: TimesService,
    private translate: TranslateService,
    private el: ElementRef<HTMLElement>
  ) {
    if (localStorage.timeCalViewSegment) {
      const segmentPreset = this.segmentPresets.indexOf(localStorage.timeCalViewSegment ^ 0); //  tslint:disable-line:no-bitwise
      if (segmentPreset !== -1) this._selectedSegment = this.segmentPresets[segmentPreset];
    }
  }
  ngOnInit() {}
  ngAfterViewInit() {
    this.todayView();
  }
  get currentFormat() {
    return this.appService.formatAMPM();
  }
  onViewDateChange(ev: any) {
    log.debug(ev);
  }
  isDefaultProject(id: string) {
    return this.projectsQuery.getEntity(id)?.useAsDefault === true;
  }
  getProjectColor(id: string) {
    return this.projectsQuery.getEntity(id)?.color;
  }
  private setColors(x: Time[]) {
    const uniqItems = x.reduce((l, r) => {
      if (!r?.project?.id) return l;
      return { ...l, [r.project.id]: r.id };
    }, {});
    Object.keys(uniqItems).forEach((id) => {
      if (!this.appService.hasColorById(id, 'calview')) this.appService.setColorById(id, null, 'calview');
    });
  }
  async prevView() {
    this.isLoading = true;
    this.viewDate = subWeeks(this.viewDate, 1);
    const start = startOfWeek(this.viewDate, { weekStartsOn: this.weekStartsOn }),
      end = endOfWeek(this.viewDate, { weekStartsOn: this.weekStartsOn });
    forkJoin([
      this.timesService.getMyTimesBetweenRange(start, end).pipe(
        untilDestroyed(this),
        tap((x) => {
          if (x?.length > 0) {
            (this.myTimesQuery.__store__ as MyTimesStore).upsertMany(x);
            this.setColors(x);
          }
        })
      ),

      this.feedService.load(start, end),
    ]).subscribe(
      () => (this.isLoading = false),
      () => (this.isLoading = false)
    );
  }
  async todayView() {
    this.isLoading = true;
    this.viewDate = new Date();
    const start = startOfWeek(this.viewDate, { weekStartsOn: this.weekStartsOn }),
      end = endOfWeek(this.viewDate, { weekStartsOn: this.weekStartsOn });
    forkJoin([
      this.timesService.getMyTimesBetweenRange(start, end).pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false))
      ),
      this.feedService.load(start, end),
    ]).subscribe(([x]) => {
      if (x?.length > 0) this.setColors(x);
    });
  }
  async nextView() {
    this.isLoading = true;
    this.viewDate = addWeeks(this.viewDate, 1);
    const start = startOfWeek(this.viewDate, { weekStartsOn: this.weekStartsOn }),
      end = endOfWeek(this.viewDate, { weekStartsOn: this.weekStartsOn });
    forkJoin([
      this.timesService.getMyTimesBetweenRange(start, end).pipe(
        untilDestroyed(this),
        tap((x) => {
          if (x?.length > 0) (this.myTimesQuery.__store__ as MyTimesStore).upsertMany(x), this.setColors(x);
        })
      ),
      this.feedService.load(start, end),
    ]).subscribe(
      () => (this.isLoading = false),
      () => (this.isLoading = false)
    );
  }
  async selectView({ start, end }: DateRange<Date>) {
    this.isLoading = true;
    this.viewDate = start;
    forkJoin([
      this.timesService.getMyTimesBetweenRange(start, end).pipe(
        untilDestroyed(this),
        tap((x) => {
          if (x?.length > 0) (this.myTimesQuery.__store__ as MyTimesStore).upsertMany(x), this.setColors(x);
        })
      ),
      this.feedService.load(start, end),
    ]).subscribe(
      () => (this.isLoading = false),
      () => (this.isLoading = false)
    );
  }
  async setViewStart() {
    const view: HTMLElement = this.el.nativeElement?.querySelector('.cal-week-view');
    if (!view) return;
    const offset = view.querySelector('.cal-time-events')?.querySelectorAll('.cal-hour')[6]?.getBoundingClientRect();
    if (!offset) return;
    view.focus({
      preventScroll: false,
    });
    view.scrollTo({
      top: offset.bottom + offset.height,
      behavior: 'auto',
    });
  }
  startDragToMove(week: WeekViewTimeEvent, mouseDownEvent: MouseEvent) {
    if (mouseDownEvent.button !== 0) return;
    const untilMouseUp = fromEvent(document, 'mouseup').pipe(take(1));
    const { event } = week;
    untilMouseUp
      .pipe(
        switchMap(() => {
          const newEnd = event.end,
            newStart = event.start;
          return this.myTimesService.update({
            ...event.meta.time,
            start: newStart.toISOString(),
            end: newEnd.toISOString(),
          });
        })
      )
      .subscribe(
        ([x]) => {
          const time = produce(this.myTimesQuery.getEntity(x.id), (draft) => {
            draft.start = x.start;
            draft.end = x.end;
          });
          event.start = new Date(time.start);
          event.end = new Date(time.end);
          delete event.meta.loading;
        },
        (err) => {
          event.start = new Date(event.meta.time.start);
          event.end = new Date(event.meta.time.end);
          delete event.meta.loading;
        }
      );
    const endOfView = endOfWeek(this.viewDate, {
      weekStartsOn: this.weekStartsOn,
    });
    fromEvent(document, 'mousemove')
      .pipe(
        distinctUntilChanged((l: MouseEvent, r: MouseEvent) => l.clientY === r.clientY),
        takeUntil(untilMouseUp)
      )
      .subscribe((mouseMoveEvent: MouseEvent) => {
        const minutesDiff = ceilToNearest(mouseMoveEvent.clientY - week.top, this.selectedSegmentSnapSize);

        const newEnd = addMinutes(event.end, minutesDiff),
          newStart = addMinutes(event.start, minutesDiff);
        log.debug('from mousemove', newEnd, week, minutesDiff);
        if (newEnd > newStart && newEnd < endOfView) {
          event.start = newStart;
          event.end = newEnd;
        }
        this.refresh(false);
      });
  }
  startDragToCreate(segment: WeekViewHourSegment, mouseDownEvent: MouseEvent, segmentElement: HTMLElement) {
    if (mouseDownEvent.button !== 0) return;
    if (
      this.userSettings.workspace.settings &&
      !this.userSettings.workspace.settings.allowFutureTimeTracking &&
      !isBefore(segment.date, endOfDay(new Date()))
    ) {
      return;
    }
    const dragToSelectEvent: CalendarEvent = {
      id: this.events.length,
      title: this.translate.instant('timer.calendar.newevent'),
      start: segment.date,
      end: addMinutes(segment.date, this.selectedSegmentSnapSize),
      meta: {
        tmpEvent: true,
      },
    };
    this.events = [...this.events, dragToSelectEvent];
    this.dragToCreateActive = true;
    const endOfView = endOfWeek(this.viewDate, {
      weekStartsOn: this.weekStartsOn,
    });
    const untilMouseUp = fromEvent(document, 'mouseup').pipe(take(1));
    untilMouseUp.subscribe(() => {
      (dragToSelectEvent.meta.loading = true),
        this.openCreateDialog({
          ...dragToSelectEvent,
        }),
        this.events.splice(this.events.indexOf(dragToSelectEvent));
    });
    fromEvent(document, 'mousemove')
      .pipe(
        distinctUntilChanged((l: MouseEvent, r: MouseEvent) => l.clientY === r.clientY),
        takeUntil(untilMouseUp)
      )
      .subscribe((mouseMoveEvent: MouseEvent) => {
        const segmentPosition = segmentElement.getBoundingClientRect();
        const minutesDiff = ceilToNearest(mouseMoveEvent.clientY - segmentPosition.top, this.selectedSegmentSnapSize);

        const newEnd = addMinutes(segment.date, minutesDiff);
        log.debug('from mousemove', newEnd, segment.date, minutesDiff);
        if (newEnd > segment.date && newEnd < endOfView) {
          dragToSelectEvent.end = newEnd;
        }
        this.refresh(false);
      });
  }

  eventTimesChanged({ event, newStart, newEnd, type }: CalendarEventTimesChangedEvent): void {
    // if (type === CalendarEventTimesChangedEventType.Drag) return;
    if (!event.meta?.time?.id) return;
    event.meta.loading = true;
    const oldStart = event.start.toISOString(),
      oldEnd = event.end?.toISOString();
    event.start = newStart;
    event.end = newEnd;
    event.meta.oldStart = new Date(oldStart);
    event.meta.oldEnd = new Date(oldEnd);
    this.viewRefresh.next();
    this.dialog
      .open(TimeTrackerCalendarUpdateDialogComponent, {
        data: event,
      })
      .afterClosed()
      .subscribe((x) => {
        let entity: Time;
        if (x && (entity = this.myTimesQuery.getEntity(x.id))) {
          const time = produce(entity, (draft) => {
            draft.start = x.start;
            draft.end = x.end;
          });
          this.setColors([time]);
          (event.start = new Date(time.start)), (event.end = new Date(time.end));
        } else {
          (event.start = new Date(oldStart)), (event.end = new Date(oldEnd));
        }
        delete event.meta.loading;
        delete event.meta.oldStart;
        delete event.meta.oldEnd;
        this.viewRefresh.next();
      });
  }
  @ViewChild('eventContextTrigger', { static: true })
  contextMenu: MatMenuTrigger;

  contextMenuPosition = { x: '0px', y: '0px' };

  onContextMenu(event: MouseEvent, item: CalendarEvent) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = item;
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }
  getEntityColor(id: string) {
    return this.appService.getColorById(id, 'calview');
  }
  openCreateDialog(ev: CalendarEvent) {
    return this.dialog
      .open(TimeTrackerCalendarStepperCreateDialogComponent, {
        data: <TimeTrackCreateData>{
          title: ev.title,
          start: ev.start,
          end: ev.end || ev.start,
          timeDiff: ev.end ? differenceInSeconds(ev.end, ev.start) : this.selectedSegmentSnapSize,
        },
      })
      .afterClosed()
      .subscribe((x) => {
        if (x) {
          (ev.title = x.name), (ev.start = new Date(x.start)), (ev.end = new Date(x.end)), (ev.id = x.id);
          ev.meta.time = x;
          delete ev.meta.tmpEvent;
          delete ev.meta.loading;
        } else {
          this.events.splice(this.events.indexOf(ev));
        }
        this.dragToCreateActive = false;
        this.refresh();
      });
  }

  private refresh(checkEvents: boolean = true) {
    if (checkEvents) this.events = [...this.events];
    this.viewRefresh.next();
    this.cdr.detectChanges();
  }
  eventChange(ev: any) {
    log.debug(ev);
  }
  onEventClicked({ event: ev }: { event: CalendarEvent<{ time?: Time; feed?: FeedEntry }> }) {
    if (ev.meta.time?.project?.completed) return;
    if (ev.meta.feed) {
      return this.dialog
        .open(TimeTrackerCalendarStepperCreateDialogComponent, {
          data: { ...ev, outlookRefId: ev.meta.feed.id },
        })
        .afterClosed();
    }
    return this.dialog
      .open(TimeTrackerCalendarUpdateDialogComponent, {
        data: ev,
      })
      .afterClosed();
  }
  duplicateEvent(ev: CalendarEvent<{ time: Time; loading?: boolean }>) {
    ev.meta.loading = true;
    const time = this.myTimesQuery.getEntity(ev.meta.time.id);
    if (!time?.id) {
      ev.meta.loading = false;
      return;
    }
    this.myTimesService
      .add({
        billable: ev.meta.time.billable,
        name: ev.meta.time.name,
        // @ts-ignore
        task: ev.meta.time.task,
        ...(ev.meta.time.project?.completed
          ? {
              project: this.projectsQuery.getAll({ filterBy: (x) => x.useAsDefault })[0],
              task: null,
            }
          : {
              project: ev.meta.time.project,
              task: ev.meta.time.task,
            }),
        start: new Date(ev.meta.time.start),
        end: new Date(ev.meta.time.end),
        tags: ev.meta.time.tags as any,
      })
      .pipe(finalize(() => (ev.meta.loading = false)))
      .subscribe(() => {
        this.cdr.detectChanges();
        this.viewRefresh.next();
      });
  }
  removeEvent(ev: CalendarEvent<{ time: Time; loading?: boolean }>) {
    if (ev.meta.time.project?.completed) return;
    ev.meta.loading = true;
    const time = this.myTimesQuery.getEntity(ev.meta.time.id);
    if (!time?.id) {
      ev.meta.loading = false;
      return;
    }
    this.myTimesService
      .delete(time)
      .pipe(finalize(() => (ev.meta.loading = false)))
      .subscribe(() => {
        this.cdr.detectChanges();
        this.viewRefresh.next();
      });
  }
  private segmentPresets = [2, 4, 6, 12];

  private _selectedSegment: number = this.segmentPresets[0];
  get selectedSegment(): number {
    return this._selectedSegment;
  }
  set selectedSegment(v: number) {
    this._selectedSegment = v;
    (async () => {
      this.cdr.detectChanges();
      this.viewRefresh.next();
      localStorage.timeCalViewSegment = v;
    })();
  }
  get selectedSegmentSnapSize() {
    return 60 / this.selectedSegment;
  }

  zoomIn() {
    const index = this.segmentPresets.indexOf(this.selectedSegment);
    if (index === -1 || index >= this.segmentPresets.length - 1)
      this.selectedSegment = this.segmentPresets[this.segmentPresets.length - 1];
    else this.selectedSegment = this.segmentPresets[index + 1];
  }
  zoomOut() {
    const index = this.segmentPresets.indexOf(this.selectedSegment);
    if (index <= 0) this.selectedSegment = this.segmentPresets[0];
    else this.selectedSegment = this.segmentPresets[index - 1];
  }
  getBillableFromDate({ date }: { date: Date }) {
    const times = this.myTimesQuery.getAll({
      filterBy: (x) => isSameDay(Date.parse(x.start), date),
    });
    const ret = {
      billable: times.filter((x) => x.billable).reduce((l, r) => (l += r.timeDiff), 0),
      summed: times.reduce((l, r) => (l += r.timeDiff), 0),
    };
    return {
      ...ret,
      percentage: ret.summed <= 0 ? 0 : ret.billable <= 0 ? 0 : (ret.billable / ret.summed) * 100,
    };
  }
}
