import { Directionality } from '@angular/cdk/bidi';
import { Overlay, OverlayConfig, OverlayContainer, OverlayRef, ScrollStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, TemplatePortal } from '@angular/cdk/portal';
import { Location } from '@angular/common';
import { Directive, Inject, Injectable, InjectionToken, Injector, OnDestroy, Optional, SkipSelf } from '@angular/core';
import {
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MAT_DIALOG_SCROLL_STRATEGY,
  _MatDialogContainerBase,
} from '@angular/material/dialog';
import { _MatSheetBase } from '../mat-side-sheet/mat-side-sheet';
import { MatRightSideSheetComponent as MatSideSheetComponent } from './mat-right-side-sheet.component';
export const MAT_SHEET_DEFAULT_OPTIONS = new InjectionToken<MatDialogConfig>('mat-right-sheet-default-options');

/**
 * Service to open Material Design modal dialogs.
 */
@Injectable()
export class MatRightSideSheet extends _MatSheetBase<MatSideSheetComponent> {
  constructor(
    overlay: Overlay,
    injector: Injector,
    /**
     * @deprecated `_location` parameter to be removed.
     * @breaking-change 10.0.0
     */
    @Optional() location: Location,
    @Optional() @Inject(MAT_SHEET_DEFAULT_OPTIONS) defaultOptions: MatDialogConfig,
    @Inject(MAT_DIALOG_SCROLL_STRATEGY) scrollStrategy: any,
    @Optional() @SkipSelf() parentDialog: MatRightSideSheet,
    overlayContainer: OverlayContainer
  ) {
    super(
      overlay,
      injector,
      defaultOptions,
      parentDialog,
      overlayContainer,
      scrollStrategy,
      MatDialogRef,
      MatSideSheetComponent,
      MAT_DIALOG_DATA
    );
  }
}
