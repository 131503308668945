import { Inject, Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { includes } from 'lodash-es';

import { Logger } from 'timeghost-api';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { enUS as dateFns_enUS, de as dateFns_de, ptBR, es as esES } from 'date-fns/locale';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { CURRENCY_MASK_CONFIG } from 'ngx-currency';
import { CurrencyConfig } from '@app/services/ngx-currency/currency-config';
const log = new Logger('I18nService');
const languageKey = 'language';

/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @param {string} s The string to extract for translation.
 * @return {string} The same string.
 */
export function extract(s: string) {
  return s;
}

const dateFnsKeys = {
  'de-DE': dateFns_de,
  'en-US': dateFns_enUS,
  'es-ES': esES,
  'pt-PT': ptBR,
  'pt-BR': ptBR,
};

@Injectable()
export class I18nService {
  defaultLanguage: string;
  supportedLanguages: string[];

  constructor(
    private translateService: TranslateService,
    private dtAdapter: DateAdapter<Date>,
    private dateFnsConfig: DateFnsConfigurationService,
    @Inject(CURRENCY_MASK_CONFIG) private currencyAdapteConfig: CurrencyConfig
  ) {}

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * @param {!string} defaultLanguage The default language to use.
   * @param {Array.<String>} supportedLanguages The list of supported languages.
   */
  init(defaultLanguage: string, supportedLanguages: string[]) {
    this.defaultLanguage = defaultLanguage;
    this.supportedLanguages = supportedLanguages;
    this.language = '';

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      localStorage.setItem(languageKey, event.lang);
    });
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   * @param {string} language The IETF language code to set.
   */
  set language(language: string) {
    language = language || localStorage.getItem(languageKey) || this.translateService.getBrowserCultureLang();
    let isSupportedLanguage = includes(this.supportedLanguages, language);
    let langcode = language;
    // If no exact match is found, search without the region
    if (language && !isSupportedLanguage) {
      language = language.split('-')[0];
      language = this.supportedLanguages.find((supportedLanguage) => supportedLanguage.startsWith(language)) || '';
      isSupportedLanguage = Boolean(language);
    }

    // Fallback if language is not supported
    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
    }
    const langCode = language.split('-', 1)[0];

    log.debug(`Language set to ${language}`);
    this.translateService.use(language);
    if (this.dtAdapter) {
      this.dtAdapter.setLocale(language);
    }

    this.dateFnsConfig.setLocale(dateFnsKeys[language] ?? dateFnsKeys['en-US']);
    this.currencyAdapteConfig.updateConfig(
      langCode === 'de'
        ? {
            thousands: '.',
            decimal: ',',
          }
        : {
            thousands: ',',
            decimal: '.',
          }
    );
  }

  /**
   * Gets the current language.
   * @return {string} The current language code.
   */
  get language(): string {
    return this.translateService.currentLang;
  }
}
