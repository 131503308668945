import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-feed-filter-event-item',
  templateUrl: './feed-filter-event-item.component.html',
  styleUrls: ['./feed-filter-event-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedFilterEventItemComponent implements OnInit {
  @Input()
  image: string;
  @Input()
  title: string;

  @ViewChild(MatCheckbox, { static: true })
  private source: MatCheckbox;

  @Input()
  get checked(): boolean {
    return this._checked;
  }
  set checked(value: boolean) {
    if (value != this.checked) {
      this._checked = value;
      this._changeDetectorRef.markForCheck();
    }
  }
  private _checked: boolean = false;
  @Output() readonly change: EventEmitter<MatCheckboxChange> = new EventEmitter<MatCheckboxChange>();

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {}
  _onInteractionEvent(ev: Event) {
    ev.stopPropagation();
  }
  _onChangeEvent(ev: MatCheckboxChange) {
    const event = new MatCheckboxChange();
    event.source = this.source;
    event.checked = this.checked;
    this.change.emit(ev);
  }
}
