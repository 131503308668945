import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { FreshdeskService, ServerErrorBag, UserSettingsQuery } from 'timeghost-api';
import { distinctUntilChanged, finalize, map, filter } from 'rxjs/operators';
import { AppService } from '@app/app.service';
import { BehaviorSubject } from 'rxjs';
import { CustomValidators } from '@app/_validators/custom-validators';
import { TranslateService } from '@ngx-translate/core';
import { extract } from '@app/core';
export enum FeedbackType {
  GENERIC = 'generic',
  BUG = 'bug',
  FEEDBACK = 'feedback',
  ACCOUNT_DELETION = 'account-deletion',
}
export const FeedbackMinResponse = {
  [FeedbackType.GENERIC]: 10,
  [FeedbackType.BUG]: 20,
  [FeedbackType.FEEDBACK]: 10,
  [FeedbackType.ACCOUNT_DELETION]: 20,
};
export interface FeedbackDialogConfig {
  descriptionMinLength: number;
  description?: string;
  type?: FeedbackType;
}
@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent implements OnInit {
  private _isLoading = new BehaviorSubject<boolean>(false);
  readonly isLoading$ = this._isLoading.asObservable().pipe(distinctUntilChanged());
  get isLoading() {
    return this._isLoading.getValue();
  }
  set isLoading(val: boolean) {
    this._isLoading.next(val);
  }
  feedbackTypes: typeof FeedbackType = FeedbackType;
  feedbackTypesArr = Object.keys(FeedbackType).map((x) => ({ key: x, value: FeedbackType[x] }));
  group: UntypedFormGroup;
  constructor(
    private ref: MatDialogRef<FeedbackDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: Partial<FeedbackDialogConfig>,
    private freshdeskService: FreshdeskService,
    private translateService: TranslateService,
    private appService: AppService,
    userSettingsQuery: UserSettingsQuery
  ) {
    const userSettings = userSettingsQuery.getValue();
    this.group = new UntypedFormGroup({
      name: new UntypedFormControl(userSettings.officeProfile?.displayName || '', [
        Validators.required,
        CustomValidators.minLength(3),
      ]),
      description: new UntypedFormControl(data?.description || '', [
        Validators.required,
        CustomValidators.minLength(
          data?.descriptionMinLength || FeedbackMinResponse[data?.type || FeedbackType.GENERIC]
        ),
      ]),
      email: new UntypedFormControl(userSettings.officeProfile?.mail || '', [
        Validators.required,
        CustomValidators.minLength(3),
        Validators.email,
      ]),
      type: new UntypedFormControl(data?.type || FeedbackType.GENERIC, [Validators.required]),
      newsletter_subscription: new UntypedFormControl(false),
      subject: new UntypedFormControl('', [Validators.required, CustomValidators.minLength(3)]),
    });
  }

  ngOnInit() {
    this.ref.addPanelClass(['mat-dialog-relative', 'mat-dialog-vanilla']);
    this.ref.updateSize('460px');
    this.group.valueChanges
      .pipe(
        map((x) => x?.type),
        filter((x) => x !== undefined),
        distinctUntilChanged()
      )
      .subscribe((x: FeedbackType) => {
        this.group.controls.description.setValidators([
          Validators.required,
          CustomValidators.minLength(this.data?.descriptionMinLength || FeedbackMinResponse[x]),
        ]);
        this.group.controls.description.updateValueAndValidity();
      });
  }
  submit() {
    if (this.group.invalid) {
      return;
    }
    this.isLoading = true;
    return this.freshdeskService
      .add({
        ...this.group.value,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (x) => {
          this.ref.close(x),
            this.appService.notifier.show({
              message: this.translateService.instant(extract('utils.ticket-created')),
              type: 'success',
            });
        },
        (err) => {
          let errorBag: ServerErrorBag;
          if ((errorBag = err.errors)) {
            this.appService.notifier.show({
              message: errorBag[Object.keys(errorBag)[0]][0],
              type: 'error',
            });
          }
        }
      );
  }
}
