import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectTemplateDialogComponent } from './project-template-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { CustomDirectivesModule } from '@app/_directives/custom-directives.module';
import { CdkScrollableModule, ScrollingModule } from '@angular/cdk/scrolling';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ProjectTemplateDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    CustomDirectivesModule,
    ScrollingModule,
    TranslateModule,
  ],
  exports: [ProjectTemplateDialogComponent],
})
export class ProjectTemplateDialogModule {}
