import { Component } from '@angular/core';
import { FadeInOut, SlideUpDown, SlideUpDownBump } from '@app/animations/fade';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Logger, MyTimesQuery, MyTimesService, UserSettingsQuery, Time, ProjectsQuery } from 'timeghost-api';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import {
  TimeTrackCreateData,
  TimeTrackerCalendarCreateDialogComponent,
} from '@app/shared/time-tracker-calendar-create-dialog/time-tracker-calendar-create-dialog.component';
import { RecordToolbarService } from '@app/shared/record-toolbar/record-toolbar.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import produce from 'immer';
import { TimeTrackerCalendarUpdateDialogComponent } from '@app/shared/time-tracker-calendar-update-dialog/time-tracker-calendar-update-dialog.component';
import { CalendarEvent } from 'calendar-utils';

const log = new Logger('home');

export const TIMEFORMAT = {
  parse: {
    dateInput: 'H:mm:ss',
  },
  display: {
    dateInput: 'H:mm:ss',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@UntilDestroy()
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    FadeInOut,
    SlideUpDown,
    SlideUpDownBump,
    trigger('onTimerContentScroll', [
      state('show', style({ opacity: 1 })),
      state('hide', style({ opacity: 0 })),
      transition('show <=> hide', animate('225ms ease')),
    ]),
  ],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: TIMEFORMAT }],
})
export class HomeComponent {
  readonly isMobile$ = this.media
    .asObservable()
    .pipe(map((x) => x.findIndex((y) => ['xs', 'sm'].includes(y.mqAlias)) !== -1));
  readonly isMobileCalendar$ = this.media
    .asObservable()
    .pipe(map((x) => x.findIndex((y) => ['xs', 'sm'].includes(y.mqAlias)) !== -1));
  constructor(private recordService: RecordToolbarService, private media: MediaObserver) {}
  openCreateDialog() {
    return this.recordService.openCreateDialog();
  }
  openUpdateDialog(time: Time, forceUpdate: boolean = false) {
    return this.recordService.openUpdateDialog(time, forceUpdate);
  }
  startRecord() {
    return this.recordService.startRecord();
  }
  stopRecord() {
    return this.recordService.stopRecord();
  }
}
