import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticDatePickerComponent } from './static-dtpicker.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@app/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomDirectivesModule } from '@app/_directives/custom-directives.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [StaticDatePickerComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    CustomDirectivesModule,
    MatDatepickerModule,
    MatButtonModule,
    TranslateModule
  ],
  exports: [StaticDatePickerComponent]
})
export class StaticDatePickerModule {}
