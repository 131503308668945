import { zonedTimeToUtc } from 'date-fns-tz/fp';
import {
  addDays,
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subDays,
  subMonths,
} from 'date-fns/esm/fp';
import { flow } from 'lodash-es';
export const CUSTOM_RANGE_TYPE_ID = 'time-range.preset.custom';
export interface TRange {
  name?: string;
  indexName?: string;
  rangeType: 'day' | 'week' | 'month' | 'quarter' | 'year';
  from: Date;
  to: Date;
  afterParse?(range: TRange): TRange;
  custom?: { add: (date: Date) => Date; sub: (date: Date) => Date };
}
export interface TRangePresets {
  [key: string]: TRange;
}
const TIME_RANGES = {
  year: <TRange>{
    indexName: '_0year',
    rangeType: 'year',
    name: 'time-range.preset.this-year',
    from: flow(startOfYear)(new Date()),
    to: flow(endOfYear)(new Date()),
    afterParse: (d: TRange) => {
      d.to = flow(endOfYear)(d.to);
      return d;
    },
  },
  quarter: <TRange>{
    indexName: '_1quarter',
    rangeType: 'quarter',
    name: 'time-range.preset.this-quarter',
    from: flow(startOfQuarter)(new Date()),
    to: flow(endOfQuarter)(new Date()),
    afterParse: (d: TRange) => {
      d.to = flow(endOfQuarter)(d.to);
      return d;
    },
  },
  month: <TRange>{
    indexName: '_2l30d',
    rangeType: 'month',
    name: 'time-range.preset.last-30-days',
    from: flow(subDays(29), startOfDay)(new Date()),
    to: flow(endOfDay)(new Date()),
    custom: {
      add: (date) => flow(addDays(30))(date),
      sub: (date) => flow(subDays(30))(date),
    },
  },
  tmonth: <TRange>{
    indexName: '_3tmonth',
    rangeType: 'month',
    name: 'time-range.preset.this-month',
    from: flow(startOfMonth)(new Date()),
    to: flow(endOfMonth)(new Date()),
    afterParse: (d: TRange) => {
      d.to = flow(endOfMonth)(d.to);
      return d;
    },
  },
  lmonth: <TRange>{
    indexName: '_4lmonth',
    rangeType: 'month',
    name: 'time-range.preset.last-month',
    from: flow(subMonths(1), startOfMonth)(new Date()),
    to: flow(subMonths(1), endOfMonth)(new Date()),
    afterParse: (d: TRange) => {
      d.to = flow(endOfMonth)(d.to);
      return d;
    },
  },
  week: <TRange>{
    indexName: '_5week',
    rangeType: 'week',
    name: 'time-range.preset.this-week',
    from: flow(startOfISOWeek)(new Date()),
    to: flow(endOfISOWeek)(new Date()),
  },
  day: <TRange>{
    indexName: '_6day',
    rangeType: 'day',
    name: 'time-range.preset.day',
    from: flow(startOfDay)(new Date()),
    to: flow(endOfDay)(new Date()),
  },
};
export const TIME_RANGE_CUSTOM_NAME = 'time-range.preset.custom';
export const TIME_RANGE_DEFAULT_RANGES: TRangePresets & typeof TIME_RANGES = TIME_RANGES;
