<mat-dialog-content>
  <div fxLayout="column" class="searchWrapper flex-shrink-0 mt-2" fxLayoutAlign="stretch stretch">
    <div class="searchInput px-2" fxLayout="column" fxLayoutAlign="center start">
      <mat-form-field appearance="fill" class="w-full mat-form-field-compact mat-form-field-no-hint">
        <input type="text" matInput [formControl]="searchinput" cdkFocusInitial tabindex="0" ignoreAutocomplete />
        <mat-icon inline matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div fxLayout="column" class="searchContainer pos-relative">
      <app-loader [isActive]="this.isLoading$ | async" [enableCircle]="true" [width]="20"></app-loader>
      <mat-list dense role="listbox">
        <ng-container
          *ngFor="let entry of this.data$filtered | async; let last = last; let first = first; trackBy: trackFiltered"
        >
          <mat-list-item
            (keyup.enter)="this.selectItem(entry)"
            (click)="this.selectItem(entry)"
            class="cursor-pointer truncate"
            matRipple
          >
            <div class="flex items-center space-x-2 truncate">
              <div [ngClass]="{ radio: true, active: entry.selected }"></div>
              <span class="truncate"> {{ entry.name }} </span>
            </div>
          </mat-list-item>
          <mat-divider *ngIf="!last"></mat-divider>
        </ng-container>
      </mat-list>
    </div>
  </div>
  <div fxFlex></div>
  <div fxLayout="column" fxLayoutAlign="end">
    <div class="flex flex-col">
      <button
        class="mat-button-filled entry-create-button"
        mat-flat-button
        color="primary"
        (click)="this.createDialog()"
        tabindex="0"
        [disabled]="!(workspace$createPermission | async)"
      >
        {{ 'client.create' | translate }}
      </button>
    </div>
  </div>
</mat-dialog-content>
