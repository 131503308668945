<div fxFlex>
  <mat-sidenav-container fxFill>
    <mat-sidenav
      #sidenav
      [mode]="(this.isMobile$ | async) ? 'over' : 'side'"
      [opened]="!(this.isMobile$ | async)"
      [disableClose]="!(this.isMobile$ | async)"
      ngClass.gt-sm="has-border"
      class="shell-main"
    >
      <div class="shell-wrapper" fxLayout="column" *ngIf="this.workspace$ | async as ws">
        <mat-toolbar class="header-wrapper mat-toolbar-tg" *ngIf="!this.isTeams">
          <button
            mat-icon-button
            matRipple
            [matRippleColor]="'rgba(255,255,255,.02)'"
            class="mat-icon-button-alt mat-icon-fluent"
            [satPopoverAnchor]="officeMenu"
            (click)="officeMenu.toggle()"
          >
            <ms-icon icon="WaffleOffice365"></ms-icon>
          </button>
          <div
            class="header-image cursor-pointer"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="8px"
            [routerLink]="'/'"
          >
            <div class="h-10">
              <mat-icon class="header-logo" svgIcon="logo" inline></mat-icon>
            </div>
          </div>
        </mat-toolbar>
        <div fxLayout="column" fxFlex="1 0" class="shell-inner">
          <nav>
            <!-- Timer -->
            <mat-list fxLayout="column" fxLayoutGap="8px" class="my-3">
              <a
                mat-list-item
                matRipple
                fxLayout="row"
                fxFlex="1 0"
                class="mat-primary shell-list-item route-icon-timer"
                routerLinkActive="active"
                routerLink="/timer"
              >
                <div class="route-item">
                  <mat-icon svgIcon="ftimer"></mat-icon>
                  <ng-container *ngIf="{ entity: this.runningTime$ | async } as time">
                    <ng-container
                      *ngTemplateOutlet="
                        time.entity && time.entity.start && !time.entity.end ? showTimer : showTimerText;
                        context: { $implicit: { time: time.entity } }
                      "
                    >
                    </ng-container>
                  </ng-container>
                </div>
              </a>
              <!-- Feed -->
              <a
                mat-list-item
                matRipple
                fxLayout="row"
                fxFlex="1 0"
                class="mat-primary shell-list-item route-icon-calendar"
                routerLinkActive="active"
                routerLink="/feed"
              >
                <div class="route-item">
                  <mat-icon svgIcon="fcalendar"></mat-icon>
                  <span class="ml-2" translate>feed.name</span>
                </div>
              </a>
            </mat-list>
            <div class="text-sm font-bold mat-subheader select-none" translate>insights.name</div>
            <mat-list fxLayout="column" fxLayoutGap="8px" class="mb-3">
              <!-- Dashboard -->
              <a
                mat-list-item
                matRipple
                fxLayout="row"
                fxFlex="1 0"
                class="mat-primary shell-list-item route-icon-dashboard"
                routerLinkActive="active"
                routerLink="/dashboard"
              >
                <div class="route-item">
                  <mat-icon svgIcon="fdashboard"></mat-icon>
                  <span class="ml-2" translate>dashboard.name</span>
                </div>
              </a>
              <!-- Team-Activity -->
              <a
                mat-list-item
                matRipple
                fxLayout="row"
                fxFlex="1 0"
                class="mat-primary shell-list-item route-icon-tactivity"
                routerLinkActive="active"
                routerLink="/team-activity"
                *ngIf="ws.permissions.groupsCanSeeTeamActivity"
              >
                <div class="route-item">
                  <mat-icon svgIcon="factivity"></mat-icon>
                  <span class="ml-2" translate>team-activity.name</span>
                </div>
              </a>
              <!-- Insights -->
              <a
                mat-list-item
                matRipple
                fxLayout="row"
                fxFlex="1 0"
                class="mat-primary shell-list-item route-icon-insights"
                routerLinkActive="active"
                routerLink="/profitability"
                *ngIf="ws.permissions.groupsCanSeeBillableRates"
              >
                <div class="route-item">
                  <mat-icon svgIcon="finsights"></mat-icon>
                  <span class="ml-2" translate>insights.profitability</span>
                </div>
              </a>
              <a
                mat-list-item
                matRipple
                fxLayout="row"
                fxFlex="1 0"
                class="mat-primary shell-list-item route-icon-tactivity"
                routerLinkActive="active"
                routerLink="/testing"
                *ngIf="!production"
              >
                <div class="route-item">
                  <span>Testing Page</span>
                </div>
              </a>
            </mat-list>
            <div class="text-sm font-bold mat-subheader select-none" translate>workspace.single</div>
            <mat-list fxLayout="column" fxLayoutGap="8px">
              <a
                mat-list-item
                matRipple
                fxLayout="row"
                fxFlex="1 0"
                class="mat-primary shell-list-item"
                routerLinkActive="active"
                routerLink="/settings/workspace/team"
                *ngIf="this.workspace$admin | async"
              >
                <div class="route-item">
                  <mat-icon mat-inline>group</mat-icon>
                  <span class="ml-2">{{ 'team._' | translate }}</span>
                </div>
              </a>
              <a
                mat-list-item
                matRipple
                fxLayout="row"
                fxFlex="1 0"
                class="mat-primary shell-list-item"
                routerLinkActive="active"
                routerLink="/settings/projects"
              >
                <div class="route-item">
                  <mat-icon>folder</mat-icon>
                  <span class="ml-2" translate>project.plural</span>
                </div>
              </a>
              <a
                mat-list-item
                matRipple
                fxLayout="row"
                fxFlex="1 0"
                class="mat-primary shell-list-item"
                routerLinkActive="active"
                routerLink="/settings/clients"
              >
                <div class="route-item">
                  <mat-icon>account_tree</mat-icon>
                  <span class="ml-2" translate>client.plural</span>
                </div>
              </a>
              <a
                mat-list-item
                matRipple
                fxLayout="row"
                fxFlex="1 0"
                class="mat-primary shell-list-item"
                routerLinkActive="active"
                routerLink="/settings/tags"
                [teamsHideMobile]="true"
              >
                <div class="route-item">
                  <mat-icon>label</mat-icon>
                  <span class="ml-2" translate>tag.plural</span>
                </div>
              </a>
              <ng-container *ngIf="this.workspace$admin | async">
                <a
                  routerLink="/settings/workspace/"
                  [routerLinkActiveOptions]="{ exact: true }"
                  routerLinkActive="active"
                  mat-list-item
                  matRipple
                  fxLayout="row"
                  fxFlex="1 0"
                  class="mat-primary shell-list-item"
                  [teamsHideMobile]="true"
                >
                  <div class="route-item">
                    <mat-icon mat-inline>settings</mat-icon>
                    <div class="ml-2" fxLayout="column" translate>
                      <span>{{ 'workspace.settings' | translate }}</span>
                    </div>
                  </div>
                </a>
              </ng-container>
              <ng-container *ngIf="workspaceStatus$ | async as status">
                <a
                  mat-list-item
                  matRipple
                  fxLayout="row"
                  fxFlex="1 0"
                  class="mat-primary shell-list-item"
                  routerLinkActive="active"
                  routerLink="/settings/workspace/subscriptions"
                  *ngIf="status.subscription && status.isOwner && !status.isTrial && !status.isChargebee"
                  teams-hide
                  [teamsHideMobile]="true"
                >
                  <div class="route-item">
                    <mat-icon>card_membership</mat-icon>
                    <span class="ml-2">{{ 'subscription.single' | translate }}</span>
                  </div>
                </a> </ng-container
              ><a
                mat-list-item
                matRipple
                fxLayout="row"
                fxFlex="1 0"
                class="mat-primary shell-list-item route-icon-flow"
                routerLinkActive="active"
                routerLink="/automation"
              >
                <div class="route-item">
                  <mat-icon>auto_fix_high</mat-icon>
                  <span class="ml-2" translate>automation.name</span>
                </div>
              </a>
            </mat-list>
          </nav>
          <div fxFlex></div>
          <mat-list class="mb-3 mt-1.5" fxLayout="column" fxLayoutGap="8px">
            <a
              *ngIf="user$ | async as user"
              mat-list-item
              matRipple
              fxLayout="row"
              fxFlex="1 0"
              class="mat-primary shell-list-item"
              [href]="
                ('livedemo-url' | translate) +
                  '?email=' +
                  user.email +
                  '&name=' +
                  (user.officeProfile?.displayName ? user.officeProfile?.displayName : user.email) | safeUrl
              "
              target="_blank"
              rel="external noopener nofollow"
            >
              <div class="route-item">
                <mat-icon>people_alt</mat-icon>
                <span class="ml-2" translate>livedemo</span>
              </div>
            </a>
          </mat-list>
          <div fxLayout="column" class="mat-bg-3 mx-2 rounded">
            <div
              gdArea
              gdGap="12px"
              gdColumns="1fr 1fr 1fr 1fr"
              gdAlignColumns="center stretch"
              gdAlignRows="center center"
              class="m-2"
            >
              <a
                mat-icon-button
                class="mat-icon-button-flex"
                matTooltip="Teams App"
                [href]="meta.teams"
                target="_blank"
                teams-hide
              >
                <mat-icon svgIcon="ms_teams"></mat-icon>
              </a>
              <a
                mat-icon-button
                class="mat-icon-button-flex"
                matTooltip="Youtube"
                [href]="meta.youtube"
                target="_blank"
              >
                <mat-icon svgIcon="youtube"></mat-icon>
              </a>
              <a mat-icon-button class="mat-icon-button-flex" matTooltip="API" [href]="meta.apiDocs" target="_blank">
                <mat-icon>settings_ethernet</mat-icon>
              </a>
              <button
                mat-icon-button
                class="mat-icon-button-flex"
                (click)="openWidget()"
                matTooltip="Support"
                [disabled]="widgetLoading"
              >
                <mat-icon *ngIf="!widgetLoading; else widgetLoadingRef">live_help</mat-icon>
              </button>
            </div>
          </div>
          <div class="pb-2"></div>
          <div class="flex flex-col">
            <div fxLayout="column" fxLayoutGap="8px">
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="8px"
                class="cursor-pointer px-4 py-3 line-height-base mat-bg-2-hover select-none rounded mx-2 mb-2"
                (click)="openWorkspaceDialog()"
              >
                <mat-icon class="-ml-2 text-lg mat-bg-5 p-2 rounded-circle" inline>work</mat-icon>
                <div fxFlex class="flex flex-col truncate">
                  <span class="text-sm" translate>workspace.single</span>
                  <div class="text-sm font-bold truncate">{{ ws.name }}</div>
                </div>
                <mat-icon class="-mr-1 text-lg mat-bg-5 !rounded-xl !leading-normal" inline>unfold_more</mat-icon>
              </div>
            </div>
            <ng-container *ngIf="this.workspaceStatus$ | async as status">
              <ng-container *ngIf="showUser$ | async">
                <div class="flex items-center space-x-2 pr-2">
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex
                    class="px-4 py-2 cursor-pointer mat-bg-2-hover select-none mx-2 mb-2 rounded"
                    fxLayoutGap="10px"
                    (click)="userMenuRef.menuTrigger.toggleMenu()"
                  >
                    <app-user-menu
                      #userMenuRef
                      class="-ml-2"
                      [disableMenu]="true"
                      [closeOnNavigation]="true"
                      size="30px"
                      (click)="$event.stopPropagation(); $event.preventDefault()"
                    ></app-user-menu>
                    <span class="truncate mat-typography -mr-2 text-sm">{{
                      this.currentUser.officeProfile.displayName
                    }}</span>
                  </div>
                  <tg-frill-button></tg-frill-button>
                </div>
              </ng-container>
              <div fxLayout="column" fxLayoutGap="8px">
                <ng-container *ngIf="status.isTrial; else nonTrialExpired">
                  <div
                    class="flex flex-col justify-center"
                    [class.cursor-pointer]="!status.isTeamsMobile"
                    [send-tagmanager_event]="{ category: 'purchase_c2a', action: 'upgrade button sidebar' }"
                    (click)="!status.isTeamsMobile && this.openWorkspacePlan()"
                    *ngIf="!status.isTeamsMobile"
                  >
                    <ng-container *ngIf="!status.isExpired; else nonTrialExpired">
                      <div class="text-sm alert alert-primary" *ngIf="status.endDate as end">
                        Trial, {{ end | dfnsFormatDistanceToNow }}
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-template #nonTrialExpired>
                  <tg-subscription-status-row [navigateOnClick]="true"></tg-subscription-status-row>
                </ng-template>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content #container>
      <div id="wrapper" class="router-wrapper">
        <router-outlet #o="outlet"></router-outlet>
        <app-loader #routerLoader [enableCircle]="true" [isActive]="this.isLoading !== false"></app-loader>
        <tg-record-widget></tg-record-widget>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<sat-popover #officeMenu hasBackdrop class="popover-menu-top-left-offset">
  <mat-card class="ms-office-menu ms-office-menu-offset">
    <div fxLayout="column" fxLayoutGap="8px">
      <div class="popover-card-header">
        <div fxLayout="row" class="p-2" fxLayoutAlign="start center" fxLayoutGap="8px">
          <button mat-icon-button class="mat-icon-button-alt mat-icon-fluent" (click)="officeMenu.close()">
            <ms-icon icon="WaffleOffice365" class="mat-typography"></ms-icon>
          </button>
          <div class="!ml-auto mr-1" fxLayout="row" fxLayoutAlign="start center">
            <a href="https://www.office.com" target="_blank" referrerpolicy="origin" mat-link>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                <small>Microsoft 365</small>
                <mat-icon inline>arrow_forward</mat-icon>
              </div>
            </a>
          </div>
        </div>
      </div>
      <h4 class="mt-0">Apps</h4>
      <div fxLayout="column" fxLayoutGap="4px" class="ms-office-menu-list p-1">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <button
            (click)="openLink('https://www.office.com/launch/word')"
            mat-button
            class="ms-office-menu-item text-left"
          >
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
              <mat-icon svgIcon="ms_word" class="ms-Icon"></mat-icon>
              <span>Word</span>
            </div>
          </button>
          <button
            (click)="openLink('https://www.office.com/launch/powerpoint')"
            mat-button
            class="ms-office-menu-item text-left"
          >
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
              <mat-icon svgIcon="ms_powerpoint" class="ms-Icon"></mat-icon>
              <span>Power Point</span>
            </div>
          </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <button
            (click)="openLink('https://outlook.office.com/owa/?modurl=0')"
            mat-button
            class="ms-office-menu-item text-left"
          >
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
              <mat-icon svgIcon="ms_outlook" class="ms-Icon"></mat-icon>
              <span>Outlook</span>
            </div>
          </button>
          <button (click)="openLink('https://aka.ms/mstfw')" mat-button class="ms-office-menu-item text-left">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
              <mat-icon svgIcon="ms_teams" class="ms-Icon"></mat-icon>
              <span>Teams</span>
            </div>
          </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <button
            (click)="openLink('https://www.microsoft.com/microsoft-365/onedrive/onedrive-for-business')"
            mat-button
            class="ms-office-menu-item text-left"
          >
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
              <mat-icon svgIcon="ms_onedrive" class="ms-Icon"></mat-icon>
              <span>OneDrive</span>
            </div>
          </button>
          <button (click)="openLink('https://to-do.office.com/tasks')" mat-button class="ms-office-menu-item text-left">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
              <mat-icon svgIcon="ms_todo" class="ms-Icon"></mat-icon>
              <span>To Do</span>
            </div>
          </button>
        </div>
        <div class="ms-office-menu-footer flex flex-row items-center justify-center">
          <a href="https://timeghost.io" mat-link class="mat-hint py-1" target="_blank">
            <small>timeghost.io</small>
          </a>
        </div>
      </div>
    </div>
  </mat-card>
</sat-popover>
<ng-template #showTimer let-item>
  <span class="ml-2">{{
    item.time.start | dfnsParseIso | dfnsDifferenceInSeconds: now() | parseMsAsDuration: true
  }}</span>
</ng-template>
<ng-template #showTimerText>
  <span class="ml-2" translate>timer.single</span>
</ng-template>
<ng-template #widgetLoadingRef>
  <mat-progress-spinner [strokeWidth]="2" [diameter]="18" mode="indeterminate"> </mat-progress-spinner>
</ng-template>
