<mat-dialog-content>
  <div fxLayout="column" class="searchWrapper flex-shrink-0 mt-2" fxLayoutAlign="stretch stretch" [formGroup]="group">
    <div class="searchInput px-2" fxLayout="column" fxLayoutAlign="center start">
      <mat-form-field appearance="fill" class="w-full mat-form-field-compact mat-form-field-no-hint">
        <input type="text" matInput formControlName="search" cdkFocusInitial tabindex="0" ignoreAutocomplete />
        <mat-icon inline matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <cdk-virtual-scroll-viewport itemSize="40" [minBufferPx]="300" [maxBufferPx]="800" [style.height.px]="380">
      <mat-list dense role="listbox" class="pb-8">
        <ng-container *cdkVirtualFor="let entry of data$filtered | async; trackBy: trackId">
          <ng-container *ngTemplateOutlet="projectItem; context: { $implicit: entry }"></ng-container>
        </ng-container>
      </mat-list>
    </cdk-virtual-scroll-viewport>
  </div>
</mat-dialog-content>
<ng-template #clientItem let-entry>
  <h3 matSubheader>
    <span class="--client-title">{{
      entry.id === 'internal' ? (entry.name | translate) : (entry | defaultEntityTranslate: 'client':'client.none')
    }}</span>
  </h3>
</ng-template>
<ng-template #projectItem let-project>
  <mat-list-item matRippleDisabled class="cursor-pointer truncate h-10" tabindex="0" (click)="setProject(project)">
    <div class="flex items-center space-x-2 truncate">
      <div [ngClass]="{ radio: true, active: project.selected }"></div>
      <span class="truncate leading-none"> {{ project | defaultEntityTranslate: 'project':'project.none' }} </span>
    </div>
  </mat-list-item>
</ng-template>
