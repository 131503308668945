import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomPipesModule } from '@app/_pipes/custom-pipes.module';

@NgModule({
  declarations: [LoaderComponent],
  imports: [CommonModule, MatProgressSpinnerModule, MatProgressBarModule, CustomPipesModule],
  exports: [LoaderComponent],
  providers: [],
})
export class LoaderModule {}
