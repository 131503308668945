import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeTrackerEntryComponent } from './time-tracker-entry.component';
import { MaterialModule } from '@app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ClientprojectPickerModule } from '../clientproject-picker/clientproject-picker.module';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { LoaderModule } from '../loader/loader.module';
import { TranslateModule } from '@ngx-translate/core';
import { StaticDatePickerModule } from '../static-datepicker/static-dtpicker.module';
import { CustomDirectivesModule } from '@app/_directives/custom-directives.module';
import { NgxMaskModule } from 'ngx-mask';
import { ClientProjectPickerDialogModule } from '../dialogs/client-project-picker-dialog/client-project-picker-dialog.module';
import { TagPickerDialogModule } from '../dialogs/tag-picker-dialog/tag-picker-dialog.module';
import { TimeInputModule } from '../time-input/time-input.module';
import { DateFnsModule } from 'ngx-date-fns';
import { TimeDatePickerModule } from '../time-date-picker/time-date-picker.module';
import { EntityRefPipesModule } from '@app/_pipes/entity-ref-pipes/entity-ref-pipes.module';
import { DurationInputControlModule } from '@app/components/duration-input-control/duration-input-control.module';

@NgModule({
  declarations: [TimeTrackerEntryComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    ClientprojectPickerModule,
    SatPopoverModule,
    LoaderModule,
    NgxMaskModule,
    TranslateModule,
    TimeDatePickerModule,
    CustomDirectivesModule,
    TimeInputModule,
    DurationInputControlModule,
    ClientProjectPickerDialogModule,
    DateFnsModule,
    EntityRefPipesModule,
    TagPickerDialogModule,
  ],
  exports: [TimeTrackerEntryComponent],
})
export class TimeTrackerEntryModule {}
