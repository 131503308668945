import { EventEmitter } from '@angular/core';
import { TRange } from '@app/shared/time-range-picker/time-range-constants';
import {
  DistinctUntilChangedBy,
  DistinctUntilCosmosChange,
  DistinctUntilDiff,
} from '@app/_validators/custom-validators';
import {
  addDays,
  addMonths,
  addWeeks,
  differenceInDays,
  differenceInWeeks,
  intervalToDuration,
  isBefore,
  isSameDay,
  isSameISOWeek,
  isSameMonth,
  isSameYear,
  startOfDay,
  subDays,
} from 'date-fns/esm';
import { clone, cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable, OperatorFunction } from 'rxjs';
import { distinctUntilChanged, first, share, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { GroupDefaults, Project, Task, UserSettings, ProjectPermissionRole, Logger, Workspace } from 'timeghost-api';
export class NotImplementedException extends Error {
  constructor() {
    super('Not implemented');
  }
}
export type DateRangeType = 'day' | 'week' | 'month';
export function isNullOrUndefined(obj: any) {
  return obj === undefined || obj === null;
}
/**
 *
 * @param from where to start from
 * @param to where to end
 * @param dynamicRange allow dynamically setting the range type (weekly, daily or monthly)
 * @returns [Array<[Date, T]>, DateRangeType] - [range in a array, type of range (month, week, day)]
 */
export function createDateRangeArray<T = any>(
  from: Date,
  to: Date,
  dynamicRange: boolean = false
): [Array<[Date, T]>, DateRangeType] {
  let _data: Array<[Date, T]> = [];
  let _type: DateRangeType = 'day';
  if (dynamicRange && differenceInDays(to, from) >= 28) {
    if (differenceInWeeks(to, from) > 4) {
      _type = 'month';
      for (let d = startOfDay(from); isBefore(d, to); d = addMonths(d, 1)) {
        _data.push([new Date(d.getTime()), null]);
      }
    } else {
      _type = 'week';
      for (let d = startOfDay(from); isBefore(d, to); d = addWeeks(d, 1)) {
        _data.push([new Date(d.getTime()), null]);
      }
    }
  } else {
    for (let d = startOfDay(from); isBefore(d, to); d = addDays(d, 1)) {
      _data.push([new Date(d.getTime()), null]);
    }
  }
  return [_data, _type];
}
export function seriesTypeDateCheck(
  seriesType: 'day' | 'week' | 'month' | 'year',
  dateLeft: string | number | Date,
  dateRight: string | number | Date
) {
  return !!(
    seriesType === 'day'
      ? isSameDay
      : seriesType === 'week'
      ? isSameISOWeek
      : seriesType === 'month'
      ? isSameMonth
      : seriesType === 'year'
      ? isSameYear
      : null
  )?.(new Date(dateLeft), new Date(dateRight));
}
export const DEFAULT_PERMISSION_GROUPS = {
  Everyone: GroupDefaults.everyoneGroupId,
  Admin: GroupDefaults.adminGroupId,
};
export const hasPermission = (permissionId: string, userSettings: UserSettings, project?: { id: string }) => {
  if (DEFAULT_PERMISSION_GROUPS.Everyone === permissionId) return true;
  const isAdmin = !!userSettings.workspace.users.find((x) => x.admin && x.id === userSettings.id);
  if (isAdmin) return true;
  const user = userSettings.workspace.groups
    ?.find?.((x) => x.id === permissionId)
    ?.users?.find?.((x) => userSettings.id === x.id);
  if (user) return true;
  if (project) {
    const projectPerm = userSettings.workspace.projectPermissions.find((x) => x.projectId === project.id);
    return (
      !projectPerm.private ||
      !!projectPerm.users.find((x) => x.id === user.id) ||
      !!projectPerm.groups.find((x) =>
        userSettings.workspace.groups.find((g) => g.id === x.id && g.users.find((u) => u.id === user.id))
      )
    );
  }
  return false;
};
export function hasPerm(permKey: keyof Workspace['permissionSettings'], user: UserSettings) {
  throw new NotImplementedException();
  const perm = user.workspace.permissionSettings[permKey];
  if (!perm) return false;
  return false;
}
export const hasPermissionTaskView = (
  task: Task,
  project: Project,
  user: UserSettings,
  _options?: Partial<{ public: boolean }>
) => {
  const options = _options ?? { public: false };
  if (!project) return false;
  if (!options.public && !project?.private) return true;
  if (!!user.workspace.users.find((x) => x.id === user.id && x.admin)) return true;
  if (
    project.users?.find((x) => x.id === user.id && x.role === ProjectPermissionRole.manager) ||
    project.groups?.find(
      (x) =>
        x.role === ProjectPermissionRole.manager &&
        user.workspace.groups?.find((g) => g.users.find((gu) => gu.id === user.id))
    )
  )
    return true;
  if (!task.assignedToGroups?.length && !task.assignedToUsers?.length) return true;
  if (task.assignedToUsers.findIndex((x) => x.id === user.id) !== -1) return true;
  const groups = task.assignedToGroups!.map((x) => user.workspace.groups?.find((g) => g.id === x.id)).filter(Boolean);
  if (groups?.find((x) => x.users.findIndex((u) => u.id === user.id) !== -1)) return true;
  return false;
};
export const hasPermissionTaskChange = (task: Task, project: Project, user: UserSettings) => {
  if (
    !!user.workspace.users?.find((x) => x.id === user.id && x.admin) ||
    (project &&
      (!!project.users?.find((x) => x.id === user.id && x.role === ProjectPermissionRole.manager) ||
        !!project.groups?.find(
          (x) =>
            x.role === ProjectPermissionRole.manager &&
            user.workspace.groups?.find((g) => g.users.find((u) => u.id === user.id))
        )))
  )
    return true;
  return false;
};
export interface RXValue<T> {
  value: T;
  value$: Observable<T>;
  asObservable: (share?: boolean) => Observable<T>;
  asEventEmitter: () => EventEmitter<T>;
  update: (fn: T | ((state: T) => void) | ((state: T) => T), clone?: boolean) => void;
  next: (value: T) => void;
}
/**
 *
 * @param defaultValue default value
 * @param options options, defaults: distinct=true
 * @returns rx class instance
 */
export function createRxValue<T>(
  defaultValue?: T,
  options?: Partial<{
    log: Logger | string;
    startWithValue: T;
    addOperators: OperatorFunction<T, T>;
    distinct: boolean;
  }>
): RXValue<T> {
  const { addOperators, startWithValue } = options ?? {};
  const log = options?.log
    ? typeof options?.log === 'string'
      ? new Logger(`[${options.log}@RX]`)
      : options.log instanceof Logger
      ? options.log
      : undefined
    : undefined;
  return new (class {
    private _valueEmitter: EventEmitter<T>;
    private _value = new BehaviorSubject<T>(defaultValue);
    public readonly value$: Observable<T> = this._value
      .asObservable()
      .pipe((source) =>
        (startWithValue ? source.pipe(startWith(startWithValue)) : source).pipe((dsrc) =>
          options?.distinct !== false ? dsrc.pipe(distinctUntilChangedJson()) : dsrc
        )
      )
      .pipe(
        tap((val) => {
          this._valueEmitter?.emit?.(val);
          log?.debug('valueUpdate', val);
        })
      )
      .pipe((source) => addOperators?.(source) ?? source);
    public get value() {
      return this._value.getValue();
    }
    public update(fn: T | ((state: T) => T), isCloned?: boolean) {
      const isFunc = typeof fn === 'function';
      const newValue = isFunc ? (fn as Function)(!isCloned ? this.value : clone(this.value)) : fn;
      if (isFunc && newValue === undefined) {
        this._value.next(this.value);
        return;
      }
      this._value.next(newValue);
    }
    public next(value: T) {
      this._value.next(value);
    }
    public set value(val: T) {
      this._value.next(val);
    }
    public asObservable(share?: boolean) {
      if (share) return this.value$.pipe(shareReplay());
      return this.value$;
    }
    public asEventEmitter() {
      if (!this._valueEmitter) this._valueEmitter = new EventEmitter<T>(true);
      return this._valueEmitter;
    }
  })();
}
export interface RXCollection<T> {
  value$: Observable<T[]>;
  asObservable: (share?: boolean) => Observable<T[]>;
  add: (v: T[], options?: StoreOptions) => this;
  remove: (v: T[], options?: StoreOptions) => this;
  contains: (v: T) => boolean;
  set: (v: T[], options?: StoreOptions) => this;
  value: T[];
  __type: T[];
}
type StoreOptions = {
  emit?: boolean;
};
export function createRxCollection<T>(uniqueOrMapByKey?: (t: T) => any, defaultValue?: T[]): RXCollection<T> {
  let uniqueFn = uniqueOrMapByKey;
  let _store = createRxValue<T[]>(defaultValue ?? []);
  return new (class {
    readonly __type: T[];
    public readonly value$: Observable<T[]> = _store.asObservable().pipe(startWith(this.store), distinctUntilChanged());
    public asObservable(share?: boolean) {
      if (share) return this.value$.pipe(shareReplay());
      return this.value$;
    }
    private get store() {
      return _store.value;
    }
    private set store(val: T[]) {
      _store.value = val;
    }
    public add(v: T[], options?: StoreOptions) {
      if (uniqueFn) {
        const ids = v.map((x) => uniqueFn(x));
        if (!this.store.find((x) => ids.includes(uniqueFn(x)))) this.store = [...this.store, ...v];
      } else this.store = [...this.store, ...v];
      return this;
    }
    public contains(v: T) {
      if (uniqueFn) return this.store.findIndex((x) => uniqueFn(v) === uniqueFn(v)) !== -1;
      return this.store.findIndex((x) => x === v) !== -1;
    }
    public set(v: T[], options?: StoreOptions) {
      this.store = v;
      return this;
    }
    public remove(v: T[], options?: StoreOptions) {
      const toRemove = v.map((x) => uniqueFn(x));
      this.store = this.store.filter((x) => !toRemove.includes(uniqueFn(x)));
      return this;
    }
    get value() {
      return [...this.store];
    }
  })();
}
export function fromRxValue<T>(source: Observable<T>, defaultValue?: T, startWithValue?: T) {
  const rx = createRxValue<T>(defaultValue, { startWithValue });
  source.subscribe((x) => (rx.value = x));
  return rx;
}
export function createRange(from: Date, to: Date): TRange {
  const days = ((x) => (x < 0 ? x * -1 : x))(differenceInDays(to, from));
  const type = days < 27 ? (days < 7 ? (days < 1 ? 'day' : 'week') : 'month') : 'month';
  return {
    name: 'time-range.preset.custom',
    from,
    to,
    rangeType: type,
  };
}
export function DistinctEqual(original: any, ...args: any[]) {
  const _original = JSON.stringify(original);
  return args.every((x) => JSON.stringify(x) === _original);
}
function DistinctBy<T>(mapper?: (a: T) => any) {
  return (source: Observable<T>) =>
    source.pipe(
      distinctUntilChanged((a, b) => {
        return mapper(a) === mapper(b);
      })
    );
}
export function DistinctJSON<T>(mapper?: (a: T) => any) {
  return (source: Observable<T>) =>
    source.pipe(
      distinctUntilChanged((a, b) => {
        return !hasChange(a, b, mapper);
      })
    );
}
export function hasChange<T>(a: T, b: T, mapper?: (a: T) => any) {
  if (mapper) return JSON.stringify(mapper(a)) !== JSON.stringify(mapper(b));
  return JSON.stringify(a) !== JSON.stringify(b);
}
export function DistinctComsmos<T>() {
  return (source: Observable<T>) => source.pipe(distinctUntilChanged<T>(DistinctUntilCosmosChange));
}
export function waitFor<T>(signal: Observable<any>) {
  return (source: Observable<T>) =>
    signal.pipe(
      first(),
      switchMap((_) => source)
    );
}

export const DistinctUntilCompare = {
  ChangedBy: DistinctUntilChangedBy,
  Diff: DistinctUntilDiff,
  ChangedCosmos: DistinctUntilCosmosChange,
  ChangedJson: DistinctEqual,
};
export const distinctUntilChangedJson = DistinctJSON;
export const distinctUntilBy = DistinctBy;
export const distinctUntilChangedCosmos = DistinctComsmos;
export function factory<T>(type: { new (): T }): T {
  return new type();
}
export type WithOptional<T = any> = T & { [key: string]: any };
export const NaNZeroify = (val: any) => (val === NaN ? 0 : val);
export const roundUpMinute = (date: Date, coff: number = 60000): Date => {
  return new Date(Math.ceil(date.getTime() / coff) * coff);
};
