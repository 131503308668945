<h1 matDialogTitle *ngIf="stepIndex === 0">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div>{{ 'time.pick-project' | translate }}</div>
    <div fxFlex></div>
    <button mat-icon-button matDialogClose tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</h1>
<tg-plain-stepper [(selectedIndex)]="stepIndex" class="overflow-hidden relative h-full">
  <cdk-step>
    <tg-project-list
      class="max-h-inherit"
      (entityChange)="onSelectProject($event)"
      [suggestProjectByName]="createData?.title"
    ></tg-project-list>
  </cdk-step>
  <cdk-step>
    <app-time-tracker-calendar-create-dialog [data]="createData"></app-time-tracker-calendar-create-dialog>
  </cdk-step>
</tg-plain-stepper>
<mat-dialog-actions align="end" class="!mb-0 mx-8" *ngIf="stepIndex === 0">
  <button mat-raised-button color="primary" (click)="onSelectProject()">Next</button>
</mat-dialog-actions>
