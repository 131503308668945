import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSideSheetComponent } from './mat-side-sheet.component';
import { MatCommonModule } from '@angular/material/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import {
  MatDialogContainer,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialog,
  MatDialogConfig,
  MAT_DIALOG_SCROLL_STRATEGY_PROVIDER,
} from '@angular/material/dialog';
import { MatSideSheet, MAT_SHEET_DEFAULT_OPTIONS } from './mat-side-sheet';

@NgModule({
  imports: [OverlayModule, PortalModule, MatCommonModule],
  exports: [MatSideSheetComponent],
  declarations: [MatSideSheetComponent],
  providers: [
    MatSideSheet,
    MAT_DIALOG_SCROLL_STRATEGY_PROVIDER,
    {
      provide: MAT_SHEET_DEFAULT_OPTIONS,
      useValue: <MatDialogConfig>{
        height: '100%',
        maxHeight: '100%',
        panelClass: 'mat-sheet-panel',
        hasBackdrop: true,
        position: {
          left: '0',
        },
      },
    },
  ],
})
export class MatSideSheetModule {}
