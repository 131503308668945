import { Logger } from 'timeghost-api';

export const checkTeamsQuery = () => {
  return !!window.location.search?.match('in_teams=1');
};
export const isTeamsWindow = () => {
  return !!window.teams || !!checkTeamsQuery() || !!JSON.parse(sessionStorage.teams || 'false');
};
const teamsJsLog = new Logger('Teams JS');
export const initTeamsJS = () =>
  new Promise((resolve, reject) => {
    const data = {
      url: 'https://statics.teams.cdn.office.net/sdk/v1.11.0/js/MicrosoftTeams.min.js',
      integrity: 'sha384-SCVF3m7OvDKnfAilUzYn2yozEvBeP8n/Oq0yTH8VUI70J4AzrqR70jzjdQ6DI8s2',
    };
    const script = document.createElement('script');
    script.integrity = data.integrity;
    script.src = data.url;
    script.crossOrigin = 'anonymous';
    script.onload = (ev) => {
      resolve(ev), teamsJsLog.debug('loaded teams js');
    };
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  })
    .then(() => {
      window.teams = true;
      window.microsoftTeams.initialize();
      return window.microsoftTeams;
    })
    .then((teams) => {
      teamsJsLog.debug('instance', teams);
      return teams;
    })
    .then((teams) => {
      return new Promise((resolveTeams) => {
        teams.getContext((context: any) => resolveTeams(context));
      });
    })
    .then((teamsContext: any) => {
      window.teamsMobile = ['android', 'ios'].includes(teamsContext.hostClientType);
      return window.microsoftTeams;
    })
    .catch(teamsJsLog.error);
