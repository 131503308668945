import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionStatusRowComponent } from './subscription-status-row.component';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [SubscriptionStatusRowComponent],
  imports: [CommonModule, FlexLayoutModule, RouterModule, TranslateModule],
  exports: [SubscriptionStatusRowComponent],
})
export class SubscriptionStatusRowModule {}
