<mat-dialog-content>
  <div fxLayout="column" class="searchWrapper" fxLayoutAlign="stretch stretch">
    <div class="searchInput px-2" fxLayout="column" fxLayoutAlign="center start">
      <mat-form-field appearance="fill" class="w-full mat-form-field-compact mat-form-field-no-hint">
        <input
          type="text"
          ignoreAutocomplete
          matInput
          [formControl]="searchinput"
          cdkFocusInitial
          (keypress)="onCreateTag($event)"
          tabindex="0"
        />
        <mat-icon inline matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div fxLayout="column" class="searchContainer">
      <mat-list dense *ngIf="this.data$filtered | async as dataFiltered">
        <ng-container *ngFor="let item of dataFiltered; trackBy: trackFiltered">
          <mat-list-item
            matRipple
            (click)="this.selectItem(item)"
            class="cursor-pointer"
            [class.bg-primary-2]="item.selected"
          >
            <ng-container>
              <mat-checkbox
                [labelPosition]="'after'"
                class="w-full mat-checkbox-flex"
                (click)="$event.preventDefault()"
                color="primary"
                [checked]="item.selected"
              >
                {{ item.name }}
              </mat-checkbox>
            </ng-container>
          </mat-list-item>
        </ng-container>
        <ng-container *ngTemplateOutlet="noItems; context: { data: dataFiltered }"></ng-container>
      </mat-list>
      <ng-template #noItems let-dataFiltered="data">
        <div class="flex flex-col items-center justify-center p-2" *ngIf="dataFiltered.length === 0">
          <div class="mat-hint">{{ 'utils.noitems' | translate }}</div>
        </div>
      </ng-template>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="mat-action-filled" fxLayout="row">
  <button
    mat-flat-button
    color="primary"
    (click)="this.closeDialog()"
    [disabled]="(this.isLoading$ | async) || !(selected$change | async)"
    class="mat-button-filled flex-grow"
  >
    {{ 'utils.apply' | translate }}
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="this.createTagDialog()"
    [disabled]="(this.isLoading$ | async) || !(this.workspace$createPermission | async)"
    [matTooltip]="'workspace.errors.admin-create-tag' | translate"
    [matTooltipDisabled]="this.workspace$createPermission | async"
    class="mat-button-filled !ml-0"
  >
    <mat-icon>add</mat-icon>
  </button>
</mat-dialog-actions>
