import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientProjectPickerDialogComponent } from './client-project-picker-dialog.component';
import { MaterialModule } from '@app/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomDirectivesModule } from '@app/_directives/custom-directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ClientProjectEntryComponent } from './client-project-entry/client-project-entry.component';
import { CreateTaskDialogModule } from '@app/components/create-task-dialog/create-task-dialog.module';

@NgModule({
  declarations: [ClientProjectPickerDialogComponent, ClientProjectEntryComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    CustomDirectivesModule,
    TranslateModule,
    RouterModule,
    ScrollingModule,
    CreateTaskDialogModule,
  ],
  exports: [ClientProjectPickerDialogComponent],
})
export class ClientProjectPickerDialogModule {}
