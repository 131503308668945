import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'tg-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { text: string },
    private ref: MatDialogRef<ConfirmDialogComponent>
  ) {}

  ngOnInit(): void {}
  @HostListener('document:keydown.escape')
  onEscape() {
    this.ref.close(false);
  }
  get message() {
    return this.data.text;
  }
}
