import { FlexLayoutModule } from '@angular/flex-layout';
import { TgFormsModule } from '@app/components/tg-forms/tg-forms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarInsertTimeDialogComponent } from './calendar-insert-time-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from '@app/shared/loader/loader.module';
import { TimeInputModule } from '@app/shared/time-input/time-input.module';

@NgModule({
  declarations: [CalendarInsertTimeDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    LoaderModule,
    FormsModule,
    FlexLayoutModule,
    TgFormsModule,
    ReactiveFormsModule,
    TimeInputModule,
    TranslateModule,
  ],
  exports: [CalendarInsertTimeDialogComponent],
})
export class CalendarInsertTimeDialogModule {}
