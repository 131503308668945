import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientprojectPickerComponent } from './clientproject-picker.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@app/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CreateProjectModule } from '../dialogs/create-project/create-project.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ClientprojectPickerComponent],
  imports: [CommonModule, FlexLayoutModule, MaterialModule, ReactiveFormsModule, CreateProjectModule, TranslateModule],
  exports: [ClientprojectPickerComponent],
  providers: []
})
export class ClientprojectPickerModule {}
