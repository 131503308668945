<div matRipple (click)="this._onChangeEvent($event)">
  <div mat-list-item fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" class="event-filter-item">
    <mat-checkbox
      (click)="$event.preventDefault()"
      color="primary"
      [checked]="this.checked"
      (change)="this._onChangeEvent($event)"
      class="event-filter-item-check"
    ></mat-checkbox>
    <span [innerText]="this.title | translate" class="truncate"></span>
    <img *ngIf="this.image" [src]="this.image | safeUrl" class="event-filter-item-icon" />
  </div>
</div>
