import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnchorForPopup } from './satpopupfor-target.directive';
import { SatPopoverModule, SatPopoverAnchoringService } from '@ncstate/sat-popover';
import { OptionsScrollDirective } from './mat-autocomplete-infinite-scroll.directive';
import { ExtractPipe } from './extract.pipe';
import { ElRefDirective } from './el-ref/el-ref.directive';
import { LazyForDirective } from './lazy-for.directive';
import { TeamsUiConditionDirective } from './teams-ui-condition.directive';
import { TeamsExternaLinkDirective } from './teams-externa-link.directive';
import { MouseOverClassDirective } from './mouse-over-class.directive';
import { LongPressDirective } from './long-press.directive';
import { IsBetaDirective } from './is-beta.directive';
import { SendTagDirective } from './send-tag';
import { MatDividerMqDirective } from './mat-divider-mq.directive';
import { AngularExpansionModule } from '@app/angular-expansion/angular-expansion.module';
import { IgnoreAutocompleteDirective } from './ignore-autocomplete.directive';

@NgModule({
  declarations: [
    AnchorForPopup,
    OptionsScrollDirective,
    ExtractPipe,
    ElRefDirective,
    LazyForDirective,
    TeamsUiConditionDirective,
    TeamsExternaLinkDirective,
    MouseOverClassDirective,
    LongPressDirective,
    SendTagDirective,
    IsBetaDirective,
    MatDividerMqDirective,
    IgnoreAutocompleteDirective,
  ],
  exports: [
    AnchorForPopup,
    OptionsScrollDirective,
    ExtractPipe,
    ElRefDirective,
    LazyForDirective,
    TeamsUiConditionDirective,
    TeamsExternaLinkDirective,
    SendTagDirective,
    MouseOverClassDirective,
    LongPressDirective,
    IsBetaDirective,
    MatDividerMqDirective,
    IgnoreAutocompleteDirective,
  ],
  imports: [CommonModule, AngularExpansionModule, SatPopoverModule],
  providers: [SatPopoverAnchoringService],
})
export class CustomDirectivesModule {}
