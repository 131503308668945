import {
  Component,
  OnInit,
  EventEmitter,
  HostListener,
  Inject,
  ViewChild,
  Input,
  OnDestroy,
  AfterContentInit,
  ViewChildren,
  ElementRef,
  QueryList,
} from '@angular/core';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { CalendarRangePickerOverlayRef } from './calendar-range-picker-ref';
import { CALENDAR_RANGE_DIALOG_DATA } from './calendar-range-picker-ref-tokens';
import { CalendarRangeConfig } from './calendar-range-picker.service';
import { distinctUntilChanged, startWith, map, filter, takeUntil, first } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Subject, Observable } from 'rxjs';
import { Logger } from 'timeghost-api';
import { orderBy } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { TRange, TRangePresets } from '../time-range-constants';
import {
  MatCalendar,
  MatMonthView,
  MatCalendarCellCssClasses,
  DateRange,
  DefaultMatCalendarRangeStrategy,
} from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';
import { differenceInDays, endOfDay, isSameDay, isValid, startOfDay, startOfMonth } from 'date-fns/esm';

const ESCAPE = 27;
const ANIMATION_TIMINGS_IN = '300ms cubic-bezier(0.25, 1.25, 0.25, 1)';
const ANIMATION_TIMINGS_OUT = '300ms cubic-bezier(0.25, 0.85, 0.25, 1)';
const log = new Logger('CalendarRangePickerComponent');

@Component({
  selector: 'app-calendar-range-picker',
  templateUrl: './calendar-range-picker.component.html',
  styleUrls: ['./calendar-range-picker.component.scss'],
  providers: [DefaultMatCalendarRangeStrategy],
  animations: [
    trigger('fade', [
      state('fadeOut', style({ opacity: 0 })),
      state('fadeIn', style({ opacity: 1 })),
      transition('* => fadeIn', animate(ANIMATION_TIMINGS_IN)),
    ]),
    trigger('slideContent', [
      state('void', style({ transform: 'translate3d(0, 0, 0) scale(0.65)', opacity: 0 })),
      state('enter', style({ transform: 'none', opacity: 1 })),
      state('leave', style({ transform: 'translate3d(0, 0, 0) scale(0.65)', opacity: 0 })),
      transition('* => *', animate(ANIMATION_TIMINGS_IN)),
    ]),
  ],
})
export class CalendarRangePickerComponent implements OnInit, OnDestroy, AfterContentInit {
  loading = true;
  animationState: 'void' | 'enter' | 'leave' = 'enter';
  animationStateChanged = new EventEmitter<AnimationEvent>();
  private destroy$ = new Subject<void>();
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  @ViewChild('startCal', { static: true })
  private startCal: MatCalendar<Date>;
  @ViewChild('endCal', { static: true })
  private endCal: MatCalendar<Date>;

  private ranges = new BehaviorSubject<TRangePresets>(null);
  readonly ranges$ = this.ranges.asObservable().pipe(distinctUntilChanged());
  private range = new BehaviorSubject<TRange>(null);
  private get rangeNative() {
    return new DateRange(this.range.value.from, this.range.value.to);
  }
  readonly range$ = this.range.asObservable().pipe(distinctUntilChanged());
  readonly range$SameDay = this.range$.pipe(map((x) => isSameDay(x.from, x.to)));
  readonly range$Date = this.range$.pipe(
    map((x) => {
      return new DateRange(x.from, x.to);
    })
  );
  readonly rangeStart$ = this.range$.pipe(
    filter((x) => !!x?.from && isValid(x.from)),
    map((x) => x.from)
  );
  readonly rangeEnd$ = this.range$.pipe(
    filter((x) => !!x?.to && isValid(x.to)),
    map((x) => x.to)
  );

  private _customRanges = new BehaviorSubject<TRangePresets>(null);
  readonly customRanges$ = this._customRanges.asObservable().pipe(distinctUntilChanged());
  @Input()
  get customRanges() {
    return this._customRanges.getValue();
  }
  set customRanges(val: TRangePresets) {
    this._customRanges.next(val);
  }
  readonly ranges$Custom = combineLatest([this.ranges$.pipe(startWith()), this.customRanges$.pipe(startWith())]).pipe(
    map(([presets, customPresets]) => {
      if (!customPresets) {
        return presets;
      } else if (!presets) {
        return customPresets;
      }
      return { ...presets, ...customPresets };
    }),
    map((x) => {
      return orderBy(x, (y) => y.indexName);
    })
  );

  @HostListener('document:keydown.escape', ['$event'])
  private handleKeydown(event: KeyboardEvent) {
    this.dialogRef.close();
  }
  constructor(
    private translateService: TranslateService,
    public dialogRef: CalendarRangePickerOverlayRef,
    @Inject(CALENDAR_RANGE_DIALOG_DATA)
    private config: CalendarRangeConfig,
    private pickerStrategy: DefaultMatCalendarRangeStrategy<Date>
  ) {
    this.ranges.next(this.config.presets);
  }
  onLoad(event: Event) {
    this.loading = false;
  }

  ngOnInit() {
    this.selectRange(this.config.selected, true);
  }
  ngAfterContentInit() {}
  onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }
  get currentLang() {
    return this.translateService.currentLang;
  }
  startExitAnimation() {
    this.animationState = 'leave';
  }
  selectRange(ev: TRange, triggerUpdateActiveDate: boolean = true) {
    this.range.next(ev);
    if (triggerUpdateActiveDate) {
      try {
        this.updateActiveDate(ev);
      } catch (e) {
        log.error(e);
      }
    }
  }
  updateActiveDate(ev?: TRange) {
    log.debug(ev || this.range.value, this.startCal, this.endCal);
    if (this.startCal) {
      ev = this.range.value;
      this.startCal.startAt = this.startCal.activeDate = startOfMonth(ev.from.getTime());
      this.startCal.stateChanges.next();
      // try {
      //   this.startCal.focusActiveCell();
      //   this.endCal.focusActiveCell();
      // } catch {}
    }
  }
  selectedStart(ev: Date) {
    log.debug(ev);
    this.selectRange({
      ...this.range.value,
      ...{
        from: startOfDay(ev),
        name: 'time-range.preset.custom',
        rangeType: 'month',
      },
    });
  }
  selectedEnd(ev: Date) {
    log.debug(ev);
    this.selectRange({
      ...this.range.value,
      ...{
        to: endOfDay(ev),
        name: 'time-range.preset.custom',
        rangeType: 'month',
      },
    });
  }
  private toggleType: 'start' | 'end' = 'start';
  selectedChange(ev: Date) {
    const newRange = this.pickerStrategy.selectionFinished(ev, this.rangeNative);
    this.selectRange({
      ...this.range.value,
      ...{
        from: isValid(newRange.start) ? startOfDay(newRange.start) : null,
        to: isValid(newRange.end) ? endOfDay(newRange.end) : null,
        name: 'time-range.preset.custom',
        rangeType:
          isValid(newRange.start) && isValid(newRange.end)
            ? differenceInDays(newRange.start, newRange.end) >= 20
              ? 'month'
              : 'week'
            : 'month',
      },
    });
    this.startCal.stateChanges.next();
  }
  saveSelection() {
    this.dialogRef.close(this.range.getValue());
  }
  cancel() {
    this.dialogRef.close();
  }

  dtRange() {
    return (date: Date): MatCalendarCellCssClasses => {
      const range = this.range ? this.range.getValue() : null;
      let isRange = false;
      if (range && differenceInDays(range.from, range.to) === 0) {
        return;
      }
      if (range && (isRange = differenceInDays(range.from, range.to) > 0)) {
        log.debug(isRange, date);
        return 'mat-date-range-inBetween';
      }
      if (range && (isRange = isSameDay(range.from, Date.now()))) {
        log.debug(isRange, date);
        return 'mat-date-range-isStart';
      }
      if (range && (isRange = isSameDay(range.to, Date.now()))) {
        log.debug(isRange, date);
        return 'mat-date-range-isEnd';
      }
    };
  }
}
