import { Router } from '@angular/router';
import { resetStores } from '@datorama/akita';
import { environment } from '@env/environment';
import { initTeamsJS, isTeamsWindow } from '@env/msal';
import * as Sentry from '@sentry/angular';
import produce from 'immer';
import { merge } from 'lodash-es';
import {
  ApplicationSettingsQuery,
  ApplicationSettingsStore,
  BaseService,
  Logger,
  ProjectsQuery,
  ProjectsService,
  UserService,
  UserSettings,
  UserSettingsQuery,
  WorkspacesService,
} from 'timeghost-api';
import { ProjectsStore } from 'timeghost-api/lib/stores/projects/projects.store';

import getSupportedLanguages from './_helpers/getSupportedLanguages';
import { isNullOrUndefined } from './_helpers/utils';
import { getBrowserTimezone } from './account-profile/custom-timezones';
import { AppService } from './app.service';
import { I18nService } from './core';
import { RecordToolbarService } from './shared/record-toolbar/record-toolbar.service';
import appAuth from './app-auth-handler';
import { MsalService } from '@azure/msal-angular';
import { forkJoin } from 'rxjs';
import { UserAvatarService } from './shared/user-avatar/user-avatar.service';
import { TranslateService } from '@ngx-translate/core';

const log = new Logger('AppLoad-Handler');
export const initializeSentry = (user: UserSettings) => {
  Sentry.setTags({
    'user.id': user.id,
    'user.name': user.officeProfile?.displayName,
    'user.workspaceId': user.workspace?.id ?? user.defaultWorkspace,
    'user.email': user.email,
    'user.defaultWorkspaceId': user.defaultWorkspace,
    'user.workspaceName': user.workspace?.name,
    'user.language': user.settings?.languageSetting || Intl.DateTimeFormat().resolvedOptions().locale,
    'user.timezone': user.settings?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
    'user.release': environment.releaseName,
    'user.subscription.provider': user.workspace.subscription?.provider,
    'user.subscription.quantity': user.workspace.subscription?.quantity?.toString(),
    'user.subscription.interval': user.workspace.subscription?.recurringInterval,
    'user.subscription.status': user.workspace.subscription?.provider,
  });
};
const resetAppSettings = (appSettings: ApplicationSettingsStore) => {
  return appSettings.update((draft) => {
    draft = merge(draft || {}, {
      dashboard: {},
      project: {},
      client: {},
      reports: {},
      workspace: {
        active: null,
      },
      config: {},
      userId: null,
    });
    return draft;
  });
};
export const initialize = function (
  baseService: BaseService,
  i18n: I18nService,
  appService: AppService,
  userSettingsQuery: UserSettingsQuery,
  userService: UserService,
  workspaceService: WorkspacesService,
  projectsService: ProjectsService,
  appSettings: ApplicationSettingsStore,
  appSettingsQuery: ApplicationSettingsQuery,
  projectQuery: ProjectsQuery,
  recordService: RecordToolbarService,
  router: Router,
  msal: MsalService,
  userAvatars: UserAvatarService,
  translate: TranslateService
) {
  const isTeams = isTeamsWindow();
  log.debug('init API');
  log.debug('teams: ' + isTeams);
  log.debug('tz:', getBrowserTimezone());
  log.debug('args:', arguments);
  window.dataLayer.push({
    platform: isTeams ? 'teams' : 'web',
  });
  if (environment.production && localStorage.version !== (environment.releaseName || environment.version)) {
    resetStores();
  }
  const currentUser = userSettingsQuery.getValue(),
    currentSettings = appSettings.getValue();
  if (
    (currentUser?.id && currentUser.id !== currentSettings?.userId) ||
    (currentSettings?.workspace?.active && currentUser.workspace.id !== currentSettings.workspace.active) ||
    !currentSettings ||
    Object.keys(currentSettings).length === 0
  ) {
    resetStores();
    resetAppSettings(appSettings);
  }
  return () =>
    new Promise((resolve, reject) => {
      baseService
        .init()
        .then(() =>
          isTeams
            ? initTeamsJS()
            : new Promise((_resolve) => {
                _resolve(null);
              })
        )
        .then((teams: any) => {
          if (teams && appService.selectedTheme === 'default') {
            teams.getContext((context: any) => {
              if (!context) return appService.setMode(localStorage.theme, true);
              if (context.theme === 'dark') appService.setMode('default', true);
            });
            teams.registerOnThemeChangeHandler(
              (theme: string) =>
                appService.selectedTheme === 'default' && theme && appService.setMode('default', true, true)
            );
          } else if (window.matchMedia && appService.selectedTheme === 'default') {
            appService.setMode('default', true);
            const browserStyleContext = window.matchMedia('(prefers-color-scheme: dark)');
            if (browserStyleContext.addEventListener)
              browserStyleContext.addEventListener('change', (e) => {
                if (appService.selectedTheme === 'default')
                  appService.setMode(e.matches ? localStorage.theme : 'default');
              });
          } else {
            appService.setMode(localStorage.theme, true);
          }
          return teams;
        })
        .then(() => appAuth.bind(this, ...arguments)()) // handle authentication
        .then(() =>
          userService
            .getCurrentUser()
            .toPromise()
            .then((user) => {
              baseService.checkConsent();
              return user;
            })
        )
        .then(() => baseService.checkConsent().catch(() => null))
        .then(() => {
          return new Promise<any | void>((resolveWorkspace) => {
            setTimeout(() => {
              // run on next tick
              const user = userSettingsQuery.getValue(),
                urlTree = router.parseUrl(location.pathname + location.search),
                // @ts-ignore
                workspaceId = urlTree.queryParams?.workspaceid;
              if (!workspaceId) return resolveWorkspace(null);
              const ws = user.workspaces.find((x) => x.id === workspaceId);
              delete urlTree.queryParams.workspaceid;
              if (!ws || workspaceId === user.workspace.id) return resolveWorkspace(null);
              appService.resetStores();
              resetAppSettings(appSettings);
              const prevWs = { ...user.workspace };
              return userService
                .changeWorkspace({ id: workspaceId } as any)
                .then(async (userSettings) => {
                  userSettingsQuery.__store__.update(userSettings);
                  await appService.reinitializeStores(['workspaces', 'projects']);
                  await appService.connectSignal(true);
                  appService.initSignalReconnectOnFailed();

                  router.navigateByUrl(urlTree);
                  return userSettings;
                })
                .then(resolveWorkspace)
                .catch(() => {
                  if (prevWs) return userService.changeWorkspace(prevWs).then(resolveWorkspace).catch(resolveWorkspace);
                  return resolveWorkspace(null);
                });
            });
          });
        })
        .then(() => {
          const userSettings = userSettingsQuery.getValue();
          appSettings.update((state) => {
            state.workspace = Object.assign({}, userSettings.workspace || {}, { active: userSettings.workspace?.id });
            state.userId = userSettings.id;
          });
          if (
            !userSettings.settings ||
            Object.keys(userSettings.settings).length === 0 ||
            Object.values(userSettings.settings).findIndex((x) => isNullOrUndefined(x)) !== -1
          ) {
            const languageSetting =
              userSettings.settings?.languageSetting ??
              {
                de: 'de-DE',
                en: 'en-US',
              }[Intl.DateTimeFormat()?.resolvedOptions()?.locale] ??
              'en-US';
            return userService
              .changeSettings({
                timeZone: userSettings.settings?.timeZone ?? getBrowserTimezone(),
                tableDisplay: userSettings.settings.tableDisplay || 'Simple',
                captureManualMode: userSettings.settings?.captureManualMode ?? true,
                feedShowBooked: userSettings.settings?.feedShowBooked ?? true,
                languageSetting,
                timeFormat24h: userSettings.settings?.timeFormat24h ?? languageSetting !== 'en-US',
              })
              .toPromise();
          } else {
            const timeZone = getBrowserTimezone();
            if (!userSettings.settings?.timeZone && timeZone) {
              return userService
                .changeSettings({
                  timeZone: timeZone,
                })
                .toPromise();
            }
          }
          return userSettings;
        })
        .then((userSettings) => {
          i18n.init(environment.defaultLanguage, getSupportedLanguages());
          i18n.language = userSettings.settings.languageSetting || environment.defaultLanguage;
          window.dataLayer.push({
            Language: i18n.language,
          });
          if (userSettings.workspace && !userSettings.workspace.settings?.timesMode) {
            return produce(userSettings, (draft) => {
              draft.workspace.settings = {
                ...draft.workspace.settings,
                timesMode: 'range',
              };
            });
          }
          return userSettings;
        })
        .then((user) => {
          initializeSentry(userSettingsQuery.getValue());
          return Promise.all([
            workspaceService.get().toPromise(),
            projectsService.get().toPromise(),
            Promise.resolve(user),
          ]);
        }) // load projects/workspaces on init (prevent null projects)
        .then(([workspaces, projects, user]) => {
          if (!user.pinnedProjects) {
            produce(user, (draft) => {
              draft.pinnedProjects = [];
            });
          }
          (projectQuery.__store__ as ProjectsStore).remove((x) => x.completed);
          const defaultProject = projects?.find((x) => x.useAsDefault);

          if (defaultProject)
            recordService.group.patchValue({
              project: defaultProject,
            });
          return user;
        })
        .then(() => {
          localStorage.version = environment.releaseName || environment.version;

          window.dataLayer.push({
            event: 'PageStatus',
            data: 'InitializationCompleted',
          });
          userAvatars.initialize(); // fetch avatars async queued
          resolve(null);
        })
        .then(
          () =>
            translate
              .getTranslation(translate.currentLang)
              .toPromise()
              .then((translations) => {
                log.debug('translations', translations);
                return translations;
              })
              .catch(() => null) // load set lang
        )
        .catch((err) => {
          console.error(err);
        });
    });
};
