import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskPickerDialogComponent } from './task-picker-dialog.component';
import { TaskPickerEntryComponent } from './task-picker-entry/task-picker-entry.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/material.module';
import { CustomDirectivesModule } from '@app/_directives/custom-directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { CreateTaskDialogModule } from '../create-task-dialog/create-task-dialog.module';

@NgModule({
  declarations: [TaskPickerDialogComponent, TaskPickerEntryComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    CustomDirectivesModule,
    TranslateModule,
    RouterModule,
    ScrollingModule,
    CreateTaskDialogModule,
  ],
  exports: [TaskPickerDialogComponent],
})
export class TaskPickerDialogModule {}
