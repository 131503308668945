import { StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Project, Task } from 'timeghost-api';
import { PlainStepperComponent } from '../plain-stepper/plain-stepper.component';
import { TimeTrackCreateData } from '../time-tracker-calendar-create-dialog/time-tracker-calendar-create-dialog.component';

@Component({
  selector: 'tg-time-tracker-calendar-stepper-create-dialog',
  templateUrl: './time-tracker-calendar-stepper-create-dialog.component.html',
  styleUrls: ['./time-tracker-calendar-stepper-create-dialog.component.scss'],
  animations: [],
})
export class TimeTrackerCalendarStepperCreateDialogComponent implements OnInit, AfterContentInit {
  @ViewChild(PlainStepperComponent, { static: true })
  stepper: PlainStepperComponent;
  constructor(
    private ref: MatDialogRef<TimeTrackerCalendarStepperCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public createData: TimeTrackCreateData & { skipProjectStepper: boolean }
  ) {}

  stepIndex: number = 0;
  ngOnInit() {
    this.ref.updateSize('760px', '620px');
    this.ref.addPanelClass(['mat-dialog-vanilla', 'mat-dialog-relative', 'mat-dialog-flex']);
    if (this.createData?.skipProjectStepper === true) this.stepIndex = 1;
  }
  next() {
    this.stepper.next();
  }
  get entityData() {
    return {
      project: this.createData?.project,
      task: this.createData?.task,
    };
  }
  onSelectProject(entity: { project: Project; task?: Task }) {
    if (entity)
      this.createData = {
        ...this.createData,
        project: entity.project,
        billable: !!entity.project?.billable,
        task: entity.task,
      };
    this.stepIndex = 1;
  }
  ngAfterContentInit() {
    setTimeout(() => {
      if (this.createData?.skipProjectStepper !== true) this.stepIndex = 0;
    }, 50);
  }
}
