import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { parseDuration } from '@app/components/duration-input-control/duration-input-utils';
import { DistinctEqual, isNullOrUndefined } from '@app/_helpers/utils';
import { isValid, parse } from 'date-fns/esm';
import { isAfter, parse as parsefp } from 'date-fns/esm/fp';
import { flow } from 'lodash';
import { Logger } from 'timeghost-api';
const log = new Logger('CustomValidators');
export const EMAIL_REGEX =
  /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
export class CustomValidators {
  static isEmail(val: string) {
    return EMAIL_REGEX.test(val);
  }
  static isCharactersOrSpace = Validators.pattern('[a-zA-Z ]*');
  static hasNotNumber = (ctrl: AbstractControl) => {
    return `${ctrl.value}`.match(/[0-9]/g)?.length > 0 ? { hasNumber: true } : null;
  };

  static timeDiffCheck = (leftKey: string, rightKey: string) =>
    ((): ValidatorFn => (ctrl) => {
      const value = ctrl.value;
      if (value[leftKey] == null || value[rightKey] == null) {
        return null;
      }
      const start = parse(value[leftKey], 'HH:mm', Date.now()),
        end = parse(value[rightKey], 'HH:mm', Date.now());
      if (!isValid(start) || !isValid(end) || flow(isAfter(end))(start)) {
        return {
          range: { start: value[leftKey], end: value[rightKey], invalidRange: true },
        };
      }
    })();
  static validDateFormat =
    (format: string = 'HH:mm', required?: boolean) =>
    (ctrl: AbstractControl) => {
      if (!ctrl.value) {
        if (required)
          return {
            time: ctrl.value,
          };
        return null;
      }
      if (!isValid(parse(ctrl.value, format, new Date())))
        return {
          time: ctrl.value,
        };
      return null;
    };
  static validDuration = (required?: boolean) => (ctrl: AbstractControl) => {
    if (!ctrl.value) {
      if (required)
        return {
          time: ctrl.value,
        };
      return null;
    }
    let pDuration: [number, number];
    if (!(pDuration = parseDuration(ctrl.value)) || pDuration.find((x) => x === undefined))
      return {
        duration: ctrl.value,
        durationMessage: 'Invalid Duration',
      };
    return null;
  };
  static match = (reg: RegExp) => (ctrl: AbstractControl) => {
    if (!reg.test(ctrl.value))
      return {
        match: ctrl.value,
      };
    return null;
  };
  static validDate = (ctrl: AbstractControl) => {
    if (!ctrl.value) return null;
    if (!isValid(ctrl.value))
      return {
        time: ctrl.value,
      };
    return null;
  };
  static minLength = (_minLength: number) => {
    return (ctrl: AbstractControl) => {
      if (!ctrl.value) return null;
      if (typeof ctrl.value !== 'string') {
        return {
          minlength: {
            requiredLength: _minLength,
            actualLength: 0,
          },
        };
      }

      let length = ctrl.value.trim().length;
      if (length >= _minLength) {
        return null;
      }
      return {
        minlength: {
          requiredLength: _minLength,
          actualLength: length,
        },
      };
    };
  };
  static mustChange = (before: any, onlyDisabled: boolean = false) => {
    return (ctrl: AbstractControl) => {
      if (ctrl.disabled) return null;
      return JSON.stringify({ ...Object.keys(ctrl.value).reduce((l, r) => ({ ...l, [r]: before[r] }), {}) }) !==
        JSON.stringify(ctrl.value)
        ? null
        : {
            nochange: true,
          };
    };
  };
  static controlMustChange = (before: any) => {
    return (ctrl: AbstractControl) => {
      if (ctrl.disabled) return null;
      return JSON.stringify(before) !== JSON.stringify(ctrl.value)
        ? null
        : {
            nochange: true,
          };
    };
  };
  static checkForChange = (val: (_ctrl: AbstractControl) => boolean) => {
    return (ctrl: AbstractControl) => {
      if (ctrl.disabled) return null;
      return !val(ctrl)
        ? null
        : {
            changed: true,
          };
    };
  };
  static typeCheck =
    (type: 'string' | 'number' | 'bigint' | 'boolean' | 'symbol' | 'undefined' | 'object' | 'function') =>
    (cntrl: AbstractControl) => {
      if (typeof cntrl.value !== type) {
        return {
          typeCheck: {
            invalid: true,
            current: typeof cntrl.value,
            expected: type,
          },
        };
      }
      return null;
    };
  static isNumeric = Validators.pattern('^[0-9]*$');
  static isFloat = (ctrl: AbstractControl) => {
    return Validators.pattern('^[0-9]+([,.][0-9]+)?$')(ctrl);
  };
  /**
   * @name predicate Make your own Validation
   * @param func Custom Function to validate stuff on your context (return object for custom validation errors otherwise use true/false)
   * @returns object | null
   */
  static predicate = (func: (ctrl: AbstractControl) => any) => (ctrl: AbstractControl) => {
    const val: string = ctrl.value;
    const result = func(ctrl);
    if (typeof result === 'boolean' && !!result) {
      return {
        predicate: false,
      };
    }
    if (typeof result === 'object' && !!result) {
      return result;
    }
    return null;
  };
}
export const DistinctUntilCosmosChange = (left: any, right: any) => {
  if (left && right) {
    if (Array.isArray(left) && Array.isArray(right)) {
      return (
        JSON.stringify(left.map(({ id, _ts }) => ({ id, _ts }))) ===
        JSON.stringify(right.map(({ id, _ts }) => ({ id, _ts })))
      );
    } else if (left._ts && right._ts) {
      return JSON.stringify(left, ['id', '_ts']) === JSON.stringify(right, ['id', '_ts']);
    }
  } else {
    return JSON.stringify(left) === JSON.stringify(right);
  }
};
export const DistinctUntilChangedBy =
  <T>(func: (item: T) => any) =>
  (left: T, right: T) =>
    func(left) === func(right);
export const DistinctUntilDiff = <T>(left: T, right: T) => left === right;
