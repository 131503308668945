import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { createRxValue, hasPermissionTaskView } from '@app/_helpers/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, from } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, startWith } from 'rxjs/operators';
import { Project, TasksQuery, TasksService, UserSettingsQuery, Task } from 'timeghost-api';
import { CreateTaskDialogComponent } from '../create-task-dialog/create-task-dialog.component';
type TaskPickerDialogData = {
  project: Project;
  task?: Task;
};
let previousProjectId: string = null;
@UntilDestroy()
@Component({
  selector: 'tg-task-picker-dialog',
  templateUrl: './task-picker-dialog.component.html',
  styleUrls: ['./task-picker-dialog.component.scss'],
  host: {
    class: 'search-dialog-host',
  },
})
export class TaskPickerDialogComponent implements OnInit {
  searchinput: UntypedFormControl = new UntypedFormControl('');
  constructor(
    private userSettingsQuery: UserSettingsQuery,
    private taskQuery: TasksQuery,
    private taskService: TasksService,
    private ref: MatDialogRef<TaskPickerDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: TaskPickerDialogData
  ) {}
  selected$ = createRxValue<Task>(this.data.task);
  workspace$canManageTask = createRxValue<boolean>(false);
  data$filtered = combineLatest([
    this.taskQuery.selectAll(),
    this.searchinput.valueChanges.pipe(startWith(this.searchinput.value), distinctUntilChanged()),
    this.selected$.asObservable(),
  ]).pipe(
    map(([x, q, selected]) => {
      let data = this.data.project?.id ? x.filter((t) => t.project && t.project.id === this.data.project.id) : x;
      if (q && this.searchinput.valid)
        data = data.filter((t) => t.name.toLowerCase().indexOf(`${q}`.toLowerCase()) !== -1);
      if (selected?.id)
        data = data.map((t) => {
          return { ...t, selected: t.id === selected.id };
        });
      return data;
    })
  );
  isLoading = false;
  ngOnInit(): void {
    this.ref._containerInstance._config.autoFocus = true;
    this.ref._containerInstance._config.closeOnNavigation = true;
    this.ref.addPanelClass(['client-project-picker-dialog-container', 'mat-dialog-relative']);
    this.ref.updateSize('460px');
    this.workspace$canManageTask.value = !!this.data.project?.currentUserCanEdit;
    if (this.data.project?.id && previousProjectId !== this.data.project.id) {
      this.isLoading = true;
      this.taskService
        .getByProject({ id: this.data.project.id } as Project, false)
        .pipe(
          untilDestroyed(this),
          finalize(() => (this.isLoading = false))
        )
        .subscribe(() => {
          previousProjectId = this.data.project.id;
        });
    }
  }
  createTaskDialog() {
    this.dialog
      .open(CreateTaskDialogComponent, {
        data: {
          search: this.searchinput.value,
          project: this.data.project,
        },
      })
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe((x: Task) => this.onSelect(x as any));
  }
  trackId(index: number, { id }: { id: string }) {
    return id;
  }
  contextMenuPosition = { x: '0px', y: '0px' };
  openContextMenu(event: MouseEvent | Event, trigger: MatMenuTrigger, data: any) {
    event.stopPropagation(), event.preventDefault();
    if (event instanceof MouseEvent) {
      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
    } else {
      const { left, top } = (event.currentTarget! as HTMLElement)?.getClientRects()[0];
      this.contextMenuPosition.x = left + 'px';
      this.contextMenuPosition.y = top + 'px';
    }
    trigger.menuData = data;
    trigger.menu.focusFirstItem('mouse');
    trigger.openMenu();
  }
  onSelect(ev: Task & { selected: boolean }) {
    this.selected$.value = ev?.selected ? null : ev;
    this.ref.close({ ...ev, selected: !ev?.selected });
  }
}
