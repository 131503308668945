<mat-card class="pos-relative group-wrap rounded-inherit" *ngIf="this.template as t">
  <mat-card-title-group class="group-header" fxLayout="row" fxLayoutAlign="start start">
    <mat-card-title class="group-date">{{ data.date | dfnsFormat: 'iiii' }}</mat-card-title>
    <mat-card-subtitle class="group-date-full">{{ data.date | formatDate: 'PP' }}</mat-card-subtitle>
    <div fxFlex></div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
      <ng-container *ngIf="this.data$stats | async as stats">
        <div
          *ngIf="stats.billablePercentage"
          [matTooltip]="'BILLING' | translate"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
        >
          <div>
            <small>{{ stats.billablePercentageFormat }}%</small>
          </div>
          <div class="mat-circle-card" *ngIf="stats.billablePercentage > 0">
            <mat-progress-spinner color="accent" [strokeWidth]="3" [diameter]="20" [value]="stats.billablePercentage">
            </mat-progress-spinner>
          </div>
        </div>
      </ng-container>
      <div
        fxLayout="column"
        *ngIf="this.data$stats | async as item"
        class="cursor-default"
        matTooltip
        [matTooltipTemplate]="durationHoverCard"
        [matTooltipTemplateContext]="{ timeDiff: item.timeSum, billable: item.billable }"
      >
        <small>
          <strong> {{ item?.timeSum ?? 0 | parseMsAsDuration: false }} </strong>
        </small>
        <small class="mat-hint" *ngIf="item.billable as billable"> {{ billable | parseMsAsDuration: false }} </small>
      </div>
    </div>
  </mat-card-title-group>
  <mat-card-content class="--table-time-container">
    <div class="group-content group-content-scroll" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
      <ng-container *ngTemplateOutlet="t"></ng-container>
    </div>
  </mat-card-content>
</mat-card>
<ng-template #durationHoverCard let-timeDiff="timeDiff" let-billable="billable">
  <div fxLayout="column">
    <span>{{ timeDiff | parseMsAsDuration: true }}</span>
    <span *ngIf="billable" class="text-accent">{{ billable | parseMsAsDuration: true }}</span>
  </div>
</ng-template>
