import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject, Injectable, Optional } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { EntityStore, PersistState, resetStores } from '@datorama/akita';
import { environment } from '@env/environment';
import { isTeamsWindow } from '@env/msal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { endOfDay, startOfDay } from 'date-fns/esm';
import { BehaviorSubject, forkJoin, fromEvent, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  Client,
  ClientsQuery,
  ClientsService,
  FeedQuery,
  FeedService,
  Logger,
  MyTimesQuery,
  MyTimesService,
  NotifyService,
  Project,
  ProjectsQuery,
  ProjectsService,
  TagsService,
  Time,
  UserService,
  UserSettingsQuery,
  Workspace,
  WorkspacesQuery,
  WorkspacesService,
} from 'timeghost-api';
import { MyTimesStore } from 'timeghost-api/lib/stores/myTimes/myTimes.store';

import { ColorScheme } from './_classes/color-scheme';
import color from './_helpers/color';
import { debounceTimeAfterFirst } from './_helpers/debounceAfterTime';
import { errorEventHandler } from './_helpers/globalErrorHandler';
import wakeup from './services/on-weakup/wakeup';
import { APP_ASSIGN_COLOR_SCHEME } from './shared/color-schemes/office-scheme';
import RoundingConfigData from './shared/dialogs/rounding-dialog/models/rounding-config-data';
import { RecordToolbarService } from './shared/record-toolbar/record-toolbar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './components/generic-dialogs/confirm-dialog/confirm-dialog.component';
import { OfflineDialogComponent } from './components/offline-dialog/offline-dialog.component';
import { initFrillJs, WIDGET_ID as FRILL_WIDGET_ID } from '@env/frill';
import { createRxValue, fromRxValue } from './_helpers/utils';
import { NotifierNotificationOptions } from 'angular-notifier/lib/models/notifier-notification.model';
import { MsalBroadcastService } from '@azure/msal-angular';

declare const window: Window;
const log = new Logger('AppService');
export type ThemeType = 'dark' | 'light' | 'default';
type EntityHasWorkspace = {
  workspace: { id: string };
};
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AppService {
  colorScheme = new ColorScheme(APP_ASSIGN_COLOR_SCHEME);
  events = new EventEmitter<[string, ...any[]]>(true);
  readonly isMobile$ = this.media
    .asObservable()
    .pipe(map((x) => x.findIndex((y) => ['xs', 'sm'].includes(y.mqAlias)) !== -1));
  get isMobile() {
    return this.media.isActive(['xs', 'sm']);
  }

  emitEvent(event: string, ...args: any[]) {
    return this.events.emit([event, ...args]);
  }
  isCDKActive() {
    return !!this.document.querySelector(`.cdk-overlay-container .cdk-overlay-pane`);
  }
  private _isOnline = new BehaviorSubject<boolean>(true);
  readonly isOnline$ = this._isOnline.asObservable().pipe(distinctUntilChanged());
  get isOnline() {
    return this._isOnline.getValue();
  }
  set isOnline(val: boolean) {
    this._isOnline.next(val);
  }
  readonly browserWoken = new EventEmitter<number>(true);

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    @Optional()
    @Inject('persistStorage')
    private persistStore: PersistState,
    public notifier: NotifierService,
    private translate: TranslateService,
    private userSettingsQuery: UserSettingsQuery,
    private userService: UserService,
    private notifyService: NotifyService,
    private workspaceQuery: WorkspacesQuery,
    private feedService: FeedService,
    private feedQuery: FeedQuery,
    private media: MediaObserver,
    private clientsService: ClientsService,
    private projectsService: ProjectsService,
    private tagsService: TagsService,
    private myTimesService: MyTimesService,
    private recordService: RecordToolbarService,
    private workspaceService: WorkspacesService,
    private myTimesQuery: MyTimesQuery,
    private projectsQuery: ProjectsQuery,
    private clientsQuery: ClientsQuery,
    private msalBroadcast: MsalBroadcastService,
    private dialog: MatDialog
  ) {
    if (!localStorage.theme) localStorage.theme = 'default';
    if (!!localStorage.roundingConfigData) {
      this.roundingData = JSON.parse(localStorage.roundingConfigData);
    }
  }
  readonly authState = fromRxValue(this.msalBroadcast.inProgress$);
  storesLoading = false;
  reinitializeStores(
    ignore?: Partial<['workspaces', 'projects']>,
    options?: Partial<{ feedKeepTime: boolean; disableRemove: boolean }>
  ) {
    const today = new Date();
    const user = this.userSettingsQuery.getValue();
    return new Promise<boolean>(async (resolve, reject) => {
      (this.myTimesQuery.__store__ as MyTimesStore).updateState({ nextDate: 'init' });
      this.myTimesService.get('init'),
        options?.feedKeepTime
          ? ((date) => this.feedService.load(date, date))(new Date(this.feedQuery.getValue().nextDate))
          : this.feedService.load(startOfDay(today), endOfDay(today));
      if (!options?.disableRemove) {
        await Promise.resolve(
          (this.projectsQuery.__store__ as EntityStore).remove(
            (x: Project & EntityHasWorkspace) => x.workspace?.id && x.workspace.id !== user.workspace.id
          )
        ),
          await Promise.resolve(
            (this.clientsQuery.__store__ as EntityStore).remove(
              (x: Client & EntityHasWorkspace) => x.workspace?.id && x.workspace.id !== user.workspace.id
            )
          );
      }
      forkJoin([
        !ignore?.includes('workspaces') ? this.workspaceService.get().toPromise() : Promise.resolve<Workspace[]>([]),
        this.clientsService.get().toPromise(),
        !ignore?.includes('projects') ? this.projectsService.get().toPromise() : Promise.resolve<Project[]>([]),
        this.tagsService.get().toPromise(),
      ])
        .pipe(switchMap((x) => this.connectSignal(true).then(() => x)))
        .pipe(
          switchMap(([, , projects]) => {
            this.storesLoading = true;
            const handleTime = (time: Time[]) => {
              if (
                !time?.length &&
                (!this.recordService.group.value.project?.id ||
                  !projects.find((x) => x.id === this.recordService.group.value.project?.id)) &&
                projects?.find((x) => !!x?.useAsDefault)
              ) {
                this.recordService.group.patchValue({
                  project: projects.find((x) => !!x?.useAsDefault),
                  task: null,
                });
                return time[0];
              }
              return null;
            };
            return this.myTimesService.getLatestRecordings(1).then(handleTime).catch(handleTime);
          })
        )
        .subscribe({
          next: () => {
            this.storesLoading = false;
            resolve(true);
          },
          error: (err) => reject(err),
        });
    });
  }
  resetStores() {
    if (localStorage)
      localStorage.removeItem('project_filter'),
        localStorage.removeItem('dashboard_filter'),
        localStorage.removeItem('projectSearch'),
        this.persistStore?.clearStore();
    resetStores({ exclude: [this.userSettingsQuery.__store__.storeName, this.workspaceQuery.__store__.storeName] });

    this.recordService.resetAll();
  }
  private _visibility = new BehaviorSubject<DocumentVisibilityState>('visible');
  readonly visibility$ = this._visibility.asObservable().pipe(distinctUntilChanged());
  get visibility() {
    return this._visibility.getValue();
  }
  set visibility(val: DocumentVisibilityState) {
    this._visibility.next(val);
  }
  readonly onResume = new EventEmitter(true);

  initialize() {
    fromEvent(document, 'onvisibilitychange').subscribe(
      () => (this.visibility = document.hidden ? 'hidden' : 'visible')
    );
    fromEvent(document, 'resume').subscribe(() => this.onResume.emit());
    fromEvent(window, 'online').subscribe(() => (this.isOnline = true)),
      fromEvent(window, 'offline').subscribe(() => (this.isOnline = false));

    if (!environment.production) setTimeout(() => wakeup(() => this.browserWoken.emit(Date.now()))); // register on next tick

    errorEventHandler
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x && Array.isArray(x))
      )
      .subscribe(([x, ...args]) => this.handleError(x, ...args));
  }
  get isSignalInstanceConnected() {
    return this.notifyService.isInstanceConnected();
  }
  get platform() {
    const m = navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|iOS|iMac/i)[0]?.toLowerCase();
    return m === 'android' ? 'android' : ['iphone', 'ipad', 'ipod', 'imac', 'ios', 'webos'].includes(m) ? 'ios' : null;
  }
  notifyError(body: string, options?: NotifierNotificationOptions, args?: { [key: string]: any }) {
    return this.notifier.show({
      type: 'error',
      ...(options ? options : {}),
      message: this.translate.instant(body, args || {}),
    });
  }
  notifyInfo(body: string, options?: NotifierNotificationOptions, args?: { [key: string]: any }) {
    return this.notifier.show({
      type: 'info',
      ...(options ? options : {}),
      message: this.translate.instant(body, args || {}),
    });
  }
  notifySuccess(body: string, options?: NotifierNotificationOptions, args?: { [key: string]: any }) {
    return this.notifier.show({
      type: 'success',
      ...(options ? options : {}),
      message: this.translate.instant(body, args || {}),
    });
  }
  public static defaultTimeFormat(): '24h' | 'AMPM' {
    const lc = Intl.DateTimeFormat().resolvedOptions().locale;
    const hasPeriodSuffix =
      new Intl.DateTimeFormat(lc, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      })
        .formatToParts()
        .findIndex((x) => x.type === 'dayPeriod') !== -1;
    return hasPeriodSuffix ? 'AMPM' : '24h';
  }

  public get timezone(): string {
    return this.userSettingsQuery.getValue().settings.timeZone;
  }
  isTeams() {
    return !!window.teams || !!window['teams_test'] || isTeamsWindow();
  }
  private _isLoading = new BehaviorSubject<string[]>(null);
  readonly isLoading$ = this._isLoading.asObservable().pipe(
    distinctUntilChanged(),
    tap((x) => log.debug(JSON.stringify(x)))
  );
  get isLoading() {
    return this._isLoading.getValue();
  }
  set isLoading(val: string[]) {
    this._isLoading.next(val);
  }
  get isAMPM() {
    return !this.userSettingsQuery.getValue().settings.timeFormat24h;
  }
  formatAMPM(is24h?: boolean) {
    if (is24h === undefined) is24h = this.userSettingsQuery.getValue()?.settings?.timeFormat24h;
    if (typeof is24h !== 'boolean') {
      return AppService.defaultTimeFormat() === 'AMPM' ? 'hh:mm a' : 'HH:mm';
    }
    return is24h === true ? 'HH:mm' : 'hh:mm a';
  }
  get timeFormat() {
    return this.formatAMPM(this.userSettingsQuery.getValue().settings.timeFormat24h);
  }
  readonly timeFormat$ = this.userSettingsQuery.select().pipe(
    startWith(this.userSettingsQuery.getValue()),
    distinctUntilChanged(),
    shareReplay(),
    map((x) => this.formatAMPM(x.settings?.timeFormat24h))
  );
  addLoading(id: string, clear: boolean = false) {
    if (clear) {
      this.isLoading = null;
    }
    if (this.isLoading) {
      this.isLoading = [...this.isLoading, id];
    } else {
      this.isLoading = [id];
    }
    return this.isLoading.length;
  }
  checkLoading(id: string) {
    return this.isLoading && this.isLoading.findIndex((x) => x === id) !== -1;
  }
  removeLoading(id: string) {
    if (!this.isLoading) {
      return 0;
    }
    const newLoadingObj = this.isLoading.filter((x) => x !== id);
    const currentLength = this.isLoading.length;
    this.isLoading = newLoadingObj;
    return currentLength - newLoadingObj.length;
  }
  toggleLoading(id: string) {
    return this.isLoading?.findIndex((x) => x === id) !== -1 ? this.removeLoading(id) : this.addLoading(id);
  }
  get selectedTheme(): ThemeType {
    return ['default', 'dark', 'light'].includes(localStorage.theme) ? localStorage.theme : 'default';
  }
  set selectedTheme(theme: ThemeType) {
    localStorage.theme = theme;
    this.selectedThemeChange.emit(theme);
  }
  readonly selectedThemeChange = new EventEmitter<ThemeType>(true);
  readonly selectedTheme$ = this.selectedThemeChange.asObservable().pipe(
    startWith(this.selectedTheme),
    debounceTimeAfterFirst(50),
    distinctUntilChanged(),
    switchMap(() => this.getCurrentTheme())
  );
  async getCurrentTheme(): Promise<ThemeType> {
    const current = this.selectedTheme;
    if (current === 'default') {
      return await new Promise((resolve, reject) => {
        if (this.isTeams() && window.microsoftTeams) {
          window.microsoftTeams.getContext((context: any) => {
            resolve(context.theme === 'dark' ? 'dark' : 'light');
          });
        } else if (window.matchMedia) {
          resolve(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
        } else {
          resolve('light');
        }
      });
    }
    return current;
  }
  setMode(type: ThemeType, forceUpdate?: boolean, showLoading: boolean = true) {
    let styles: HTMLLinkElement[] = Array.from(this.document.head.querySelectorAll('link[data-theme]'));
    const prevTheme = this.selectedTheme;
    if (type === 'default' && (forceUpdate === true || prevTheme !== 'default')) {
      if (this.isTeams() && window.microsoftTeams) {
        window.microsoftTeams.getContext((context: any) => {
          if (!context) return this.setMode('light', true);
          if (context.theme === 'dark') this.setMode('dark', true, true);
        });
      } else if (window.matchMedia) {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) this.setMode('dark', true);
        else this.setMode('light', true);
      } else {
        this.setMode('light');
      }
      this.selectedTheme = 'default';
      return;
    } else if (type === 'dark' && (forceUpdate === true || prevTheme !== 'dark')) {
      if (styles.findIndex((x: HTMLLinkElement) => x.dataset.theme === 'dark') === -1) {
        if (showLoading) this.addLoading('fullAppLoading', true);
        const stl = document.createElement('link');
        stl.rel = 'stylesheet';
        stl.href = 'app-dark.css';
        stl.dataset.theme = type;
        stl.dataset.themeRef = 'dark';
        if (showLoading)
          stl.onload = () => {
            styles.forEach((x) => x.remove());
            setTimeout(() => {
              this.removeLoading('fullAppLoading');
            }, 200);
          };
        styles.forEach((x) => x.remove());
        this.document.head.insertBefore(stl, this.document.head.querySelector('style'));
      }
      localStorage.theme = type;
    } else if (type === 'light' && (forceUpdate === true || prevTheme !== 'light')) {
      if (styles.findIndex((x: HTMLLinkElement) => x.dataset.theme === 'light') === -1) {
        if (showLoading) this.addLoading('fullAppLoading', true);
        const stl = document.createElement('link');
        stl.rel = 'stylesheet';
        stl.href = 'app.css';
        stl.dataset.theme = type;
        stl.dataset.themeRef = 'light';
        if (showLoading)
          stl.onload = () => {
            styles.forEach((x) => x.remove());
            setTimeout(() => {
              this.removeLoading('fullAppLoading');
            }, 200);
          };
        this.document.head.insertBefore(stl, this.document.head.querySelector('style'));
      }
      this.selectedTheme = type;
    }
    if (type !== 'default') document.documentElement.setAttribute('data-theme', type);
  }

  private _colorAssignments = new BehaviorSubject<IColorAssignment[]>([]);
  readonly colorAssignments$ = this._colorAssignments.asObservable().pipe(distinctUntilChanged());
  get colorAssignments() {
    return this._colorAssignments.getValue();
  }
  set colorAssignments(val: IColorAssignment[]) {
    this._colorAssignments.next(val);
  }
  getColorFromScheme(scheme: string[], rnd?: number) {
    const colorIndex = (rnd || 1) % APP_ASSIGN_COLOR_SCHEME.length;
    return APP_ASSIGN_COLOR_SCHEME[colorIndex];
  }
  dynamicColor(useCustomScheme?: boolean) {
    if (useCustomScheme === true) {
      const colorIndex = this.colorAssignments.length % APP_ASSIGN_COLOR_SCHEME.length;
      return color.fromPalette(APP_ASSIGN_COLOR_SCHEME[colorIndex]).rgbString();
    }
    let r = Math.floor(Math.random() * 255);
    let g = Math.floor(Math.random() * 255);
    let b = Math.floor(Math.random() * 255);
    return `rgba(${r},${g},${b}, .65)`;
  }
  setColorById(id: string, color?: string, prefix?: string) {
    if (prefix) id = prefix + '.' + id;
    if (this.colorAssignments?.findIndex((x) => x.id === id) !== -1) return;
    this.colorAssignments = [...this.colorAssignments, { id, color: color || this.dynamicColor(true) }];
  }
  getColorById(id: string, prefix?: string) {
    return this.colorAssignments?.find((x) => x.id === (prefix ? prefix + '.' : '') + id)?.color;
  }
  hasColorById(id: string, prefix?: string) {
    return !this.colorAssignments?.findIndex((x) => x.id === (prefix ? prefix + '.' : '') + id);
  }

  private _roundingData = new BehaviorSubject<RoundingConfigData>(null);
  readonly roundingData$ = this._roundingData.asObservable().pipe(distinctUntilChanged());
  get roundingData() {
    return this._roundingData.getValue();
  }
  set roundingData(val: RoundingConfigData) {
    try {
      this._roundingData.next(val);
    } finally {
      localStorage.roundingConfigData = JSON.stringify(val);
    }
  }
  private signalInitSubscription: Subscription;
  connectSignal(replaceHub?: boolean) {
    return this.notifyService.createNewInstance(replaceHub === true || !this.notifyService.isInstanceConnected());
  }
  initSignalReconnectOnFailed() {
    if (this.signalInitSubscription) this.signalInitSubscription.unsubscribe();
    this.signalInitSubscription = this.notifyService.onHubClose$
      .pipe(
        debounceTime(1000),
        switchMap(() => this.notifyService.createNewInstance(!this.notifyService.isInstanceConnected()))
      )
      .subscribe();
  }
  private infoErrors: string[] = []; // todo
  handleError(err: any, options?: any, args: { [key: string]: any } = {}) {
    if (typeof err === 'string') return this.notifyError(err, options, args);
    else if (typeof err === 'object') {
      if (err instanceof HttpErrorResponse && !err.ok) {
        let errMsg: string;
        if (typeof err.error === 'object' && (errMsg = err.error?.message || err.error?.body)) {
          if (this.infoErrors.includes(errMsg.replace(/^error(s?)\./, '')))
            return this.notifyInfo(errMsg, options, args);
          return this.notifyError(errMsg, options, args);
        } else {
          if (err.status == 500) return this.notifyError('errors.server', options, args);
          else if (typeof err.error === 'string' && err.error?.match?.(/^error(s?)\./)) {
            if (this.infoErrors.includes(err.error.replace(/^error(s?)\./, '')))
              return this.notifyInfo(err.error, options, args);
            return this.notifyError(err.error, options, args);
          } else if (err.status == 401) return this.notifyError('utils.not-authorized', options, args);
          else if (typeof err.error === 'string') return this.notifyError(err.error, options, args);
          else if (typeof err.message === 'string') return this.notifyError(err.message, options, args);
        }
      }
      const message = err.message ?? err.content ?? 'Something went wrong...';
      if (message) return this.notifyError(message, options, args);
    }
  }
  confirmDialog(text: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            text,
          },
          disableClose: true,
          closeOnNavigation: false,
          position: { top: '16px' },
          width: '520px',
        })
        .afterClosed()
        .subscribe(resolve);
    });
  }
  frillLoading = createRxValue(false);
  frillBadgeCount = createRxValue<number>();
  private frillInstance: any;
  async getFrillWidget() {
    const theme = await this.getCurrentTheme();
    const user = this.userSettingsQuery.getValue();
    const isAdmin = !!user.workspace.users.find((x) => x.admin && x.id === user.id);
    const ssoToken = await this.userService
      .getFrillSsoToken()
      .toPromise()
      .then((x) => x?.ssoToken)
      .catch(() => undefined);
    const getInstance = () =>
      initFrillJs(isAdmin ? FRILL_WIDGET_ID.ADMIN : FRILL_WIDGET_ID.NORMAL, {
        theme,
        ssoToken,
        onUpdate: (ev, data) => {
          if (ev === 'badgeCount') this.frillBadgeCount.value = data;
        },
      });
    if (this.frillInstance) {
      this.frillInstance.toggle();
      this.frillLoading.value = false;
      return;
    }
    this.frillLoading.value = true;
    return await getInstance().then((Frill: any) => {
      Frill.toggle();
      this.frillLoading.value = false;
      this.frillInstance = Frill;
    });
  }
}
export interface IColorAssignment {
  id: string;
  color: string;
}
