import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShellMenuToggleComponent } from './shell-menu-toggle.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [ShellMenuToggleComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, FlexLayoutModule],
  exports: [ShellMenuToggleComponent],
})
export class ShellMenuToggleModule {}
