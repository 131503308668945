<ng-template #toggle>
  <div fxLayout="row" fxLayoutAlign="start center" *ngIf="isMobile$ | async">
    <button
      class="menu-button"
      mat-icon-button
      (click)="this.toggleNav()"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <mat-icon>menu</mat-icon>
    </button>
  </div>
</ng-template>
