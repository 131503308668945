import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { MaterialScale } from '@app/animations/fade';
import { createRxValue } from '@app/_helpers/utils';
import { WorkspaceGroup } from 'timeghost-api';
type Group = WorkspaceGroup & { users: { id: string; name: string; email: string }[] };
@Component({
  selector: 'tg-user-group-view',
  templateUrl: './user-group-view.component.html',
  styleUrls: ['./user-group-view.component.scss'],
  animations: [MaterialScale],
})
export class UserGroupViewComponent implements OnInit {
  @Input()
  group: Group;
  @Input()
  showGroupUsers: boolean;
  @Input()
  showDelete: boolean;
  @Input()
  deleteEntityTemplate: TemplateRef<any>;
  @Input()
  groupUsersTemplate: TemplateRef<any>;

  showUsers = createRxValue(false);
  constructor() {}
  toggleUsersView() {
    this.showUsers.value = !this.showUsers.value;
  }
  trackId(_: number, { id }: { id: string }) {
    return id;
  }
  ngOnInit(): void {}
}
