import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  BrowserAuthError,
  InteractionRequiredAuthError,
  PopupRequest,
} from '@azure/msal-browser';
import { environment } from '@env/environment';
import { isTeamsWindow } from '@env/msal';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';
import {
  ApplicationSettingsQuery,
  ApplicationSettingsStore,
  BaseService,
  initialScopes,
  Logger,
  ProjectsQuery,
  ProjectsService,
  Scope,
  UserService,
  UserSettingsQuery,
  WorkspacesService,
} from 'timeghost-api';

import { AppService } from './app.service';
import { I18nService } from './core';
import { RecordToolbarService } from './shared/record-toolbar/record-toolbar.service';
import { openURL } from './_helpers/aLink';
import { AccessTokenEntity } from '@azure/msal-common';
const emptyPromise = () => new Promise(() => {});
const log = new Logger('AppAuth-Handler');

const requireLoginErrors = [
  'interaction_required',
  'login_required',
  'invalid_grant',
  'no_account_error',
  'consent_required',
];

function checkAndSetActiveAccount(msal: MsalService) {
  /**
   * If no active account set but there are accounts signed in, sets first account to active account
   * To use active account set here, subscribe to inProgress$ first in your component
   * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
   */
  let activeAccount = msal.instance.getActiveAccount();

  if (!activeAccount && msal.instance.getAllAccounts().length > 0) {
    let accounts = msal.instance.getAllAccounts();
    msal.instance.setActiveAccount((activeAccount = accounts[0]));
  }
  return activeAccount;
}
const initFinish = new Subject<void>();
const closeInit = () => (initFinish.next(), initFinish.complete());
function login(this: MsalService) {
  return this.loginRedirect({ redirectStartPage: window.location.href, scopes: initialScopes })?.toPromise();
}
function handleMsalError(err: BrowserAuthError) {
  if (err.errorCode === 'interaction_in_progress') return false;

  return true;
}
async function checkTeamsAndRedirect(msal: MsalService, ignoreAccount?: boolean) {
  if (isTeamsWindow() && (ignoreAccount === true || !checkAndSetActiveAccount(msal))) {
    log.info('redirect to teams auth button page');
    localStorage.teams_auth_scopes = initialScopes.toString();
    localStorage.teams_auth_clientId = environment.adalConfig.clientId;
    openURL(window.location.origin + '/teams-auth-iframe.html').click();
    return await emptyPromise();
  }
  return null;
}
const initialize = async (
  baseService: BaseService,
  i18n: I18nService,
  appService: AppService,
  userSettingsQuery: UserSettingsQuery,
  userService: UserService,
  workspaceService: WorkspacesService,
  projectsService: ProjectsService,
  appSettings: ApplicationSettingsStore,
  appSettingsQuery: ApplicationSettingsQuery,
  projectQuery: ProjectsQuery,
  recordService: RecordToolbarService,
  router: Router,
  msal: MsalService
) => {
  log.debug('init Auth');
  if (!msal.instance.getActiveAccount() || msal.instance.getAllAccounts().length > 0) checkAndSetActiveAccount(msal);
  await checkTeamsAndRedirect(msal);
  return await new Promise((resolve, reject) => {
    msal
      .handleRedirectObservable()
      .pipe(takeUntil(initFinish))
      .subscribe({
        next: (auth: AuthenticationResult) => {
          const account = checkAndSetActiveAccount(msal);
          log.debug('[msal state]: ', auth, account);
          if (!auth) {
            if (account) {
              closeInit();
              return msal
                .acquireTokenSilent({
                  scopes: [Scope.Me],
                })
                .toPromise()
                .then((auth) => {
                  return auth;
                })
                .then(resolve)
                .catch(reject);
            } else {
              closeInit();
              return login.bind(msal)().then(resolve).catch(reject);
            }
          } else {
            msal.instance.setActiveAccount(auth.account);
            closeInit();
            return msal
              .acquireTokenSilent({
                scopes: [Scope.Me],
              })
              .toPromise()
              .then((auth) => {
                return auth;
              })
              .then(resolve)
              .catch(reject);
          }
        },
        error: (err) => {
          log.error(err);
          reject(err);
          closeInit();
        },
      });
  }).catch(async (err: any) => {
    if (err instanceof InteractionRequiredAuthError || err instanceof BrowserAuthError) {
      // move user to login if expired auth or interaction required
      if (err.errorCode && requireLoginErrors.find((x) => x === err.errorCode)) {
        await checkTeamsAndRedirect(msal, true);
        return login.bind(msal)();
      }
    }
    return await Promise.reject(err);
  });
};
export default initialize;
