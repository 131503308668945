import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspaceChangeConfirmDialogComponent } from './workspace-change-confirm-dialog.component';
import { MaterialModule } from '@app/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [WorkspaceChangeConfirmDialogComponent],
  imports: [CommonModule, FlexLayoutModule, MaterialModule, TranslateModule],
  exports: [WorkspaceChangeConfirmDialogComponent],
})
export class WorkspaceChangeConfirmDialogModule {}
