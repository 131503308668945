<app-loader [isActive]="this.isLoadingAsync | async" [isAfterViewInit]="false" [enableCircle]="true"></app-loader>
<h1 matDialogTitle>
  <div fxLayout="row" fxLayoutAlign="start center">
    <div>{{ 'client.create' | translate }}</div>
    <div fxFlex></div>
    <button mat-icon-button matDialogClose tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</h1>
<mat-dialog-content cdkTrapFocus>
  <form (submit)="$event.preventDefault()" [formGroup]="this.csGroup">
    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input type="text" matInput formControlName="name" required cdkInitialFocus />
        <mat-error *ngIf="this.csErrors as error">{{ error.content | translate: error.args || {} }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'Description' | translate }}</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions
  class="mat-action-filled"
  [matTooltip]="'workspace.errors.admin-create-client' | translate"
  [matTooltipDisabled]="this.workspace$createPermission | async"
>
  <button
    mat-flat-button
    class="mat-action-filled"
    color="primary"
    (click)="this.addEntry()"
    [disabled]="this.csGroup.invalid || !(this.workspace$createPermission | async)"
  >
    {{ 'client.create' | translate }}
  </button>
</mat-dialog-actions>
