<div class="flex flex-col">
  <div
    matTooltip
    matTooltipPosition="top-start"
    [matTooltipOptions]="{ delay: [600, 0], arrow: false }"
    [matTooltipTemplate]="tooltipTemplate"
    [matTooltipTemplateContext]="{
      project: entry,
      client: entry.client,
      task: entry.task,
      tags: entry.tags
    }"
    class="flex items-center flex-1 clprj-item"
    [class.bg-primary-2]="entry.selected"
  >
    <mat-list-item
      matRippleDisabled
      (keyup.enter)="onSelect($event)"
      (click)="onSelect($event)"
      (contextmenu)="onContextMenu($event)"
      class="cursor-pointer truncate"
      tabindex="0"
    >
      <div class="flex items-center space-x-2 truncate">
        <div [ngClass]="{ radio: true, active: entry.selected }"></div>
        <span class="truncate"> {{ entry | defaultEntityTranslate: 'project':'project.none' }} </span>
      </div>
    </mat-list-item>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" class="!ml-auto">
      <div *ngIf="entry.color as color" class="project-color-dot" [style.backgroundColor]="color"></div>
      <mat-icon
        *ngIf="isPinned$ | async"
        inline
        [matTooltip]="'project.favourite' | translate"
        class="bg-accent-2 !p-1 rounded-circle"
        color="accent"
        >star</mat-icon
      >
      <div class="flex flex-row flex-shrink-0 truncate" *ngIf="!entry.completed && !entry.useAsDefault">
        <div
          class="px-2.5 h-8 flex flex-row items-center space-x-0.5 cursor-pointer mat-bg-3-hover text-sm rounded"
          (click)="!tasksLoading && toggleTasks()"
        >
          <ng-container *ngIf="{ count: (tasks$count | async) } as task">
            <span>{{ task.count }} {{ 'task.plural' | translate }}</span>
          </ng-container>
          <ng-container *ngIf="!tasksLoading; else loadingTasksIndicator">
            <mat-icon inline class="!leading-none !h-initial" [ngClass]="{ '!rotate-180': showTasks.value }"
              >keyboard_arrow_down</mat-icon
            >
          </ng-container>
        </div>
      </div>
      <button mat-icon-button class="!-ml-2" *ngIf="onContextMenu" (click)="onContextMenu($event)" tabindex="-1">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </div>
  <div class="flex flex-col mat-bg-3 mx-1.5 my-2 py-2.5 rounded" *ngIf="showTasks.value$ | async" @matScale>
    <div
      [style.height.px]="tasksListHeight | async"
      *ngIf="(tasks$count | async) > 0; else noTasksFound"
      class="scroll-y"
    >
      <mat-list-item
        matRippleDisabled
        *ngFor="let task of tasks$ | async; trackBy: trackId"
        class="cursor-pointer truncate mat-bg-2-hover"
        (click)="onSelectTask($event, task)"
        tabindex="0"
      >
        <div class="flex items-center space-x-2 truncate">
          <div [ngClass]="{ radio: true, active: task.selected }"></div>
          <span class="truncate"> {{ task.name }} </span>
        </div>
      </mat-list-item>
    </div>
    <ng-container *ngIf="entry.currentUserCanEdit">
      <ng-container *ngTemplateOutlet="createTaskButton"></ng-container>
    </ng-container>
  </div>
</div>
<ng-template #loadingTasksIndicator>
  <mat-progress-spinner mode="indeterminate" class="-mt-1" [strokeWidth]="1" [diameter]="12"></mat-progress-spinner>
</ng-template>
<ng-template #noTasksFound>
  <p class="text-center mat-hint text-sm" translate>task.none-found</p>
</ng-template>
<ng-template #createTaskButton>
  <div class="mt-2 mx-1.5 flex-shrink-0">
    <button mat-button class="!rounded-lg" fxFlex (click)="createTask($event)">{{ 'task.add' | translate }}</button>
  </div>
</ng-template>
<ng-template #defaultTooltip let-project="project"> {{ project.name }} </ng-template>
