import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import {
  FlexLayoutModule,
  MediaObserver,
  BreakPointRegistry,
  ɵMatchMedia as MatchMedia,
  PrintHook,
  StyleUtils,
} from '@angular/flex-layout';
import { MaterialModule } from '@app/material.module';

import { ShellComponent } from './shell.component';
import { WorkspaceDialogModule } from '@app/shared/dialogs/workspace-dialog/workspace-dialog.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AngularExpansionModule } from '@app/angular-expansion/angular-expansion.module';
import { CustomDirectivesModule } from '@app/_directives/custom-directives.module';
import { FabricBaseIconModule } from '@app/modules/fabric/fabric-base-icon/fabric-base-icon.module';
import { UserMenuModule } from '@app/shared/user-menu/user-menu.module';
import { DateFnsModule } from 'ngx-date-fns';
import { ShellRouteNamePipe } from './shell-route-name.pipe';
import { MatSideSheetModule } from '@app/shared/mat-side-sheet/mat-side-sheet.module';
import { RecordWidgetModule } from '@app/components/record-widget/record-widget.module';
import { ShellRouterLinkDirective } from './shell-router-link.directive';
import { SubscriptionStatusRowModule } from '@app/components/subscription-status-row/subscription-status-row.module';
import { FrillButtonModule } from '@app/components/frill-button/frill-button.module';

@NgModule({
  imports: [
    CommonModule,
    AngularExpansionModule,
    TranslateModule,
    FlexLayoutModule,
    MaterialModule,
    RouterModule,
    AngularSvgIconModule,
    CustomDirectivesModule,
    WorkspaceDialogModule,
    NgxSkeletonLoaderModule,
    FabricBaseIconModule,
    UserMenuModule,
    DateFnsModule,
    RecordWidgetModule,
    FrillButtonModule,
    SubscriptionStatusRowModule,
  ],
  declarations: [ShellComponent, ShellRouteNamePipe, ShellRouterLinkDirective],
  providers: [],
})
export class ShellModule {}
