import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  HostBinding,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { UtilService } from '@app/_services/util.service';
import { differenceInMilliseconds } from 'date-fns/esm';
import { Time } from 'timeghost-api';
import { TimeTrackerTableCardContentDirective } from '../time-tracker-table-card-content.directive';
import { EntryItem } from '../time-tracker-table.component';
import { sum } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-time-tracker-table-card',
  templateUrl: './time-tracker-table-card.component.html',
  styleUrls: ['./time-tracker-table-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {},
})
export class TimeTrackerTableCardComponent implements OnInit {
  @ContentChild(TimeTrackerTableCardContentDirective, { read: TemplateRef }) template: TemplateRef<any>;
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}
  private _data = new BehaviorSubject<EntryItem>(null);

  @Input()
  public get data(): EntryItem {
    return this._data.getValue();
  }
  public set data(v: EntryItem) {
    this._data.next(v);
  }
  readonly data$ = this._data.asObservable();
  readonly data$stats = this._data.asObservable().pipe(
    map((entry) => {
      const times = entry.times
        .filter((x) => x.min && x.max)
        .map((x) => x.times)
        .reduce((l, r) => l.concat(r), <Time[]>[]);
      const timeSum = times.filter((x) => x.timeDiff > 0).reduce((l, r) => (l += ~~r.timeDiff), 0);
      const billable = times.filter((x) => x.timeDiff > 0 && x.billable).reduce((l, r) => (l += ~~r.timeDiff), 0);
      const nonBillable = times.filter((x) => x.timeDiff > 0 && !x.billable).reduce((l, r) => (l += ~~r.timeDiff), 0);
      const billablePercentage = (billable / timeSum) * 100;
      return {
        billable,
        billablePercentage,
        billablePercentageFormat: billablePercentage.toFixed(0),
        nonBillable,
        nonBillablePercentage: (nonBillable / timeSum) * 100,
        timeSum,
      };
    })
  );
}
