import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackDialogComponent } from './feedback-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from '@app/shared/loader/loader.module';
import { MaterialModule } from '@app/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [FeedbackDialogComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    LoaderModule,
    TranslateModule,
  ],
  exports: [FeedbackDialogComponent],
})
export class FeedbackDialogModule {}
