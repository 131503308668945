import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DayPickerPopoverComponent } from './day-picker-popover.component';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [DayPickerPopoverComponent],
  imports: [CommonModule, SatPopoverModule, MatCardModule, FlexLayoutModule, MatDatepickerModule],
  exports: [DayPickerPopoverComponent],
})
export class DayPickerPopoverModule {}
