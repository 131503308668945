import { ComponentType } from '@angular/cdk/portal';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ensureClassIsDecorated } from './ensure-class-decorator';
import { translateArgs } from './translate-mutator';
const OBSERVABLE_FORM_ERROR_PROPNAME = '__formcontrol_observables';
export function ObserveFormGroupErrors(): ClassDecorator {
  return (_type: any) => {
    (<T>(type: ComponentType<T>): void => {
      type.prototype[OBSERVABLE_FORM_ERROR_PROPNAME] = {};
      type.prototype.__formcontrol_observableKill = new Subject<void>();
      type.prototype.ngOnDestroy = (function (ngOnDestroy: (() => void) | null | undefined) {
        return function (this: any) {
          ngOnDestroy && ngOnDestroy.call(this);
          const destroyNotify: Subject<void> = type.prototype.__formcontrol_observableKill;
          destroyNotify.next();
          destroyNotify.complete();
          type.prototype[OBSERVABLE_FORM_ERROR_PROPNAME] = {};
        };
      })(type.prototype.ngOnDestroy);
    })(_type);
  };
}
export function useFormErrorObservable<T>(instance: T) {
  ensureClassIsDecorated(instance, OBSERVABLE_FORM_ERROR_PROPNAME);
  return (
    keyName: string,
    func: () => AbstractControl,
    translations: { [key: string]: (errors: any, control: AbstractControl) => ControlErrorBag },
    translate: typeof TranslateService.prototype.instant
  ): Observable<ControlErrorBag> | Observable<ControlErrorBag[]> => {
    if (!keyName) {
      return combineLatest<ControlErrorBag[]>(Object.values(instance[OBSERVABLE_FORM_ERROR_PROPNAME]));
    }
    if (instance[OBSERVABLE_FORM_ERROR_PROPNAME][keyName]) return instance[OBSERVABLE_FORM_ERROR_PROPNAME][keyName];
    return (instance[OBSERVABLE_FORM_ERROR_PROPNAME][keyName] = createErrorObservable(
      func,
      translations,
      translate
    ).pipe(takeUntil(instance['__formcontrol_observableKill'])));
  };
}
export function createErrorFunction(
  func: () => AbstractControl,
  translations: {
    [key: string]: (errors: any, control: AbstractControl) => ControlErrorBag;
  },
  translate: typeof TranslateService.prototype.instant
): ControlErrorBag {
  const control = func();
  const x = control.errors;
  if (!x) return null;
  const [key, translateByKey] = Object.entries(translations).find(([_key]) => !!x[_key]);
  const value: ControlErrorBag = translateByKey(x[key], control);
  if (value && translate && typeof value === 'object' && value?.args && Object.keys(value.args).length > 0)
    value.args = translateArgs(value.args, translate) as typeof value.args;

  return value;
}
export function createErrorObservable(
  func: () => AbstractControl,
  translations: {
    [key: string]: (errors: any, control: AbstractControl) => ControlErrorBag;
  },
  translate: typeof TranslateService.prototype.instant
): Observable<ControlErrorBag> {
  const control = func();
  return control.statusChanges.pipe(
    map(() => control.errors),
    map((x) => {
      if (!x) return null;
      const [key, translateByKey] = Object.entries(translations).find(([_key]) => !!x[_key]);
      return translateByKey(x[key], control);
    }),
    map((x: ControlErrorBag) => {
      if (x && translate && typeof x === 'object' && x?.args && Object.keys(x.args).length > 0)
        x.args = translateArgs(x.args, translate) as typeof x.args;
      return x;
    })
  );
}

export interface ControlErrorBag {
  content: string;
  args?: {
    [key: string]: any;
  };
}
