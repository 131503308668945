import { HostListener, NgModule } from '@angular/core';
import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { MatMenuTrigger, _MatMenuBase as _MatMenu } from '@angular/material/menu';

@Directive({
  selector: '[matMenuHover]',
})
export class HoverMatMenuDirective implements OnInit {
  isInHoverBlock = false;

  constructor(private el: ElementRef, private hoverTrigger: MatMenuTrigger) {}

  ngOnInit() {
    this.el.nativeElement.addEventListener('mouseenter', this.onMouseEnter.bind(this));
    this.el.nativeElement.addEventListener('mouseleave', this.onMouseLeave.bind(this));
  }
  onMouseEnter() {
    this.setHoverState(true);
    this.hoverTrigger.openMenu();

    // @ts-ignore
    const openMenu = this.hoverTrigger.menu._elementRef.nativeElement;
    if (!openMenu) {
      this.hoverTrigger.closeMenu();
      return;
    }
    openMenu.addEventListener('mouseenter', () => {
      this.setHoverState(true);
    });
    openMenu.addEventListener('mouseleave', () => {
      this.setHoverState(false);
    });
  }

  onMouseLeave() {
    // this.setHoverState(false);
  }

  private setHoverState(isInBlock: boolean) {
    this.isInHoverBlock = isInBlock;
    if (!isInBlock) {
      this.checkHover();
    }
  }

  private checkHover() {
    setTimeout(() => {
      if (!this.isInHoverBlock && this.hoverTrigger.menuOpen) {
        this.hoverTrigger.closeMenu();
      }
    }, 50);
  }
}

@NgModule({
  declarations: [HoverMatMenuDirective],
  exports: [HoverMatMenuDirective],
})
export class HoverMatMenuModule {}
