<app-loader [isActive]="this.isLoading$ | async" [isAfterViewInit]="false" [enableCircle]="true"></app-loader>
<h1 matDialogTitle>
  <div fxLayout="row" fxLayoutAlign="start center">
    <div>{{ 'time.update' | translate }}</div>
    <div fxFlex></div>
    <button mat-icon-button matDialogClose tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</h1>
<mat-dialog-content cdkTrapFocus>
  <form (submit)="$event.preventDefault()" [formGroup]="this.group" *ngIf="this.group as grp">
    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
      <mat-form-field>
        <mat-label>{{ 'time.name' | translate }}</mat-label>
        <input type="text" matInput formControlName="name" cdkInitialFocus />
      </mat-form-field>
      <div class="flex flex-col space-y-0">
        <mat-form-field (click)="$event.preventDefault(); this.openProjectPicker()" class="cursor-pointer">
          <mat-label>{{ 'Project' | translate }}</mat-label>
          <input
            class="cursor-pointer"
            type="text"
            matInput
            readonly
            [value]="
              grp.value.project?.name ? (grp.value.project | defaultEntityTranslate: 'project':'project.none') : null
            "
          />
          <button
            *ngIf="grp.value.project && this.defaultProject.id !== grp.value.project.id"
            mat-icon-button
            matSuffix
            (click)="$event.preventDefault(); $event.stopPropagation(); resetProject()"
          >
            <mat-icon>clear</mat-icon>
          </button>
          <mat-error *ngIf="!grp.value.project"
            >{{ 'errors.required' | translate: { field: 'Project' | translate } }} *</mat-error
          >
        </mat-form-field>
        <div
          *ngIf="(grp.value.project | projectProxy)?.description as desc"
          class="dark:bg-black/20 bg-neutral-800/5 rounded px-2.5 py-3 text-sm mb-4"
        >
          {{ desc }}
        </div>
      </div>
      <ng-container *ngIf="grp.value.project?.tags?.length">
        <div class="flex flex-wrap gap-2 -mt-4">
          <div
            class="mbg-card rounded-full px-2.5 h-6 cursor-default text-base leading-6 select-none pointer-events-none"
            *ngFor="let item of grp.value.project?.tags; trackBy: trackId"
          >
            {{ item.name }}
          </div>
        </div>
      </ng-container>
      <mat-form-field
        class="w-full"
        (click)="$event.preventDefault(); this.openTaskPicker()"
        [class.cursor-pointer]="!taskDisabled"
      >
        <mat-label>{{ 'Task' | translate }}</mat-label>
        <input
          [class.cursor-pointer]="!taskDisabled"
          type="text"
          matInput
          readonly
          [value]="grp.value.task?.name"
          [disabled]="taskDisabled"
          #taskPickerInput
        />
        <button
          *ngIf="grp.value.task"
          mat-icon-button
          matSuffix
          [disabled]="taskPickerInput.disabled"
          (click)="$event.preventDefault(); $event.stopPropagation(); grp.patchValue({ task: null })"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>
      <div class="cursor-pointer w-full flex flex-col" (click)="$event.preventDefault(); this.openTagsPicker()">
        <mat-form-field class="pointer-events-none">
          <mat-label>{{ 'tag.plural' | translate }}</mat-label>
          <input
            class="cursor-pointer"
            type="text"
            matInput
            readonly
            [value]="grp.value.tags | mapProp: 'name' | joinString: ', '"
          />
          <button
            *ngIf="grp.value.tags && grp.value.tags.length > 0"
            mat-icon-button
            matSuffix
            (click)="$event.preventDefault(); $event.stopPropagation(); grp.controls.tags.setValue([])"
          >
            <mat-icon>clear</mat-icon>
          </button>
          <mat-error *ngIf="!grp.value.tags"
            >{{ 'errors.required' | translate: { field: 'Tags' | translate } }} *</mat-error
          >
        </mat-form-field>
      </div>
      <div
        class="w-full flex flex-col cursor-pointer"
        (click)="$event.preventDefault(); openUserPicker()"
        *ngIf="workspace$isAdmin.value$ | async"
      >
        <mat-form-field class="form-control-interactive">
          <mat-label>{{ 'User' | translate }}</mat-label>
          <div *ngIf="group.value.user as user" class="flex items-center space-x-1.5 -mb-16px">
            <app-user-avatar
              size="40px"
              class="rounded-full"
              [userId]="user.id"
              defaultImageSource="/assets/img/default.png"
            ></app-user-avatar>
            <div class="flex flex-col leading-none flex-auto">
              <span>{{ user.name }}</span>
              <span class="text-sm mat-hint">{{ user.email }}</span>
            </div>
            <button
              *ngIf="postDiffUser$ | async"
              mat-icon-button
              (click)="$event.preventDefault(); $event.stopPropagation(); patchValue({ user: null })"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </div>
          <input matInput [value]="group.value.user" readonly class="!pointer-events-none" />
        </mat-form-field>
      </div>
      <div class="cursor-pointer w-full flex flex-col" (click)="$event.preventDefault(); openCalPicker()">
        <mat-form-field class="pointer-events-none">
          <mat-label>{{ 'timer.date' | translate }}</mat-label>
          <input matInput [value]="grp.value.date | formatDate" class="!pointer-events-none" />
        </mat-form-field>
      </div>
      <div class="flex items-center space-x-2">
        <ng-container
          *ngTemplateOutlet="
            mode === 'range'
              ? rangeModeTemplate
              : mode === 'range_optional'
              ? stateVisibleMode
                ? rangeModeTemplate
                : durationModeTemplate
              : mode === 'duration'
              ? durationModeTemplate
              : null
          "
        ></ng-container>
        <ng-container *ngIf="mode === 'range_optional'">
          <button
            mat-icon-button
            [matTooltip]="'timer.time.changeInputMode' | translate"
            [matTooltipOptions]="{ hideOnClick: false }"
            (click)="stateVisibleMode = !stateVisibleMode"
          >
            <mat-icon>swap_horiz</mat-icon>
          </button>
        </ng-container>
      </div>
      <mat-checkbox formControlName="billable" class="mb-4" color="primary">{{
        'Billable_Alt' | translate
      }}</mat-checkbox>
    </div>
    <ng-template #rangeModeTemplate>
      <div class="flex flex-col flex-1">
        <div class="flex flex-row items-center justify-between">
          <app-time-input
            fxFlex="0 0 74px"
            [inputPlaceholder]="'timer.time.start' | translate"
            matInput
            formControlName="start"
            [displayFormat]="false"
            #tStartFormField
            [maxlength]="5"
            elementRef
            #startInpRef="nativeElement"
            (focus)="this.selectInput($event, startInpRef)"
            (keydown.enter)="$event.preventDefault()"
            (contextmenu)="this.onTimeContextMenuTrigger($event, { time: this.group.value.start, prop: 'start' })"
            (click)="$event.stopPropagation(); this.selectInput($event, startInpRef)"
          >
            <ng-template timeInputControl let-control="control" let-inputPlaceholder="inputPlaceholder">
              <mat-form-field fxFill>
                <mat-label translate>timer.time.start</mat-label>
                <input type="text" matInput [placeholder]="inputPlaceholder" [formControl]="control" />
              </mat-form-field>
            </ng-template>
          </app-time-input>
          <span class="tg-seperator mx-2">
            <mat-icon>arrow_forward</mat-icon>
          </span>
          <app-time-input
            fxFlex="0 0 74px"
            [inputPlaceholder]="'timer.time.end' | translate"
            matInput
            formControlName="end"
            [displayFormat]="false"
            #tEndFormField
            [maxlength]="5"
            elementRef
            #endInpRef="nativeElement"
            (focus)="this.selectInput($event, endInpRef)"
            (keydown.enter)="$event.preventDefault()"
            (contextmenu)="this.onTimeContextMenuTrigger($event, { time: this.group.value.end, prop: 'end' })"
            (click)="$event.stopPropagation(); this.selectInput($event, endInpRef)"
          >
            <ng-template timeInputControl let-control="control" let-inputPlaceholder="inputPlaceholder">
              <mat-form-field fxFill>
                <mat-label translate>timer.time.end</mat-label>
                <input type="text" matInput [placeholder]="inputPlaceholder" [formControl]="control" />
              </mat-form-field>
            </ng-template>
          </app-time-input>
        </div>
      </div>
    </ng-template>
    <ng-template #durationModeTemplate>
      <div class="flex flex-col flex-1">
        <tg-duration-input-control formControlName="duration" displayFormat="HH:mm">
          <ng-template
            durationInputControl
            let-control="control"
            let-inputPlaceholder="inputPlaceholder"
            let-name="name"
          >
            <mat-form-field fxFill>
              <mat-label translate>timer.time.duration</mat-label>
              <input type="text" matInput [formControl]="control" />
            </mat-form-field>
          </ng-template>
        </tg-duration-input-control>
      </div>
    </ng-template>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="mat-action-filled">
  <button
    mat-flat-button
    class="mat-action-filled"
    color="primary"
    (click)="this.update()"
    [disabled]="this.group.invalid && !forceUpdate"
  >
    {{ 'time.update' | translate }}
  </button>
</mat-dialog-actions>
<mat-menu #timeContextMenu="matMenu">
  <ng-template matMenuContent let-data>
    <button mat-menu-item (click)="setToNow(data.prop)">{{ 'timeContextMenu.setTo.now' | translate }}</button>
    <button mat-menu-item [matMenuTriggerFor]="timeContextMenuTimeTemplates" [matMenuTriggerData]="{ $implicit: data }">
      {{ 'timeContextMenu.setToMore' | translate }}
    </button>
  </ng-template>
</mat-menu>
<mat-menu #timeContextMenuTimeTemplates="matMenu">
  <ng-template matMenuContent let-data>
    <button mat-menu-item (click)="setMinutesDiff(5, data.prop)">
      {{ 'timeContextMenu.setTo.MinDiffs' | translate: { value: 5 } }}
    </button>
    <button mat-menu-item (click)="setMinutesDiff(10, data.prop)">
      {{ 'timeContextMenu.setTo.MinDiffs' | translate: { value: 10 } }}
    </button>
    <button mat-menu-item (click)="setMinutesDiff(15, data.prop)">
      {{ 'timeContextMenu.setTo.MinDiffs' | translate: { value: 15 } }}
    </button>
    <button mat-menu-item (click)="setMinutesDiff(30, data.prop)">
      {{ 'timeContextMenu.setTo.MinDiffs' | translate: { value: 30 } }}
    </button>
    <button mat-menu-item (click)="setHourDiff(1, data.prop)">
      {{ 'timeContextMenu.setTo.HrDiff' | translate: { value: 1 } }}
    </button>
    <button mat-menu-item (click)="setHourDiff(2, data.prop)">
      {{ 'timeContextMenu.setTo.HrDiffs' | translate: { value: 2 } }}
    </button>
    <button mat-menu-item (click)="setHourDiff(4, data.prop)">
      {{ 'timeContextMenu.setTo.HrDiffs' | translate: { value: 4 } }}
    </button>
    <button mat-menu-item (click)="setWorkDay()">{{ 'timeContextMenu.setTo.1workDay' | translate }}</button>
  </ng-template>
</mat-menu>
<div
  style="visibility: hidden; position: fixed"
  [style.left]="timeContextMenuPosition.x"
  [style.top]="timeContextMenuPosition.y"
  #timeContextMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="timeContextMenu"
></div>
