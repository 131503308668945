import { Injectable, NgModule } from '@angular/core';
import { CommonModule, formatDate } from '@angular/common';
import { MaterialModule } from '@app/material.module';
import { TimeInputModule } from '../time-input/time-input.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomPipesModule } from '@app/_pipes/custom-pipes.module';
import { DateFnsModule } from 'ngx-date-fns';
import { CalendarDateFormatter, CalendarModule, DateAdapter, DateFormatterParams } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MwlDayViewSchedulerModule } from '../mwl-day-view-scheduler/mwl-day-view-scheduler.module';
import { TimeTrackerCalendarStepperCreateDialogComponent } from './time-tracker-calendar-stepper-create-dialog.component';
import { TimeTrackerCalendarCreateDialogModule } from '../time-tracker-calendar-create-dialog/time-tracker-calendar-create-dialog.module';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { PlainStepperModule } from '../plain-stepper/plain-stepper.module';
import { ProjectListComponent } from './project-list/project-list.component';
import { EstimationStatisticsGraphModule } from '../estimation-statistics-graph/estimation-statistics-graph.module';
import { RouterModule } from '@angular/router';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DurationInputControlModule } from '@app/components/duration-input-control/duration-input-control.module';

@NgModule({
  declarations: [TimeTrackerCalendarStepperCreateDialogComponent, ProjectListComponent],
  imports: [
    CommonModule,
    MaterialModule,
    CustomPipesModule,
    FlexLayoutModule,
    TimeInputModule,
    DurationInputControlModule,
    FormsModule,
    ReactiveFormsModule,
    DateFnsModule,
    TranslateModule,
    CdkStepperModule,
    TimeTrackerCalendarCreateDialogModule,
    EstimationStatisticsGraphModule,
    RouterModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    PlainStepperModule,
  ],
})
export class TimeTrackerCalendarStepperCreateDialogModule {}
