import { Directive, Input, OnInit, Optional, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from '@env/environment';
import { IsBetaWindow } from '@env/version';
@Directive({
  selector: '[isBeta]',
})
export class IsBetaDirective implements OnInit {
  @Input()
  isBeta: boolean;
  constructor(private viewContainer: ViewContainerRef, @Optional() private templateRef: TemplateRef<any>) {}
  ngOnInit() {
    this.updateView();
  }
  private updateView() {
    if (environment.production && !IsBetaWindow() && this.isBeta !== false) {
      this.viewContainer.clear();
    } else if (this.templateRef) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
