import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EstimationStatisticsGraphComponent } from './estimation-statistics-graph.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipTippyModule } from '../mat-tooltip-tippy/mat-tooltip-tippy.module';
import { CustomPipesModule } from '@app/_pipes/custom-pipes.module';

@NgModule({
  declarations: [EstimationStatisticsGraphComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatTooltipTippyModule,
    CustomPipesModule,
  ],
  exports: [EstimationStatisticsGraphComponent],
})
export class EstimationStatisticsGraphModule {}
