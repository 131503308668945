import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeTrackerCalendarUpdateDialogComponent } from './time-tracker-calendar-update-dialog.component';
import { MaterialModule } from '@app/material.module';
import { TimeInputModule } from '../time-input/time-input.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TaskPickerDialogModule } from '@app/components/task-picker-dialog/task-picker-dialog.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { UserSinglePickerDialogModule } from '@app/components/user-single-picker-dialog/user-single-picker-dialog.module';
import { DurationInputControlModule } from '@app/components/duration-input-control/duration-input-control.module';

@NgModule({
  declarations: [TimeTrackerCalendarUpdateDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    TimeInputModule,
    DurationInputControlModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TaskPickerDialogModule,
    UserAvatarModule,
    UserSinglePickerDialogModule,
  ],
})
export class TimeTrackerCalendarUpdateDialogModule {}
