import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspaceDialogComponent } from './workspace-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@app/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { WorkspaceChangeConfirmDialogModule } from '../workspace-change-confirm-dialog/workspace-change-confirm-dialog.module';
import { CreateWorkspaceModule } from '../create-workspace/create-workspace.module';
import { UserAvatarModule } from '@app/shared/user-avatar/user-avatar.module';

@NgModule({
  declarations: [WorkspaceDialogComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CreateWorkspaceModule,
    UserAvatarModule,
    WorkspaceChangeConfirmDialogModule,
  ],
  exports: [WorkspaceDialogComponent],
})
export class WorkspaceDialogModule {}
