<div
  #contextMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="taskContextMenu"
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
></div>
<mat-dialog-content>
  <app-loader [isActive]="isLoading" [enableCircle]="true" [width]="20"></app-loader>
  <div fxLayout="column" class="searchWrapper flex-shrink-0" fxLayoutAlign="stretch stretch">
    <div class="searchInput px-2" fxLayout="column" fxLayoutAlign="center start">
      <mat-form-field appearance="fill" class="w-full mat-form-field-compact mat-form-field-no-hint">
        <input type="text" ignoreAutocomplete matInput [formControl]="searchinput" cdkFocusInitial tabindex="0" />
        <mat-icon inline matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <cdk-virtual-scroll-viewport itemSize="40" [minBufferPx]="300" [maxBufferPx]="800" [style.height.px]="380">
      <mat-list dense role="listbox" class="pb-8">
        <ng-container
          *cdkVirtualFor="let entry of this.data$filtered | async; let last = last; let first = first; trackBy: trackId"
        >
          <tg-task-picker-entry [entry]="entry" (select)="onSelect($event)">
            <ng-template #entryMenu>
              <button
                mat-icon-button
                class="!-ml-2"
                [matMenuTriggerFor]="taskContextMenu"
                [matMenuTriggerData]="{ $implicit: entry }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </ng-template>
          </tg-task-picker-entry>
        </ng-container>
      </mat-list>
    </cdk-virtual-scroll-viewport>
  </div>
</mat-dialog-content>
<mat-dialog-actions
  class="mat-action-filled"
  [matTooltip]="'workspace.errors.admin-create-task' | translate"
  [matTooltipDisabled]="this.workspace$canManageTask.value$ | async"
>
  <button
    *ngIf="workspace$canManageTask.value$ | async"
    mat-flat-button
    color="primary"
    (click)="createTaskDialog()"
    [disabled]="isLoading"
    class="mat-button-filled"
  >
    {{ 'task.add' | translate }}
  </button>
</mat-dialog-actions>
<mat-menu #taskContextMenu="matMenu">
  <ng-template matMenuContent let-task>
    <div fxLayout="column" fxLayoutGap="4px">
      <a mat-menu-item *ngIf="task.project?.id" [routerLink]="['/settings/projects', task.project.id]" matDialogClose>
        <mat-icon inline> open_in_new </mat-icon> {{ 'project.open' | translate }}
      </a>
    </div>
  </ng-template>
</mat-menu>
