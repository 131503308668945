import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateWorkspaceComponent } from './create-workspace.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { LoaderModule } from '@app/shared/loader/loader.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@NgModule({
  declarations: [CreateWorkspaceComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    LoaderModule,
    TranslateModule,
    NgScrollbarModule,
  ],
  exports: [CreateWorkspaceComponent],
  providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }],
})
export class CreateWorkspaceModule {}
