import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ElRefDirective } from '@app/_directives/el-ref/el-ref.directive';
import { clampDay } from '@app/_helpers/date-fns';
import { CustomValidators } from '@app/_validators/custom-validators';
import { TaskPickerDialogComponent } from '@app/components/task-picker-dialog/task-picker-dialog.component';
import { CalendarEvent } from 'calendar-utils';
import {
  addMinutes,
  addSeconds,
  format as formatString,
  intervalToDuration,
  isValid,
  parse as parseFromString,
  startOfDay,
} from 'date-fns/esm';
import { addHours, parse as parsefp, subHours, subMinutes } from 'date-fns/esm/fp';
import { flow } from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map } from 'rxjs/operators';
import {
  Logger,
  MyTimesQuery,
  MyTimesService,
  Project,
  ProjectsQuery,
  Tag,
  TagType,
  Task,
  Time,
  UserSettingsQuery,
  Workspace,
} from 'timeghost-api';

import {
  ClientProjectDialogData,
  ClientProjectPickerDialogComponent,
} from '../dialogs/client-project-picker-dialog/client-project-picker-dialog.component';
import { TagDialogData, TagPickerDialogComponent } from '../dialogs/tag-picker-dialog/tag-picker-dialog.component';
import { RecordToolbarService } from '../record-toolbar/record-toolbar.service';
import { TimeDatePickerConfig } from '../time-date-picker/time-date-picker-config';
import { TimeDatePickerComponent } from '../time-date-picker/time-date-picker.component';
import {
  UserSinglePickerDialogComponent,
  UserSinglePickerDialogData,
} from '@app/components/user-single-picker-dialog/user-single-picker-dialog.component';
import { DEFAULT_PERMISSION_GROUPS, fromRxValue, hasPermission } from '@app/_helpers/utils';
import {
  parseDurationAsFormat,
  parseDurationSeconds,
} from '@app/components/duration-input-control/duration-input-utils';

const log = new Logger('TimeTrackerCalendarUpdateDialogComponent');

@Component({
  selector: 'app-time-tracker-calendar-update-dialog',
  templateUrl: './time-tracker-calendar-update-dialog.component.html',
  styleUrls: ['./time-tracker-calendar-update-dialog.component.scss'],
})
export class TimeTrackerCalendarUpdateDialogComponent implements OnInit {
  private _isLoading = new BehaviorSubject<boolean>(false);
  readonly isLoading$ = this._isLoading.asObservable().pipe(distinctUntilChanged());
  get isLoading() {
    return this._isLoading.getValue();
  }
  set isLoading(val: boolean) {
    this._isLoading.next(val);
  }
  group = new FormGroup(
    {
      name: new FormControl('', [CustomValidators.minLength(3)]),
      project: new FormControl<Project>(null),
      task: new FormControl<Task>(null),
      billable: new FormControl(false),
      date: new FormControl(null, [Validators.required]),
      start: new FormControl(null),
      end: new FormControl(null),
      duration: new FormControl(null),
      tags: new FormControl<Tag[]>([]),
      user: new FormControl<Workspace['users'][0]>(null),
    },
    [CustomValidators.timeDiffCheck('start', 'end')]
  );
  defaultProject: Project;
  settings$allowFutureTimeTracking = this.userSettingsQuery
    .select((x) => x.workspace.settings)
    .pipe(map((x) => (x.allowFutureTimeTracking ? null : new Date())));
  constructor(
    private ref: MatDialogRef<TimeTrackerCalendarUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: CalendarEvent<{ time: Time; oldStart?: Date; oldEnd?: Date; forceUpdate?: boolean }>,
    private myTimes: MyTimesService,
    private myTimesQuery: MyTimesQuery,
    private projectsQuery: ProjectsQuery,
    private media: MediaObserver,
    private userSettingsQuery: UserSettingsQuery,
    private recordService: RecordToolbarService,
    private dialog: MatDialog
  ) {
    this.defaultProject = this.projectsQuery.getAll().find((x) => x.useAsDefault === true);
  }
  readonly workspace$isAdmin = fromRxValue(
    this.userSettingsQuery.select((x) => hasPermission(DEFAULT_PERMISSION_GROUPS.Admin, x))
  );
  readonly postDiffUser$ = combineLatest([
    this.group.valueChanges.pipe(
      map((x) => x?.user?.id),
      distinctUntilChanged()
    ),
    this.userSettingsQuery.select(),
  ]).pipe(map(([uid, user]) => uid && uid !== user.id));
  get forceUpdate() {
    return this.data?.meta?.forceUpdate;
  }
  get isMobile() {
    return this.media.isActive(['xs', 'sm']);
  }
  get timeZone() {
    return this.userSettingsQuery.getValue().settings.timeZone;
  }
  get mode() {
    return this.userSettingsQuery.getValue()?.workspace.settings?.timesMode;
  }
  get stateVisibleMode() {
    return this.recordService.group.value.inputMode === 'range';
  }
  set stateVisibleMode(v: boolean) {
    if (this.userSettingsQuery.getValue().workspace?.settings?.timesMode === 'range_optional') {
      const inputMode = v ? 'range' : 'duration';
      this.recordService.group.patchValue({
        inputMode,
      });
    }
  }
  refreshGroup(data: typeof this.data) {
    const mode = this.mode;
    const start = data.start,
      end = data.end;
    this.patchValue(
      {
        name: data.title,
        start: formatString(start, 'HH:mm'),
        end: formatString(end, 'HH:mm'),
        tags: (data.meta.time.tags || []) as Tag[],
        date: new Date(data.start.getTime()),
        duration: parseDurationAsFormat(data.meta.time.timeDiff / 60),
        project: data.meta.time.project as Project,
        task: data.meta.time.task as Task,
        billable: data.meta.time.billable,
      },
      { emitEvent: false }
    );
    this.group.setValidators([
      CustomValidators.timeDiffCheck('start', 'end'),
      CustomValidators.mustChange({
        ...this.group.value,
        date: data.meta.oldStart ? new Date(data.meta.oldStart.getTime()) : new Date(data.start.getTime()),
        start: data.meta.oldStart ? formatString(data.meta.oldStart, 'HH:mm') : formatString(start, 'HH:mm'),
        end: data.meta.oldStart ? formatString(data.meta.oldEnd, 'HH:mm') : formatString(end, 'HH:mm'),
      }),
    ]);
    if (mode === 'duration') {
      this.group.controls.duration.setValidators([Validators.required, CustomValidators.validDateFormat('HH:mm')]);
    } else if (mode !== 'range') {
      this.group.controls.start.clearValidators(),
        this.group.controls.end.clearValidators(),
        this.group.controls.duration.clearValidators();
    } else {
      this.group.controls.start.setValidators([Validators.required, CustomValidators.validDateFormat('HH:mm')]),
        this.group.controls.end.setValidators([Validators.required, CustomValidators.validDateFormat('HH:mm')]),
        this.group.controls.duration.setValidators([Validators.required, CustomValidators.validDateFormat('HH:mm')]);
    }
    this.group.updateValueAndValidity();
  }
  ngOnInit(): void {
    this.ref.updateSize('500px');
    this.ref.addPanelClass(['mat-dialog-vanilla', 'mat-dialog-relative']);
    this.refreshGroup(this.data);
  }
  openUserPicker() {
    if (!this.workspace$isAdmin.value) return;
    return this.dialog
      .open(UserSinglePickerDialogComponent, {
        data: <UserSinglePickerDialogData>{
          selected: this.group.value?.user,
          filter: ({ selected, ...x }) => (x.has_license && !x.removed) || selected,
        },
      })
      .afterClosed()
      .subscribe((user) => {
        if (user)
          this.patchValue({
            user,
          });
      });
  }
  patchValue(data: Partial<typeof this.group.value> = {}, options?: Parameters<typeof this.group.patchValue>[1]) {
    const userId = this.data.meta?.time?.user?.id;
    const user = this.userSettingsQuery.getValue();
    if (data.user === null && userId)
      data.user =
        user.workspace.users.find((x) => x.id === userId) || user.workspace.users.find((x) => x.id === user.id);
    return this.group.patchValue(data, options);
  }
  resetProject() {
    this.patchValue({
      project: this.defaultProject,
      task: null,
    });
  }
  openProjectPicker() {
    return this.dialog
      .open(ClientProjectPickerDialogComponent, {
        data: <ClientProjectDialogData>{
          data: {
            selectedProject: this.group.value.project,
            selectedTask: this.group.value.task,
            closeOnRemove: true,
            canToggle: true,
            defaultProject: this.defaultProject,
          },
        },
      })
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe(([x, t]: [Project, Task]) => {
        const newProject = x ?? this.defaultProject;
        this.patchValue({
          project: newProject,
          task: newProject?.useAsDefault ? null : t,
          billable: x.billable ? true : this.group.value.billable,
        });
      });
  }
  openTagsPicker() {
    return this.dialog
      .open(TagPickerDialogComponent, {
        data: <TagDialogData>{
          data: {
            SelectedTags: this.group.value.tags,
            canToggle: true,
            type: TagType.Time,
            allowTypeModify: false,
          },
        },
      })
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe((x) => this.patchValue({ tags: x }));
  }
  openTaskPicker() {
    const { project, task } = this.group.value;
    if (!project || project.useAsDefault || project.completed) return;
    return this.dialog
      .open(TaskPickerDialogComponent, {
        data: {
          project: project,
          task: task,
        },
      })
      .afterClosed()
      .subscribe((task: Task & { selected: boolean }) => {
        if (task) this.patchValue({ task: task.selected ? task : null });
      });
  }
  openCalPicker() {
    return this.dialog
      .open(TimeDatePickerComponent, {
        data: <TimeDatePickerConfig>{
          selectedDate: new Date(this.group.value.date),
        },
      })
      .afterClosed()
      .pipe(filter((x) => x && isValid(x)))
      .subscribe((x) => {
        this.patchValue({
          date: x,
        });
      });
  }
  isProjectDefault(id: string) {
    return this.defaultProject.id === id;
  }
  get taskDisabled() {
    return !this.group.value.project || this.group.value.project?.useAsDefault || this.group.value.project?.completed;
  }
  update() {
    if (this.group.invalid && !this.forceUpdate) return;
    const val = this.group.getRawValue();
    const mode = this.mode;
    this.isLoading = true;
    const start: Date = parseFromString(this.stateVisibleMode ? val.start : '00:00', 'HH:mm', val.date),
      end: Date = parseFromString(this.stateVisibleMode ? val.end : '00:00', 'HH:mm', val.date);
    const inputMode = this.stateVisibleMode ? 'range' : 'duration';
    const relativeDateStart = startOfDay(start.getTime());
    const time = this.data.meta.time;
    const userId = this.userSettingsQuery.getValue().id;
    const nextDuration =
      inputMode === 'duration'
        ? parseDurationSeconds(val.duration)
        : (({ hours, minutes }) => hours * 60 + minutes)(intervalToDuration({ start, end })) * 60;
    return this.myTimes
      .update({
        ...time,
        name: val.name,
        billable: !!val.billable,
        project: val.project || this.defaultProject,
        task: val.task,
        tags: val.tags,
        inputMode,
        ...(mode === 'range_optional'
          ? this.stateVisibleMode
            ? {
                start: start.toISOString(),
                end: end.toISOString(),
                timeDiff: 0,
                inputMode,
              }
            : {
                start: relativeDateStart.toISOString(),
                end: relativeDateStart.toISOString(),
                timeDiff: nextDuration,
                inputMode,
              }
          : mode === 'duration'
          ? {
              timeDiff: nextDuration,
              start: relativeDateStart.toISOString(),
              end: relativeDateStart.toISOString(),
            }
          : { start: start.toISOString(), end: end.toISOString() }),
        recordType: 'manual',
        ...(val.user && time.user?.id !== val.user?.id ? { user: { id: val.user.id, name: val.user.name } } : {}),
      })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (x) => {
          this.myTimesQuery.__store__.remove(x.filter((y) => y && y.user?.id !== userId).map((y) => y.id));
          this.ref.close(x);
        },
        error: (err) => {
          this.recordService.handleError(err);
        },
      });
  }
  selectInput(ev: Event, timeInput: ElRefDirective) {
    const el: HTMLElement = timeInput.elementRef.nativeElement;
    if (!el) {
      return;
    }
    const input = el.querySelector('input');
    if (input) {
      ev.preventDefault();
      input.select();
    }
  }

  @ViewChild('timeContextMenuTrigger', { static: true })
  timeContextMenu: MatMenuTrigger;

  timeContextMenuPosition = { x: '0px', y: '0px' };

  onTimeContextMenuTrigger(event: MouseEvent, item: { time: Time; prop: string }) {
    event.stopPropagation(), event.preventDefault();
    this.timeContextMenuPosition.x = event.clientX + 'px';
    this.timeContextMenuPosition.y = event.clientY + 'px';
    this.timeContextMenu.menuData = { $implicit: item };
    this.timeContextMenu.menu.focusFirstItem('mouse');
    this.timeContextMenu.openMenu();
  }
  setToNow(prop: 'start' | 'end') {
    return this.group.controls[prop].patchValue(flow((date) => formatString(date, 'HH:mm'))(new Date()));
  }
  setMinutesDiff(minutes: number, prop: 'start' | 'end') {
    switch (prop) {
      case 'start':
        return this.group.controls[prop].patchValue(
          flow(parsefp(new Date(), 'HH:mm'), subMinutes(minutes), clampDay(new Date()), (date) =>
            formatString(date, 'HH:mm')
          )(this.group.value.end as string)
        );
      case 'end':
        return this.group.controls[prop].patchValue(
          flow(
            parsefp(new Date(), 'HH:mm'),
            (date) => addMinutes(date, minutes),
            clampDay(new Date()),
            (date) => formatString(date, 'HH:mm')
          )(this.group.value.start as string)
        );
    }
  }
  setHourDiff(hours: number, prop: 'start' | 'end') {
    switch (prop) {
      case 'start':
        return this.group.controls[prop].patchValue(
          flow(parsefp(new Date(), 'HH:mm'), subHours(hours), clampDay(new Date()), (date) =>
            formatString(date, 'HH:mm')
          )(this.group.value.end as string)
        );
      case 'end':
        return this.group.controls[prop].patchValue(
          flow(parsefp(new Date(), 'HH:mm'), addHours(hours), clampDay(new Date()), (date) =>
            formatString(date, 'HH:mm')
          )(this.group.value.start as string)
        );
    }
  }
  setWorkDay() {
    return this.patchValue({
      start: '09:00',
      end: '17:00',
    });
  }
}
