import { ConfigurableFocusTrapFactory, FocusTrapFactory } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewContainerRef } from '@angular/core';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MatCalendarUserEvent,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
} from '@angular/material/datepicker';
import { DateRangeSelectionStrategy } from '@app/services/mat-calendar/date-range-selection-strategy.service';
import { SatPopover, SatPopoverAnchoringService } from '@ncstate/sat-popover';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { startOfWeek, endOfWeek, isThursday } from 'date-fns';
import { endOfDay, isSameDay, startOfDay } from 'date-fns/esm';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ApplicationSettingsQuery } from 'timeghost-api';

@UntilDestroy()
@Component({
  selector: 'tg-day-picker-popover',
  templateUrl: './day-picker-popover.component.html',
  styleUrls: ['./day-picker-popover.component.scss'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DateRangeSelectionStrategy,
    },
  ],
})
export class DayPickerPopoverComponent extends SatPopover implements OnInit {
  constructor(
    private appSettings: ApplicationSettingsQuery,
    _focusTrapFactory: ConfigurableFocusTrapFactory,
    _anchoringService: SatPopoverAnchoringService,
    _viewContainerRef: ViewContainerRef,
    @Optional() @Inject(DOCUMENT) _document: any,
    @Inject(MAT_DATE_RANGE_SELECTION_STRATEGY)
    private pickerStrategy: DateRangeSelectionStrategy<Date>
  ) {
    super(_focusTrapFactory, _anchoringService, _viewContainerRef, '200ms cubic-bezier(0.25, 0.8, 0.25, 1)', _document);
    this.hasBackdrop = true;
  }
  private setStrategy(val: any) {
    this.pickerStrategy.setRangeType(
      {
        0: 'day',
        1: 'week',
      }[val] ?? 'day'
    );
  }
  ngOnInit(): void {
    this.setStrategy(this.appSettings.getValue()?.config?.feedView);
  }
  @Input()
  set rangeType(val: 'week' | 'day') {
    this.pickerStrategy.setRangeType(val);
  }
  private _viewDate = new BehaviorSubject<DateRange<Date>>(new DateRange<Date>(null, null));
  readonly viewDate$ = this._viewDate.asObservable().pipe(distinctUntilChanged());
  get viewDate() {
    return this._viewDate.getValue();
  }
  @Input()
  set viewDate(val: DateRange<Date>) {
    const newVal = new DateRange<Date>(val.start ? startOfDay(val.start) : null, val.end ? endOfDay(val.end) : null);
    if (JSON.stringify(newVal) === JSON.stringify(this.viewDate)) return;
    this._viewDate.next(newVal);
    // if (newVal.start && newVal.end) this.viewDateChange.emit(newVal);
  }
  @Input()
  max: Date;
  @Input()
  min: Date;

  @Output()
  viewDateChange = new EventEmitter<DateRange<Date>>(true);
  selectedChange(ev: MatCalendarUserEvent<Date>) {
    this.viewDate = this.pickerStrategy.selectionFinished(ev.value, this.viewDate);
    if (this.viewDate.start && this.viewDate.end) {
      this.viewDateChange.emit(this.viewDate);
      this.close(this.viewDate);
    }
  }
}
