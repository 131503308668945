/*
 * This module imports and re-exports all Angular Material modules for convenience,
 * so only 1 module import is needed in your feature modules.
 * See https://material.angular.io/guide/getting-started#step-3-import-the-component-modules.
 *
 * To optimize your production builds, you should only import the components used in your app.
 */

import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  MatCommonModule,
  MatLineModule,
  MatOptionModule,
  MatPseudoCheckboxModule,
  MatRippleModule,
  MAT_DATE_FORMATS,
  MatNativeDateModule,
  MAT_NATIVE_DATE_FORMATS,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  MatFormFieldDefaultOptions,
  MatFormFieldModule,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconLocation, MatIconModule, MAT_ICON_LOCATION } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuDefaultOptions, MatMenuModule, MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { LoaderModule } from './shared/loader/loader.module';
import { MatRightSideSheetModule } from './shared/mat-right-side-sheet/mat-right-side-sheet.module';
import { MatSideSheetModule } from './shared/mat-side-sheet/mat-side-sheet.module';
import { MatTooltipTippyModule } from './shared/mat-tooltip-tippy/mat-tooltip-tippy.module';
import { TgFormsModule } from '@app/components/tg-forms/tg-forms.module';
import { CustomDirectivesModule } from './_directives/custom-directives.module';
import { CustomPipesModule } from './_pipes/custom-pipes.module';
import { HoverMatMenuModule } from './components/hover-mat-menu';
import { MatPaginatorI18NService } from './services/mat-paginator/mat-paginator-i18-n.service';
import { AngularExpansionModule } from './angular-expansion/angular-expansion.module';

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatCommonModule,
    // dt
    MatDatepickerModule,
    MatNativeDateModule,
    // MatMomentDateModule,
    // dt end
    MatDialogModule,
    MatSideSheetModule,
    MatRightSideSheetModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatLineModule,
    MatListModule,
    MatMenuModule,
    HoverMatMenuModule,
    MatOptionModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatPseudoCheckboxModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipTippyModule,
    LoaderModule,
    SatPopoverModule,
    AngularExpansionModule,
    CustomDirectivesModule,
    CustomPipesModule,
    TgFormsModule,
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS }, // MAT_MOMENT_FORMATS or MAT_NATIVE_DATE_FORMATS (date = more rawr performance)
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: <MatMenuDefaultOptions>{
        overlapTrigger: true,
      },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: <MatFormFieldDefaultOptions>{
        appearance: 'standard',
      },
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorI18NService },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: <RippleGlobalOptions>{
        animation: {
          enterDuration: 280,
          exitDuration: 120,
        },
        terminateOnPointerUp: true,
      },
    },
  ],
})
export class MaterialModule {}
