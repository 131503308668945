import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientRefPipe } from './client-ref.pipe';
import { ProjectRefPipe } from './project-ref.pipe';

@NgModule({
  declarations: [ProjectRefPipe, ClientRefPipe],
  imports: [CommonModule],
  exports: [ProjectRefPipe, ClientRefPipe],
})
export class EntityRefPipesModule {}
