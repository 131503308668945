<div class="app-logo-container" [class.app-logo-dark]="this.isDarkMode" *ngIf="this.fullAppLoading" @objectScale>
  <div class="app-logo-inner">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 3909 1001"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xml:space="preserve"
      xmlns:serif="http://www.serif.com/"
      style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2"
    >
      <g class="tg-logo">
        <g transform="matrix(16.8724,0,0,16.8724,0.000158176,-327.509)">
          <path
            d="M17.796,77.383C31.107,81.141 40.157,76.045 45.04,72.307C49.923,68.568 54.785,60.746 53.002,61.285C42.274,64.528 39.846,59.481 40.358,52.251C40.617,48.599 41.302,43.052 39.793,39.563C38.283,36.074 33.963,32.938 23.703,32.332C11.267,31.597 3.48,37.566 1.726,42.391C-0.028,47.216 -0.239,52.509 0.19,56.619C0.619,60.728 4.485,73.625 17.796,77.383Z"
            style="fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(16.8724,0,0,16.8724,0.000158176,-327.509)" class="tg-logo-eyes">
          <path
            d="M24.668,47.309C24.532,44.564 22.9,42.414 21.022,42.507C19.145,42.6 17.733,44.901 17.869,47.646C18.005,50.391 19.637,52.54 21.515,52.447C23.392,52.354 24.804,50.054 24.668,47.309Z"
            style="fill: white; fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(16.8724,0,0,16.8724,0.000158176,-327.509)" class="tg-logo-eyes">
          <path
            d="M32.419,46.628C32.092,44.132 30.68,42.26 29.265,42.445C27.851,42.63 27.271,44.851 27.598,47.346C27.924,49.841 29.033,51.667 30.448,51.481C31.863,51.296 32.745,49.123 32.419,46.628Z"
            style="fill: white; fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(16.8724,0,0,16.8724,0.000158176,-327.509)">
          <path
            d="M59.921,50.275C58.991,50.74 57.767,50.972 56.25,50.972C52.176,50.972 50.139,49.017 50.139,45.107L50.139,33.233L46.632,33.233L46.632,29.5L50.139,29.5L50.139,24.64L54.896,23.286L54.896,29.5L59.921,29.5L59.921,33.233L54.896,33.233L54.896,43.733C54.896,44.977 55.122,45.866 55.573,46.399C56.024,46.932 56.776,47.199 57.828,47.199C58.635,47.199 59.333,46.966 59.921,46.501L59.921,50.275Z"
            style="fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(16.8724,0,0,16.8724,0.000158176,-327.509)">
          <path
            d="M64.921,25.091C64.142,25.091 63.476,24.838 62.922,24.333C62.368,23.827 62.092,23.184 62.092,22.405C62.092,21.625 62.368,20.976 62.922,20.456C63.476,19.937 64.142,19.677 64.921,19.677C65.728,19.677 66.412,19.937 66.972,20.456C67.533,20.976 67.813,21.625 67.813,22.405C67.813,23.143 67.533,23.775 66.972,24.302C66.412,24.828 65.728,25.091 64.921,25.091ZM67.28,50.501L62.522,50.501L62.522,29.501L67.28,29.501L67.28,50.501Z"
            style="fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(16.8724,0,0,16.8724,0.000158176,-327.509)">
          <path
            d="M103.391,50.501L98.634,50.501L98.634,39.057C98.634,36.856 98.323,35.263 97.7,34.279C97.078,33.294 96.029,32.802 94.552,32.802C93.308,32.802 92.252,33.424 91.384,34.669C90.516,35.913 90.081,37.403 90.081,39.139L90.081,50.501L85.303,50.501L85.303,38.668C85.303,34.757 83.923,32.802 81.161,32.802C79.876,32.802 78.819,33.39 77.992,34.566C77.165,35.742 76.752,37.266 76.752,39.139L76.752,50.501L71.994,50.501L71.994,29.501L76.752,29.501L76.752,32.823L76.834,32.823C78.351,30.28 80.559,29.008 83.458,29.008C84.907,29.008 86.175,29.408 87.262,30.208C88.349,31.008 89.09,32.057 89.487,33.356C91.046,30.458 93.37,29.008 96.46,29.008C101.081,29.008 103.391,31.859 103.391,37.56L103.391,50.501Z"
            style="fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(16.8724,0,0,16.8724,0.000158176,-327.509)">
          <path
            d="M125.475,41.293L111.161,41.293C111.215,43.234 111.813,44.731 112.955,45.784C114.097,46.837 115.666,47.363 117.661,47.363C119.904,47.363 121.961,46.693 123.835,45.353L123.835,49.188C121.921,50.391 119.391,50.993 116.247,50.993C113.157,50.993 110.734,50.039 108.977,48.132C107.22,46.225 106.341,43.542 106.341,40.083C106.341,36.815 107.308,34.152 109.243,32.095C111.178,30.037 113.581,29.008 116.452,29.008C119.323,29.008 121.544,29.931 123.117,31.777C124.689,33.622 125.475,36.186 125.475,39.467L125.475,41.293ZM120.882,37.93C120.868,36.22 120.464,34.891 119.671,33.941C118.878,32.991 117.785,32.515 116.39,32.515C115.023,32.515 113.864,33.014 112.914,34.012C111.964,35.01 111.379,36.316 111.161,37.93L120.882,37.93Z"
            style="fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(16.8724,0,0,16.8724,0.000158176,-327.509)">
          <path
            d="M147.621,48.819C147.621,56.53 143.745,60.386 135.993,60.386C133.258,60.386 130.873,59.928 128.836,59.012L128.836,54.664C131.132,55.976 133.313,56.633 135.378,56.633C140.368,56.633 142.863,54.179 142.863,49.27L142.863,46.973L142.781,46.973C141.209,49.653 138.843,50.993 135.685,50.993C133.129,50.993 131.068,50.06 129.502,48.194C127.936,46.328 127.154,43.822 127.154,40.677C127.154,37.109 127.995,34.272 129.676,32.167C131.358,30.061 133.669,29.009 136.608,29.009C139.383,29.009 141.441,30.143 142.781,32.413L142.863,32.413L142.863,29.501L147.621,29.501L147.621,48.819ZM142.904,40.883L142.904,38.155C142.904,36.678 142.415,35.417 141.438,34.371C140.46,33.325 139.24,32.802 137.777,32.802C135.972,32.802 134.561,33.469 133.542,34.802C132.524,36.135 132.014,37.998 132.014,40.391C132.014,42.455 132.503,44.105 133.481,45.343C134.458,46.58 135.753,47.199 137.367,47.199C139.007,47.199 140.34,46.608 141.366,45.425C142.391,44.242 142.904,42.728 142.904,40.883Z"
            style="fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(16.8724,0,0,16.8724,0.000158176,-327.509)">
          <path
            d="M170.976,50.501L166.218,50.501L166.218,39.016C166.218,34.86 164.831,32.782 162.055,32.782C160.661,32.782 159.485,33.384 158.528,34.587C157.571,35.79 157.092,37.328 157.092,39.201L157.092,50.501L152.314,50.501L152.314,19.411L157.092,19.411L157.092,32.987L157.174,32.987C158.76,30.335 161.03,29.009 163.983,29.009C168.645,29.009 170.976,31.859 170.976,37.56L170.976,50.501Z"
            style="fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(16.8724,0,0,16.8724,0.000158176,-327.509)">
          <path
            d="M184.57,50.993C181.33,50.993 178.742,50.012 176.808,48.05C174.873,46.088 173.906,43.487 173.906,40.247C173.906,36.719 174.914,33.964 176.931,31.982C178.947,30 181.665,29.009 185.083,29.009C188.364,29.009 190.921,29.972 192.753,31.9C194.585,33.828 195.501,36.501 195.501,39.919C195.501,43.268 194.513,45.951 192.537,47.968C190.561,49.985 187.906,50.993 184.57,50.993ZM184.795,32.802C182.936,32.802 181.466,33.452 180.386,34.751C179.306,36.05 178.766,37.841 178.766,40.124C178.766,42.325 179.313,44.058 180.407,45.323C181.501,46.587 182.964,47.22 184.795,47.22C186.668,47.22 188.107,46.597 189.112,45.353C190.117,44.109 190.62,42.338 190.62,40.042C190.62,37.731 190.117,35.947 189.112,34.689C188.107,33.431 186.668,32.802 184.795,32.802Z"
            style="fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(16.8724,0,0,16.8724,0.000158176,-327.509)">
          <path
            d="M197.528,49.845L197.528,45.435C199.305,46.789 201.267,47.466 203.414,47.466C206.285,47.466 207.72,46.618 207.72,44.923C207.72,44.444 207.597,44.037 207.351,43.703C207.105,43.368 206.773,43.07 206.357,42.81C205.94,42.55 205.447,42.318 204.88,42.113C204.313,41.908 203.68,41.676 202.983,41.416C202.122,41.074 201.346,40.712 200.655,40.329C199.965,39.946 199.391,39.512 198.933,39.027C198.475,38.541 198.13,37.991 197.897,37.376C197.665,36.761 197.548,36.043 197.548,35.223C197.548,34.211 197.788,33.319 198.266,32.546C198.745,31.774 199.387,31.124 200.194,30.598C201.001,30.072 201.917,29.675 202.942,29.409C203.968,29.142 205.027,29.009 206.121,29.009C208.062,29.009 209.798,29.303 211.33,29.891L211.33,34.053C209.853,33.042 208.158,32.536 206.244,32.536C205.642,32.536 205.099,32.598 204.613,32.72C204.128,32.843 203.714,33.014 203.373,33.233C203.031,33.452 202.764,33.715 202.573,34.023C202.381,34.331 202.286,34.669 202.286,35.038C202.286,35.489 202.381,35.872 202.573,36.186C202.764,36.501 203.048,36.778 203.424,37.017C203.8,37.256 204.251,37.475 204.778,37.673C205.304,37.871 205.909,38.087 206.592,38.319C207.495,38.688 208.305,39.064 209.023,39.447C209.74,39.83 210.352,40.264 210.858,40.749C211.364,41.235 211.754,41.795 212.027,42.431C212.301,43.067 212.437,43.822 212.437,44.697C212.437,45.764 212.191,46.693 211.699,47.486C211.207,48.279 210.551,48.936 209.73,49.455C208.91,49.975 207.963,50.361 206.89,50.614C205.816,50.867 204.685,50.993 203.496,50.993C201.199,50.993 199.21,50.61 197.528,49.845Z"
            style="fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(16.8724,0,0,16.8724,0.000158176,-327.509)">
          <path
            d="M226.523,50.275C225.593,50.74 224.37,50.973 222.852,50.973C218.778,50.973 216.741,49.018 216.741,45.107L216.741,33.233L213.234,33.233L213.234,29.501L216.741,29.501L216.741,24.641L221.499,23.287L221.499,29.501L226.523,29.501L226.523,33.233L221.499,33.233L221.499,43.733C221.499,44.977 221.724,45.866 222.175,46.399C222.626,46.933 223.378,47.199 224.431,47.199C225.238,47.199 225.935,46.967 226.523,46.502L226.523,50.275Z"
            style="fill-rule: nonzero"
          />
        </g>
        <g transform="matrix(0.390222,0,0,0.390222,3159.28,-206.522)">
          <path
            d="M1748.49,685.336C1809.85,685.336 1859.66,735.15 1859.66,796.507C1859.66,857.864 1809.85,907.678 1748.49,907.678C1687.13,907.678 1637.32,857.864 1637.32,796.507C1637.32,735.15 1687.13,685.336 1748.49,685.336ZM1748.49,713.129C1794.51,713.129 1831.87,750.489 1831.87,796.507C1831.87,842.525 1794.51,879.886 1748.49,879.886C1702.47,879.886 1665.11,842.525 1665.11,796.507C1665.11,750.489 1702.47,713.129 1748.49,713.129Z"
          />
        </g>
        <g transform="matrix(3.99491,0,0,3.99491,-3160.17,-3079.1)">
          <text
            x="1746.36px"
            y="802.588px"
            style="font-family: 'Arial-BoldMT', 'Arial', sans-serif; font-weight: 700; font-size: 16px"
          >
            R
          </text>
        </g>
      </g>
    </svg>
  </div>
</div>
<div id="app-root">
  <tg-subscription-status-row [navigateOnClick]="true"></tg-subscription-status-row>
  <router-outlet></router-outlet>
</div>
<notifier-container></notifier-container>
