import { MaterialModule } from '../../material.module';
import { UserMenuComponent } from './user-menu.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { FeedbackDialogModule } from '../dialogs/feedback-dialog/feedback-dialog.module';
import { RouterModule } from '@angular/router';
import { UserMenuRouterLinkDirective } from './user-menu-router-link.directive';

@NgModule({
  declarations: [UserMenuComponent, UserMenuRouterLinkDirective],
  imports: [
    CommonModule,
    UserAvatarModule,
    FlexLayoutModule,
    TranslateModule,
    MaterialModule,
    RouterModule,
    FeedbackDialogModule,
  ],
  exports: [UserMenuComponent],
})
export class UserMenuModule {}
