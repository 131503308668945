import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeUrlPipe } from './safe-url/safe-url.pipe';
import { IterateObjectPipe } from './iterate-object/iterate-object.pipe';
import { AvatarPipe } from './avatar/avatar.pipe';
import { SourceFactory } from 'ngx-avatar';
import { KeyValStorePipe } from './key-val-store.pipe';
import { ParseMsAsDurationPipe } from './parse-ms-as-duration.pipe';
import { DfnsFormatTzPipe } from './dfns-format-tz.pipe';
import { JoinStringPipe } from './join-string.pipe';
import { MapPropPipe } from './map-prop.pipe';
import { TruncatePipe } from './truncate.pipe';
import { DfnsToZonedTimePipe } from './dfns-to-zoned-time.pipe';
import { SplitPipe } from './split.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { DefaultEntityTranslatePipe } from './default-entity-translate.pipe';
import { TranslateService } from '@ngx-translate/core';
import { ClientsQuery, ProjectsQuery } from 'timeghost-api';
import { CustomCurrencyPipe } from './custom-currency.pipe';
import { PadLeftPipe } from './pad-left.pipe';
import { PadRightPipe } from './pad-right.pipe';
import { ClampPipe } from './clamp.pipe';
import { GroupTranslatePipe } from './group-translate.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { ReplaceStringPipe } from './replace-string.pipe';
import { FormatPipe } from 'ngx-date-fns';
import { ArrayFlatPipe } from './array-flat.pipe';
import { ArraySortPipe } from './array-sort.pipe';
import { ProjectProxyPipe } from './project-proxy.pipe';

@NgModule({
  declarations: [
    SafeUrlPipe,
    SafeHtmlPipe,
    IterateObjectPipe,
    AvatarPipe,
    KeyValStorePipe,
    ParseMsAsDurationPipe,
    DfnsFormatTzPipe,
    JoinStringPipe,
    MapPropPipe,
    TruncatePipe,
    FormatDatePipe,
    DfnsToZonedTimePipe,
    SplitPipe,
    SafeHtmlPipe,
    DefaultEntityTranslatePipe,
    CustomCurrencyPipe,
    PadLeftPipe,
    PadRightPipe,
    ClampPipe,
    GroupTranslatePipe,
    ReplaceStringPipe,
    ArrayFlatPipe,
    ArraySortPipe,
    ProjectProxyPipe,
  ],
  imports: [CommonModule],
  providers: [
    SourceFactory,
    {
      provide: DefaultEntityTranslatePipe,
      deps: [ProjectsQuery, ClientsQuery, TranslateService],
    },
    FormatPipe,
  ],
  exports: [
    SafeUrlPipe,
    SafeHtmlPipe,
    IterateObjectPipe,
    AvatarPipe,
    KeyValStorePipe,
    ParseMsAsDurationPipe,
    JoinStringPipe,
    MapPropPipe,
    TruncatePipe,
    FormatDatePipe,
    DfnsFormatTzPipe,
    DfnsToZonedTimePipe,
    DefaultEntityTranslatePipe,
    CustomCurrencyPipe,
    PadLeftPipe,
    PadRightPipe,
    ClampPipe,
    GroupTranslatePipe,
    ReplaceStringPipe,
    ArrayFlatPipe,
    ArraySortPipe,
    ProjectProxyPipe,
  ],
})
export class CustomPipesModule {}
