import { NgModule } from '@angular/core';
import { ClientPickerDialogModule } from './../client-picker-dialog/client-picker-dialog.module';
import { CommonModule } from '@angular/common';
import { CreateProjectComponent } from './create-project.component';
import { MaterialModule } from '@app/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogConfig, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxCurrencyModule } from 'ngx-currency';
import { DurationInputControlModule } from '@app/components/duration-input-control/duration-input-control.module';
import { ProjectTemplateDialogModule } from '../project-template-dialog/project-template-dialog.module';

@NgModule({
  declarations: [CreateProjectComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    ClientPickerDialogModule,
    TranslateModule,
    ColorPickerModule,
    DurationInputControlModule,
    NgxCurrencyModule,
    ProjectTemplateDialogModule,
  ],
  exports: [CreateProjectComponent],
})
export class CreateProjectModule {}
