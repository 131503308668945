<div>
  <app-user-avatar
    [size]="size"
    class="rounded-circle cursor-pointer"
    [matMenuTriggerFor]="menu"
    defaultImageSource="/assets/img/default.png"
  ></app-user-avatar>
  <mat-menu [overlapTrigger]="true" #menu="matMenu" class="mat-menu-nopad usermenu-panel">
    <div fxLayout="column" fxLayoutAlign="start stretch" (click)="$event.stopPropagation()">
      <div class="flex flex-col items-center userinfo-section">
        <a href="https://portal.office.com/account/#personalinfo" target="_blank" class="my-4">
          <app-user-avatar
            size="64px"
            class="rounded-circle flex-shrink-0 mat-elevation-z4"
            defaultImageSource="/assets/img/default.png"
          ></app-user-avatar>
        </a>
        <div class="flex flex-col items-center justify-center">
          <span>{{ (this.userSettings$ | async).officeProfile.displayName }}</span>
          <small class="mat-hint"> {{ this.currentWorkspace.name }} </small>
        </div>
      </div>
      <div class="mat-divider mt-2 mb-0"></div>
      <mat-list dense class="pt-0">
        <a mat-list-item [routerLink]="['/account', 'profile']" mat-ripple class="cursor-pointer">{{
          'account.profile._' | translate
        }}</a>
        <mat-list-item
          (click)="openCurrentWorkspaceCheckout()"
          mat-ripple
          class="cursor-pointer"
          *ngIf="(status$ | async).isExpired"
          >{{ 'user.buy-license' | translate }}</mat-list-item
        >
        <mat-list-item (click)="openFeedback()" mat-ripple class="cursor-pointer">
          {{ 'Feedback' | translate }}</mat-list-item
        >
        <a mat-list-item href="https://portal.office.com/account/#personalinfo" mat-ripple target="_blank">{{
          'user.office.manageprofile' | translate
        }}</a>
        <a mat-list-item teams-hide href="https://web-beta.timeghost.io" mat-ripple target="_blank">{{
          'user.trybeta' | translate
        }}</a>
        <mat-list-item (click)="$event.stopPropagation()" mat-ripple class="cursor-pointer">
          <mat-select [(value)]="this.selectedTheme">
            <mat-option value="default">{{ 'theme.system' | translate }}</mat-option>
            <mat-option value="light">{{ 'theme.light' | translate }}</mat-option>
            <mat-option value="dark">{{ 'theme.dark' | translate }}</mat-option>
          </mat-select>
        </mat-list-item>
        <mat-list-item (click)="logout()" mat-ripple class="cursor-pointer"> {{ 'LogOut' | translate }}</mat-list-item>
      </mat-list>
      <div class="mat-divider"></div>
      <div class="flex flex-col p-2">
        <small class="mat-hint text-center"
          >{{ this.releaseName || this.packageVersion }} <span *isBeta class="ml-1">(Beta)</span>
        </small>
      </div>
    </div>
  </mat-menu>
</div>
